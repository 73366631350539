div.labodoc-stepper {
    display: flex;
    flex-direction: column;

    ol {
        display: flex;
        padding: 0;
        margin: 0;

        li {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            div {
                width: 1.5em;
                height: 1.5em;
                font-weight: 900;
                font-size: 1.5em;
                border: 2px solid var(--labodoc-middle-blue);
                color: var(--labodoc-middle-blue);
                box-shadow: 0 0 10px var(--shadow-color);
                border-radius: 50px 50px 50px 0;
            }

            p {
                color: var(--labodoc-middle-blue);
                font-weight: 900;
                width: 150px;
                margin-top: 5px;
                margin-bottom: 0;
            }
        }

        li.current {
            div {
                background-color: var(--labodoc-middle-blue);
                color: white;
            }
        }

        li:not(:last-child)::after {
            --spacing: 1.5em;

            content: "";
            position: relative;
            top: 1.5em;
            width: calc(70% - calc(var(--spacing) * 2));
            left: calc(40% + var(--spacing));
            height: 2px;
            background-color: var(--labodoc-middle-blue);
            order: -1;
        }


        @media screen and (max-width: 1000px) {
            li {
                p {
                    display: none;
                }
            }

            li:not(:last-child)::after {
                left: calc(35% + var(--spacing));
            }
        }

        @media screen and (max-width: 700px) {
            li {
                p {
                    display: none;
                }
            }

            li:not(:last-child)::after {
                left: calc(30% + var(--spacing));
            }
        }
    }

    > p {
        text-align: center;
        font-weight: 900;
        color: var(--labodoc-middle-blue);
        margin-bottom: 0;

        display: none;

        @media screen and (max-width: 1000px) {
            display: initial;
        }
    }
}
