.labodoc-popup {
    position: fixed; /* Stay in place */
    top: 0;
    left: 0;
    z-index: 99; /* Sit on top */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    display: flex;
    justify-content: center;
    align-items: center;

    .labodoc-popup-container {
        width: 800px;

        @media screen and (max-width: 1000px) {
            width: 690px;
        }

        @media screen and (max-width: 700px) {
            width: 90vw;
        }

        > header {
            background-color: var(--labodoc-light-blue);
            height: 70px;
            border-radius: 70px 70px 0 0;
            position: relative;

            @media screen and (max-width: 700px) {
                border-radius: 40px 40px 0 0;
            }

            .close-button {
                cursor: pointer;
                position: absolute;
                left: 90%;
                top: 20%;
                z-index: 2;

                @media screen and (max-width: 700px) {
                    left: 80%;
                    top: 25%
                }

                > span {
                    font-size: 2em;
                    font-weight: bold;
                    color: var(--labodoc-deep-blue);
                }
            }
        }

        .labodoc-popup-container-content {
            background-color: var(--labodoc-light-blue);
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 70px;

            @media screen and (max-width: 700px) {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
}

.labodoc-popup.with-close-button {
    .labodoc-popup-container {
        > header {
            border-radius: 0 70px 0 0;

            @media screen and (max-width: 700px) {
                border-radius: 0 40px 0 0;
            }
        }
    }
}

.labodoc-popup.with-icon {
    .labodoc-popup-container {
        > header {
            height: 125px;
            @media screen and (max-width: 700px) {
                height: 100px;
            }

            .icon {
                --size: 2.2em;
                height: var(--size);
                width: var(--size);
                border-radius: 200px;
                background-color: var(--labodoc-beige);
                box-shadow: 0 0 10px var(--shadow-color);

                position: relative;
                top: 55%;

                margin: auto;

                display: flex;
                justify-content: center;
                align-items: center;

                font-size: calc(var(--size) + 1em);

                @media screen and (max-width: 700px) {
                    --size: 2em;
                }
            }
        }

        .labodoc-popup-container-content {
            background-color: var(--labodoc-beige);
            padding-top: 75px;

            @media screen and (max-width: 700px) {
                padding-top: 50px;
            }
        }
    }
}

.labodoc-popup.with-image {
    .labodoc-popup-container {
        > header {
            height: 250px;
            background-repeat: no-repeat;

            @media screen and (max-width: 700px) {
                height: 200px;
            }

            .icon {
                top: 75%;
            }

            .close-button {
                left: 90%;
                top: 105%;
            }
        }

        .labodoc-popup-container-content {
            background-color: var(--labodoc-beige);
            padding-top: 30px;
        }
    }
}
