#page-admin-medicines {
    flex: 1;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height: 2em;
            margin-bottom: 2em;
        }


        button {
            background-color: var(--labodoc-yellow);
            color: white;
        }
    }

    .container {
        .labodoc-spinner {
            display: block;
            margin: auto;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .medicines {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            overflow-y: auto;

            .medicine {
                width: 400px;
                background-color: white;
                margin: 40px 8px;
                box-shadow: 0 0 5px var(--shadow-color);
                padding: 10px 30px;
                text-align: center;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                header {
                    display: initial;

                    img {
                        width: 250px;
                        cursor: pointer
                    }

                    .name {
                        font-size: 1.2em;
                        color: var(--labodoc-deep-blue);
                        text-decoration: none;
                        font-weight: bolder;
                        display: block;
                        margin: 10px 0px;
                    }
                }

                hr {
                    color: var(--labodoc-yellow);
                    width: 100%;
                    margin: 1.6em 0;
                }

                .actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    .action {
                        border-radius: 50px 50px 50px 0;
                        border: none;
                        padding: 0.5em 2em;
                        font-weight: bold;
                        cursor: pointer;
                        text-decoration: none;
                    }

                    .action.edit {
                        background-color: var(--labodoc-yellow);
                        color: white;
                    }

                    .action.delete {
                        background-color: red;
                        color: white;
                    }
                }
            }
        }
    }
}
