#page-admin-partnerships {
    flex: 1;

    .page-width {
        > header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                border-bottom: 3px solid var(--labodoc-yellow);
            }


            button {
                background-color: var(--labodoc-yellow);
                color: white;
            }
        }

        .partnerships {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            overflow-y: auto;

            .partnership {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 400px;
                margin: 10px 8px;
                padding: 10px;
                background-color: white;
                box-shadow: 0 0 5px var(--shadow-color);
                border-radius: 10px;
                text-align: center;

                .labodoc-popup.admin-partnership-delete-confirmation-modal {
                    .labodoc-popup-container-content {
                        p {
                            text-align: center;
                        }

                        .title {
                            font-weight: bold;
                        }

                        .choices {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;

                            button {
                                color: white;
                                margin: 10px;
                                padding: 5px 20px;
                            }

                            button.cancel {
                                background: var(--labodoc-middle-blue);
                            }

                            button.confirm {
                                background-color: #DB3F33;
                            }
                        }
                    }
                }

                > header {
                    img {
                        width: 300px;
                        height: 170px;
                    }

                    p {
                        margin: 10px 0;
                        font-size: 1.2em;
                        font-weight: bolder;
                        color: var(--labodoc-deep-blue);
                    }
                }

                hr {
                    color: var(--labodoc-yellow);
                    width: 100%;
                    margin: 1.6em 0;
                }

                .actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    .action {
                        border-radius: 50px 50px 50px 0;
                        border: none;
                        padding: 0.5em 2em;
                        font-weight: bold;
                        cursor: pointer;
                        text-decoration: none;
                    }

                    .action.edit {
                        background-color: var(--labodoc-yellow);
                        color: white;
                    }

                    .action.delete {
                        background-color: red;
                        color: white;
                    }
                }
            }
        }
    }
}
