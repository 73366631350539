#page-admin-laboratory-update {
    flex: 1;
    padding-bottom: 50px;

    .labodoc-spinner {
        display: block;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    div.page-width {
        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height:2em;
            margin-bottom: 2em;
        }
        div.contacts-form-container {
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            div.contacts {
                div.contact {
                    position: relative;
                    padding: 20px;

                    button.delete {
                        background-color: transparent;
                        color: red;
                        margin: auto;
                        display: block;
                    }
                }
            }

            button.add {
                background-color: var(--labodoc-middle-blue);
                color: white;
                margin: auto;
            }
        }

        button.save {
            background-color: var(--labodoc-yellow);
            color: white;
            margin: auto;
            display: block;
        }
    }


}
