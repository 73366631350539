header#labodoc-header {
    --height: 85px;
    background-color: var(--labodoc-beige);

    #labodoc-header-content {
        background-color: var(--labodoc-beige);
        display: grid;
        grid-template-columns: calc(500px + 6vw) 1fr;
        align-items: center;
        height: var(--height);
        width: 100%;
        position: fixed;
        z-index: 10;

        #labodoc-header-logo {
            background-color: var(--labodoc-middle-blue);
            border-radius: 0 80px 0 0;
            padding: 0;

            a {
                display: flex;
                gap: 30px;

                img {
                    height: 5em;
                    width: 13em;
                }
            }
        }

        #labodoc-header-nav {
            justify-self: end;

            nav {
                display: flex;

                .profile {
                    order: 3;
                }

                .medical-directory {
                    order: 1;
                }

                .test {
                    order: 2;
                }

                > div {
                    position: relative;
                    margin-left: 10px;

                    button {
                        background-color: transparent;
                        border: none;
                        text-transform: uppercase;
                        font-weight: bold;
                        text-decoration: none;
                        color: var(--labodoc-deep-blue);
                        font-size: 14px;

                        i {
                            font-size: 0.9em;
                        }
                    }

                    .dropdown {
                        position: absolute;
                        right: 0;
                        top: 40px;
                        min-width: 275px;
                        padding: 30px;
                        background: white;
                        border-radius: 0 0 0 40px;
                        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .15);
                        transform-origin: 50% 0;
                        transform: scale(1, 0);
                        transition: transform .15s ease-in-out .15s;

                        p.title {
                            color: var(--labodoc-deep-blue);
                            font-size: 1.2em;
                            font-weight: 900;
                            margin-top: 0;
                        }

                        a {
                            display: block;
                            text-decoration: none;
                            color: black;
                            margin-bottom: 0.5em;
                        }
                    }
                }

                > div.medical-directory {
                    .dropdown {
                        /*min-width: 350px;*/
                    }
                }


                > div.open {
                    .dropdown {
                        transform: scale(1, 1);
                        transition-delay: 0s;
                        z-index: 10;
                    }
                }
            }
        }
    }

    #labodoc-header-spacer {
        height: var(--height)
    }
}

@media screen and (max-width: 1000px) {
    header#labodoc-header {
        --height: 60px;

        #labodoc-header-content {
            grid-template-columns: 400px 1fr;

            #labodoc-header-logo {
                a {
                    img {
                        height: 4em;
                        width: 10em;
                    }
                }
            }

            #labodoc-header-nav {
                nav {
                    > div {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    header#labodoc-header {
        #labodoc-header-content {
            grid-template-columns: 1fr;
            justify-items: center;
            height: initial;
            position: initial;

            #labodoc-header-logo {
                padding: 15px 0;
                text-align: center;
                background-color: transparent;

                a {
                    img {
                        height: 4em;
                        width: 9em;
                    }
                }
            }


            #labodoc-header-nav {
                justify-self: initial;
                text-align: center;

                nav {
                    > div {
                        .dropdown {
                            padding: 30px 0 !important;
                            width: 100% !important;
                        }
                    }

                    > div.open {
                        .dropdown {
                            padding: 30px 0;
                            width: 100%;
                        }
                    }

                    .medical-directory {
                        .dropdown {
                            right: -120px;
                        }
                    }
                }
            }
        }

        #labodoc-header-spacer {
            display: none;
        }
    }
}

.labodoc-popup.leaving {
    p {
        text-align: center;

    }

    p.title {
        margin-top: 0;
        color: var(--labodoc-deep-blue);
        font-weight: 900;
        font-size: 1.5em;
    }

    p.text {
        line-height: 2em;

        a {
            color: #000000;
        }
    }

    div.buttons {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        column-gap: 2em;

        .labodoc-button {
            box-shadow: 0 0 6px #0000004D;
        }

        .labodoc-button.cancel {
            background-color: transparent;
            color: var(--labodoc-deep-blue);
            border: 2px solid var(--labodoc-middle-blue);
        }

        .labodoc-button.continue {
            background-color: var(--labodoc-middle-blue);
            color: white;
        }
    }
}
