#page-health-professional-learned-societies {
    flex: 1;
    padding-bottom: 10px;
    background-color: var(--labodoc-beige);

    .page-width {
        > header {
            margin-bottom: 10px;

            div.title {
                text-align: center;

                h1 {
                    color: var(--labodoc-deep-blue);
                    margin-bottom: 10px;
                }

                p {
                    margin-top: 0;
                }
            }

            div.filters {
                display: flex;
                align-items: baseline;
                column-gap: 10px;

                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                    align-items: stretch;
                    row-gap: 10px;
                }

                div.buttons {
                    display: flex;
                    column-gap: 10px;

                    button {
                        background-color: white;
                        border: 1px solid white;
                        padding: 5px;
                        width: 150px;

                        @media screen and (max-width: 1000px) {
                            flex: 1;
                        }
                    }

                    button:first-child {
                        border-radius: 20px 0 0 0;
                        @media screen and (max-width: 1000px) {
                            border-radius: initial;
                        }
                    }

                    button.active {
                        background-color: var(--labodoc-deep-blue);
                        border-color: var(--labodoc-deep-blue);
                        color: white;
                        font-weight: 900;
                    }
                }

                .labodoc-select-checkbox {
                    width: 100%;
                    max-width: 600px;

                    .dropdown {
                        max-height: 400px;
                    }

                    .list {
                        z-index: 2;
                    }
                }
            }
        }

        div.container {
            .labodoc-spinner {
                display: block;
                margin: 0 auto;
            }

            .cards {
                display: flex;
                flex-wrap: wrap;
                overflow-y: auto;
                max-height: 60vh;
                gap: 8px;

                @media screen and (max-width: 700px) {
                    justify-content: center;
                }

                a {
                    display: flex;
                    flex-direction: column;
                    text-decoration: none;

                    .card {
                        flex: 1;
                        width: 200px;
                        background-color: #FFFFFF;
                        padding: 5px;
                        text-align: center;
                        display: grid;
                        grid-template-rows: 125px 20px 1fr;
                        border-radius: 20px 20px 20px 0;

                        @media screen and (max-width: 700px) {
                            width: 150px;
                        }

                        header {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 125px;
                                max-height: 125px;
                            }

                            .favorite {
                                position: absolute;
                                top: -15%;
                                right: 0;

                                input[type="checkbox"] {
                                    opacity: 0;
                                    position: absolute;
                                    left: -9999px;
                                }

                                label {
                                    font-size: 2.5em; /* Adjust the size of the star */
                                    cursor: pointer;
                                    display: inline-block;
                                }

                                label::before {
                                    content: '☆'; /* Empty star */
                                    color: gold; /* Gold color */
                                    display: inline-block;
                                }

                                input[type="checkbox"]:checked + label:before {
                                    content: '★'; /* Filled star */
                                }

                                input[type="checkbox"]:focus-visible + label:before {
                                    outline: 2px solid black; /* Example focus style */
                                }
                            }
                        }

                        hr {
                            color: var(--labodoc-yellow);
                            margin: 0;
                            align-self: center;
                        }

                        p {
                            color: var(--labodoc-deep-blue);
                            font-weight: 800;
                            font-size: 0.8em;
                        }
                    }
                }
            }
        }
    }
}

.labodoc-popup.leaving {
    p {
        text-align: center;

    }

    p.title {
        margin-top: 0;
        color: var(--labodoc-deep-blue);
        font-weight: 900;
        font-size: 1.5em;
    }

    p.text {
        line-height: 2em;

        a {
            color: #000000;
        }
    }

    div.buttons {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        column-gap: 2em;

        .labodoc-button {
            background-color: transparent;
            color: var(--labodoc-deep-blue);
            border: 2px solid var(--labodoc-middle-blue);
        }

        a .labodoc-button {
            background-color: var(--labodoc-middle-blue);
            color: white;
        }
    }
}
