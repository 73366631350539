.labodoc-radio-group {
    .kv-radiogroup-container {
        margin-top: 5px;
    }

    input {
        appearance: none;
        width: 1.15em;
        height: 1.15em;
        min-width: 20px;
        min-height: 18px;
        border: 0.05em solid currentColor;
        border-radius: 50px 50px 0 50px;
        margin-right: 10px;
    }

    input:checked {
        appearance: none;
        width: 1.15em;
        height: 1.15em;
        border: 0.05em solid currentColor;
        border-radius: 50px 50px 0 50px;
        margin-right: 10px;
        background-color: currentColor;
    }
}
