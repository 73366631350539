.labodoc-segmentation-input {
    width: 100%;
    .modal {
        display: none;
        position: fixed; /* Stay in place */
        top: 0;
        left: 0;
        z-index: 99; /* Sit on top */
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

        .container {
            background-color: white;
            width: 800px;
            .header {
                height: 50px;
                background-color: var(--labodoc-middle-blue);
                width: 100%;
                margin: 0;
                margin-bottom: 10px;
                h4 {
                    margin: 0;
                    padding: 10px;
                    color: white;
                }
            }
            .content {
                padding: 20px;
            }
            .labodoc-select-checkbox {
                .dropdown {
                    max-height: 75vh;
                }
            }
            .labodoc-button {
                margin: 20px 0;
            }
            button.save-this-segmentation-btn {
                background-color: transparent;
                color: #703939;
            }

            .segmentation-template-select-container {
                display: flex;
                flex-direction: row;
            }
            .segmentation-template-select-container .labodoc-select {
                width: 75%;
                margin-right: 20px;
                margin-bottom: 0;
            }
            .segmentation-template-select-container button {
                width: 20%;
                padding-left: 5px;
                padding-right: 5px;
                margin-top: 0;
            }
        }
    }

    .modal.open {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .save-modal.open {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    button {
        background-color: var(--labodoc-middle-blue);
        color: white;
        width: 100%;
    }


    .form-group {
        margin-bottom: 20px;

        > .form-label {
            font-weight: bold;
            line-height: 1.8em;
        }

        .dropdown {
            text-align: left;
        }
    }
}
    div.segmentation-button-container {
        display: flex;
        flex-direction: row;
    }
    div.segmentation-button-container .open-save-modal-button {
        width: 20%;
        padding-left: 5px;
        padding-right: 5px;
    }
    button.minimized-btn {
        padding: 10px 10px !important;
        max-width: 3em !important;
        background-color: transparent;
        color: var(--labodoc-middle-blue);
    }
    button.minimized-btn i {
      font-size: 12px;
    }

    div.selected-information {
        text-align: center;
        margin: auto;
        p {
            line-height: 0.5em;
            font-size: 11px;
            color: #333232;
        }
    }

    span.segmentation-tag {
        display: inline-flex;
        flex-direction: row;
        background-color: var(--labodoc-beige);
        border: none;
        cursor: default;
        height: 36px;
        outline: none;
        padding: 2px 8px;
        font-size: 14px;
        white-space: nowrap;
        align-items: center;
        border-radius: 16px;
        margin: 5px;
    }
