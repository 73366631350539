#page-admin-partners {
    flex: 1;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height: 2em;
            margin-bottom: 2em;
        }


        button {
            background-color: var(--labodoc-yellow);
            color: white;
        }
    }

    .partners {
        header {
            display: grid;
            grid-template-columns: 1fr 1fr 100px;
            padding: 20px;

            p {
                font-weight: bold;
            }
        }

        .labodoc-spinner {
            display: block;
            margin: 50px auto;
        }

        .partner {
            display: grid;
            grid-template-columns: 1fr 100px;
            align-items: center;
            margin-bottom: 20px;
            padding: 20px;
            box-shadow: 0 0 10px var(--shadow-color);
            border-radius: 10px;

            .details {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;

                p {
                    margin: 0;
                }
            }


            .actions {
                .action {
                    font-size: 20px;
                    font-weight: bold;
                    color: var(--labodoc-deep-blue);
                    margin: 0 5px;
                    padding: 0;
                    background: transparent;
                    border: none;
                }
            }
        }
    }
}

.labodoc-popup.admin-partner-form-modal {
    .labodoc-popup-container {
        .labodoc-popup-container-content {
            text-align: center;

            h1 {
                margin: 0 0 50px;
            }

            form {
                .form-group {
                    margin-bottom: 50px;
                }
            }
        }
    }
}

.labodoc-popup.admin-partner-delete-confirmation-modal {
    .labodoc-popup-container-content {
        .title {
            text-align: center;
            font-weight: bold;
        }

        .choices {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                color: white;
                margin: 10px;
                padding: 5px 20px;
            }

            button.cancel {
                background: var(--labodoc-middle-blue);
            }

            button.delete {
                background-color: #DB3F33;
            }
        }
    }
}
