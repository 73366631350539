#page-admin-laboratory {
    flex: 1;
    .labodoc-spinner {
        display: block;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .page-width {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    div.laboratory-information {
        display: flex;
        margin-top: 50px;

        .image-container {
            text-align: center;
            margin: auto;
            img {
                width: 200px;
            }
        }



        h1 {
            margin-bottom: 0;
        }

        span.info-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            p {
                font-weight: bold;
            }
            p, i, a {
                padding: 0 3px;
            }
        }

        ul {
          height: 200px;
          overflow-y: auto;
          scrollbar-color: var(--labodoc-middle-blue) lightgrey;
          scrollbar-width: thin;
        }

        ul::-webkit-scrollbar {
          width: 3px;
        }

        ul::-webkit-scrollbar-thumb {
          background-color: var(--labodoc-middle-blue);
          border-radius: 100px;
        }
    }

    .dialog-content .modal-container {
        max-width: 701px;
    }

    div.modal-content {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
    }

    div.modal-content button {
      margin: 10px;
    }

    div.modal-content button.activate-btn {
      background-color: #397048;
      color: white;
    }

    div.modal-content button.deactivate-btn {
      background-color: #703939;
      color: white;
    }

    div.modal-content button.cancel-btn {
      border: none;
      background-color: transparent;
      color: var(--labodoc-deep-blue);
    }

    .dialog-content h2 {
        font-size: 21px;
        margin-bottom: 0px;
     }


    div.contents {
        width: 600px;
        nav {
            a {
                background-color: white;
                border: 1px solid var(--labodoc-deep-blue);
                color: var(--labodoc-deep-blue);
                border-radius: 10px 10px 0 0;
                padding: 10px;
                margin-right: 10px;
                display: inline-block;
                cursor: pointer;
            }

            a.active {
                background-color: var(--labodoc-deep-blue);
                color: white;
            }
        }
        div.container {
            border: 1px solid var(--labodoc-deep-blue);
            background-color: var(--labodoc-beige);
            border-radius: 0 0 10px 10px;
            div.contacts, div.medicines {
                div.contact, div.medicine {
                    background-color: white;
                    padding: 10px 20px;
                    display: flex;
                    column-gap: 20px;
                    margin: 10px 0;
                    .medicine-image {
                        max-width: 10%;
                        margin: auto;
                        img {
                           max-width: 80%;
                        }
                    }
                    .information {
                        display: flex;
                        column-gap: 20px;
                        span {
                            font-size: 14px;
                        }

                    }
                    div.medicine-actions {
                        width: 30%;
                        margin: auto;
                        button.labodoc-button {
                            padding: 0.6em 2em !important;
                            font-size: 14px !important;
                        }
                    }
                }
            }
            div.no-information {
                text-align: center;
            }
            div.add-medicine-action {
                display: flex;
                justify-content: center;
                margin: 10px;
            }
        }
    }

    div.actions {
        display: flex;
        justify-content: center;
        margin: 50px;
        button {
            color: white;
            margin: 10px;
            padding: 5px 20px;
            margin: 10px;
        }

        button.edit {
            background-color: var(--labodoc-yellow);

        }

        button.activate {
            border: 1px solid #703939;
            color: #703939;
            background-color: transparent;
        }

        button.deactivate {
            border: 1px solid #397048;
            color: #397048;
            background-color: transparent;
        }
    }
}
