form.medicine-form {
    .laboratory {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        h3 {
            grid-column: 1 / -1;
        }

        .fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            align-items: baseline;

            .laboratory-details {
                display: flex;

                .laboratory-information {
                    flex: 1;
                    display: flex;
                    align-items: baseline;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .labodoc-select {
                        flex: 1;
                    }

                    button.remove {
                        background: none;
                        border: none;
                    }
                }
            }
        }
    }

    .medicine {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        h3 {
            grid-column: 1 / -1;
        }

        .fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        .logo {
            img {
                display: block;
                width: 300px;
                margin: 20px auto;
            }
        }

        .documents {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .document-list {
                .document {
                    display: flex;
                    margin-bottom: 20px;

                    .delete {
                        background-color: transparent;
                        border: none;
                    }

                    a {
                        margin: 0;
                    }
                }
            }
        }
    }
}
