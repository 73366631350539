#page-health-professional-profile-notifications-settings {
    flex: 1;
    background-color: var(--labodoc-beige);
    padding: 40px 0;

    > .page-width {
        > div {
            background-color: white;
            padding: 20px 40px;
            margin-bottom: 20px;
            box-shadow: 0 6px 10px #00000040;
            border-radius: 0 80px 0 0;

            > .title {
                color: var(--labodoc-deep-blue);
                font-weight: 900;
                font-size: 1.2em;
            }

            > .text {
                margin: 25px 0;
            }

            > .choices {
                display: grid;
                column-gap: 5em;
                margin-top: 20px;
                margin-bottom: 50px;

                > .kv-radiogroup-container {
                    > div {
                        margin-bottom: 20px;

                        > p {
                            font-weight: bold;
                        }
                    }
                }
            }

            > .labodoc-button {
                display: block;
                margin: auto;
            }

        }

        p.privacy-policy {
            a {
                color: black;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    #page-health-professional-profile-notifications-settings {
        > .page-width {
            > div {
                > .choices {
                    grid-template-columns: 1fr;
                    row-gap: 2em;
                }
            }
        }
    }
}
