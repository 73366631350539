#page-about-us {
  #page-about-header {
    #page-about-presentation {
      border-radius: 0 100px 0 0;
      padding-bottom: 50px;
      @media screen and (max-width: 700px) {
        border-radius: 0 0 40px 0;
        max-width: 95vw;
        padding-bottom: 25px;
      }

      #page-about-presentation-content {
        h1 {
          font-size: 35px;
          max-width: 500px;
        }

        p {
          max-width: 550px;
        }
      }
    }
    img {
      position: relative;
      top: 12px;
      max-height: 430px;
      margin-left: 175px;
    }
    @media screen and (min-width: 2001px) {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }

  #page-about-pros {
    h1 {
      margin-bottom: 1em;
      font-size: 22px !important;
      text-align: center;
      font-size: 16px;
      @media screen and (max-width: 1000px) {
        margin-bottom: 50px;
      }
    }

    h4 {
      font-size: 16px;
      margin-bottom: 4em;
      font-weight: normal;
      text-align: center;
    }

    .bubble {
      max-width: 600px;
      width: 25vw;
      padding: 0 25px;
      text-align: center;
      @media screen and (max-width: 1000px) {
        max-width: 300px;
        width: 75vw;
        margin: 30px;
      }
      div.pros-point-icon {
        width: 1.5em;
        height: 1.5em;
        background-color: var(--labodoc-middle-blue);
        box-shadow: 0 0 10px var(--shadow-color);
        border-radius: 50px 50px 50px 0;
      }
      h2 {
        font-weight: 900;
        color: var(--labodoc-middle-blue);
      }
    }

    .mobile {
      display: none;
      @media screen and (max-width: 1000px) {
        display: block;

        swiper-container::part(pagination) {
          margin-top: 40px;

          --swiper-pagination-bullet-height: 15px;
          --swiper-pagination-bullet-size: 15px;
          --swiper-pagination-color: var(--labodoc-deep-blue);
          --swiper-pagination-bullet-inactive-color: var(--labodoc-deep-blue);
          --swiper-pagination-bullet-inactive-opacity: 1;
          --swiper-pagination-bottom: 1px;
        }
      }
    }
    @media screen and (min-width: 2001px) {
      padding-left: 10vw;
      padding-right: 10vw;
    }
  }

  #page-about-features {
    padding: 50px 0;

    div.page-width {
      display: flex;
      justify-content: space-around;
      @media screen and (max-width: 1000px) {
        align-items: center;
        flex-direction: column;
      }

      hr {
        border: 2px solid var(--labodoc-yellow);
        width: 100px;
        margin: 0;
        margin-bottom: 15px;
      }
      div {
        h1 {
          margin-bottom: 15px;
          max-width: 350px;
        }
      }

      img {
        max-width: 600px;
        width: 30vw;

        object-fit: cover;
        object-position: center;
        margin-left: 150px;
        @media screen and (max-width: 700px) {
          width: 80vw;
          margin: auto;
        }
      }
    }
    @media screen and (min-width: 2001px) {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }

  #page-about-mission {
    padding: 50px 0 100px 0;
    text-align: center;
    @media screen and (max-width: 700px) {
      padding: 0 0 30px 0;
    }

    div.page-width {
      div {
        display: flex;
        @media screen and (max-width: 700px) {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        h1 {
          width: 200px;
          align-self: center;
        }
      }

      div.quote-container {
        height: 6em;
        background-image: url("../../../img/quotes-background.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      a {
        text-decoration: none;
        button {
          display: block;
          margin: 60px auto 0 auto;
          @media screen and (max-width: 700px) {
            margin: 30px auto 0 auto;
          }
        }
      }
      hr {
        width: 200px;
        padding: 1px;
        margin-bottom: 25px;
        color: var(--labodoc-yellow);
        background-color: var(--labodoc-yellow);
      }
    }
  }
}
