:root {
    --labodoc-deep-blue: #002945;
    --labodoc-middle-blue: #2C7898;
    --labodoc-beige: #EFEAE4;
    --labodoc-light-blue: #C7E3EE;
    --labodoc-white: #FFFFFF;
    --labodoc-yellow: #FCBF49;
    --shadow-color: #0000004B;

    --atc-category-A-color: #FFCE85;
    --atc-category-B-color: #FFC1C1;
    --atc-category-C-color: #E0ECF8;
    --atc-category-D-color: #B3EBBB;
    --atc-category-G-color: #FFEBEB;
    --atc-category-H-color: #F8E4F6;
    --atc-category-J-color: #FCEBAE;
    --atc-category-L-color: #D9D9D9;
    --atc-category-M-color: #F8D0BA;
    --atc-category-N-color: #C1D9F1;
    --atc-category-P-color: #ECFAEE;
    --atc-category-R-color: #E0FFFF;
    --atc-category-S-color: #FFFFE7;
    --atc-category-V-color: #F3F3F3;
}

* {
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    /*font-weight: normal;*/
    /*font-style: normal;*/
    font-variation-settings: "wdth" 100;
    font-size: 16px;

    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: grey;
}

::-webkit-scrollbar-thumb:hover {
    background-color: grey;
}

::-ms-reveal {
    display: none;
}

h1 {
    font-size: 1.5rem;
    font-weight: 900;
}

h2 {
    font-size: 1.25rem;
}

picture {
    display: block;
}

.labodoc-background-deep-blue,
button.labodoc-button.labodoc-background-deep-blue {
    background-color: var(--labodoc-deep-blue);
    border: 2px solid var(--labodoc-deep-blue);
    color: var(--labodoc-white);
}

.labodoc-background-deep-blue-reverse,
button.labodoc-button.labodoc-background-deep-blue-reverse {
    background: transparent;
    border: 2px solid var(--labodoc-middle-blue);
    color: var(--labodoc-deep-blue);
}

.labodoc-background-middle-blue,
button.labodoc-button.labodoc-background-middle-blue {
    background-color: var(--labodoc-middle-blue);
    border: 2px solid var(--labodoc-middle-blue);
    color: var(--labodoc-white);
}

.labodoc-background-middle-blue-reverse,
button.labodoc-button.labodoc-background-middle-blue-reverse {
    background: transparent;
    border: 2px solid var(--labodoc-middle-blue);
    color: var(--labodoc-middle-blue);
}

.labodoc-background-beige,
button.labodoc-button.labodoc-background-beige {
    background-color: var(--labodoc-beige);
    border: 2px solid var(--labodoc-beige);
    color: var(--labodoc-deep-blue);
}

.labodoc-background-light-blue,
button.labodoc-button.labodoc-background-light-blue {
    background-color: var(--labodoc-light-blue);
    border: 2px solid var(--labodoc-light-blue);
    color: var(--labodoc-deep-blue);
}

.labodoc-background-white,
button.labodoc-button.labodoc-background-white {
    background-color: var(--labodoc-white);
    border: 2px solid var(--labodoc-white);
    color: var(--labodoc-deep-blue);
}

.labodoc-background-yellow,
button.labodoc-button.labodoc-background-yellow {
    background-color: var(--labodoc-yellow);
    border: 2px solid var(--labodoc-yellow);
    color: var(--labodoc-white);
}

div#labodoc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

hr {
    border: 1px solid;
}

.page-width {
    max-width: 88.89vw;
    padding: 0 5vw 0 5vw;
    margin: auto;
}

button {
    cursor: pointer;
}

form {
    span.notice {
        font-style: italic;
        font-weight: 200;
        font-size: 0.9em;
        color: black;
        text-align: left;
        line-height: 1.1em;
        padding-top: 0.1em;
    }
}

.toastify {
    background-image: none !important;
}


/* List of css class based off of Vuetify and Primeng to simplify redundant style patterns */

/* Display */
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

/* Flexbox */
.flex-fill {
  flex: 1 1 auto !important;
}

.flex-1-1 {
  flex: 1 1 auto !important;
}

.flex-1-0 {
  flex: 1 0 auto !important;
}

.flex-0-1 {
  flex: 0 1 auto !important;
}

.flex-0-0 {
  flex: 0 0 auto !important;
}

.flex-1-1-100 {
  flex: 1 1 100% !important;
}

.flex-1-0-100 {
  flex: 1 0 100% !important;
}

.flex-0-1-100 {
  flex: 0 1 100% !important;
}

.flex-0-0-100 {
  flex: 0 0 100% !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.align-baseline {
  align-items: baseline !important;
}

.align-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-space-between {
  align-content: space-between !important;
}

.align-content-space-around {
  align-content: space-around !important;
}

.align-content-space-evenly {
  align-content: space-evenly !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

/* Grid */
.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
}

.col-fixed {
  flex: 0 0 auto;
  padding: 0.5rem;
}

.col-1 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 8.3333%;
}

.col-2 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 16.6667%;
}

.col-3 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 33.3333%;
}

.col-5 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 41.6667%;
}

.col-6 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 58.3333%;
}

.col-8 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 66.6667%;
}

.col-9 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 83.3333%;
}

.col-11 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 91.6667%;
}

.col-12 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

/* Margins */
.ma-0 {
  margin: 0px !important;
}

.ma-1 {
  margin: 4px !important;
}

.ma-2 {
  margin: 8px !important;
}

.ma-3 {
  margin: 12px !important;
}

.ma-4 {
  margin: 16px !important;
}

.ma-5 {
  margin: 20px !important;
}

.ma-6 {
  margin: 24px !important;
}

.ma-7 {
  margin: 28px !important;
}

.ma-8 {
  margin: 32px !important;
}

.ma-9 {
  margin: 36px !important;
}

.ma-10 {
  margin: 40px !important;
}

.ma-11 {
  margin: 44px !important;
}

.ma-12 {
  margin: 48px !important;
}

.ma-13 {
  margin: 52px !important;
}

.ma-14 {
  margin: 56px !important;
}

.ma-15 {
  margin: 60px !important;
}

.ma-16 {
  margin: 64px !important;
}

.ma-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mx-1 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-3 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.mx-4 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-5 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-6 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-7 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}

.mx-8 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-9 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.mx-10 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-11 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}

.mx-12 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.mx-13 {
  margin-right: 52px !important;
  margin-left: 52px !important;
}

.mx-14 {
  margin-right: 56px !important;
  margin-left: 56px !important;
}

.mx-15 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.mx-16 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-13 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.my-14 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.my-15 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.my-16 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.mt-11 {
  margin-top: 44px !important;
}

.mt-12 {
  margin-top: 48px !important;
}

.mt-13 {
  margin-top: 52px !important;
}

.mt-14 {
  margin-top: 56px !important;
}

.mt-15 {
  margin-top: 60px !important;
}

.mt-16 {
  margin-top: 64px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.mr-11 {
  margin-right: 44px !important;
}

.mr-12 {
  margin-right: 48px !important;
}

.mr-13 {
  margin-right: 52px !important;
}

.mr-14 {
  margin-right: 56px !important;
}

.mr-15 {
  margin-right: 60px !important;
}

.mr-16 {
  margin-right: 64px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.mb-11 {
  margin-bottom: 44px !important;
}

.mb-12 {
  margin-bottom: 48px !important;
}

.mb-13 {
  margin-bottom: 52px !important;
}

.mb-14 {
  margin-bottom: 56px !important;
}

.mb-15 {
  margin-bottom: 60px !important;
}

.mb-16 {
  margin-bottom: 64px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.ml-7 {
  margin-left: 28px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.ml-9 {
  margin-left: 36px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.ml-11 {
  margin-left: 44px !important;
}

.ml-12 {
  margin-left: 48px !important;
}

.ml-13 {
  margin-left: 52px !important;
}

.ml-14 {
  margin-left: 56px !important;
}

.ml-15 {
  margin-left: 60px !important;
}

.ml-16 {
  margin-left: 64px !important;
}

.ml-auto {
  margin-left: auto !important;
}

/* Paddings */
.pa-0 {
  padding: 0px !important;
}

.pa-1 {
  padding: 4px !important;
}

.pa-2 {
  padding: 8px !important;
}

.pa-3 {
  padding: 12px !important;
}

.pa-4 {
  padding: 16px !important;
}

.pa-5 {
  padding: 20px !important;
}

.pa-6 {
  padding: 24px !important;
}

.pa-7 {
  padding: 28px !important;
}

.pa-8 {
  padding: 32px !important;
}

.pa-9 {
  padding: 36px !important;
}

.pa-10 {
  padding: 40px !important;
}

.pa-11 {
  padding: 44px !important;
}

.pa-12 {
  padding: 48px !important;
}

.pa-13 {
  padding: 52px !important;
}

.pa-14 {
  padding: 56px !important;
}

.pa-15 {
  padding: 60px !important;
}

.pa-16 {
  padding: 64px !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-5 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.px-6 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-7 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}

.px-8 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.px-9 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.px-10 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-11 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}

.px-12 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.px-13 {
  padding-right: 52px !important;
  padding-left: 52px !important;
}

.px-14 {
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.px-15 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.px-16 {
  padding-right: 64px !important;
  padding-left: 64px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.py-13 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.py-14 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.py-15 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.py-16 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-6 {
  padding-top: 24px !important;
}

.pt-7 {
  padding-top: 28px !important;
}

.pt-8 {
  padding-top: 32px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.pt-10 {
  padding-top: 40px !important;
}

.pt-11 {
  padding-top: 44px !important;
}

.pt-12 {
  padding-top: 48px !important;
}

.pt-13 {
  padding-top: 52px !important;
}

.pt-14 {
  padding-top: 56px !important;
}

.pt-15 {
  padding-top: 60px !important;
}

.pt-16 {
  padding-top: 64px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-6 {
  padding-right: 24px !important;
}

.pr-7 {
  padding-right: 28px !important;
}

.pr-8 {
  padding-right: 32px !important;
}

.pr-9 {
  padding-right: 36px !important;
}

.pr-10 {
  padding-right: 40px !important;
}

.pr-11 {
  padding-right: 44px !important;
}

.pr-12 {
  padding-right: 48px !important;
}

.pr-13 {
  padding-right: 52px !important;
}

.pr-14 {
  padding-right: 56px !important;
}

.pr-15 {
  padding-right: 60px !important;
}

.pr-16 {
  padding-right: 64px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.pb-7 {
  padding-bottom: 28px !important;
}

.pb-8 {
  padding-bottom: 32px !important;
}

.pb-9 {
  padding-bottom: 36px !important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

.pb-11 {
  padding-bottom: 44px !important;
}

.pb-12 {
  padding-bottom: 48px !important;
}

.pb-13 {
  padding-bottom: 52px !important;
}

.pb-14 {
  padding-bottom: 56px !important;
}

.pb-15 {
  padding-bottom: 60px !important;
}

.pb-16 {
  padding-bottom: 64px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.pl-10 {
  padding-left: 40px !important;
}

.pl-11 {
  padding-left: 44px !important;
}

.pl-12 {
  padding-left: 48px !important;
}

.pl-13 {
  padding-left: 52px !important;
}

.pl-14 {
  padding-left: 56px !important;
}

.pl-15 {
  padding-left: 60px !important;
}

.pl-16 {
  padding-left: 64px !important;
}

/* Border Radius */
.rounded-0 {
  border-radius: 0 !important;
}

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-lg {
  border-radius: 8px !important;
}

.rounded-xl {
  border-radius: 24px !important;
}

.rounded-pill {
  border-radius: 9999px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

/* Overflow */
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

/* Text and alignment */
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-overline {
  text-decoration: overline !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-no-wrap {
  white-space: nowrap !important;
}

.text-pre {
  white-space: pre !important;
}

.text-pre-line {
  white-space: pre-line !important;
}

.text-pre-wrap {
  white-space: pre-wrap !important;
}

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.text-none {
  text-transform: none !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

/* Position */
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

.position-sticky {
  position: sticky !important;
}


/* Hiding content depending on screen size */
@media (max-width: 600px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 959.98px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 960px) and (max-width: 1279.98px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1919.98px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 1920px) and (max-width: 2559.98px) {
  .hidden-xl {
    display: none !important;
  }
}

@media (min-width: 2560px) {
  .hidden-xxl {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .hidden-sm-and-up {
    display: none !important;
  }
}

@media (min-width: 960px) {
  .hidden-md-and-up {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}

@media (min-width: 1920px) {
  .hidden-xl-and-up {
    display: none !important;
  }
}

@media (max-width: 959.98px) {
  .hidden-sm-and-down {
    display: none !important;
  }
}

@media (max-width: 1279.98px) {
  .hidden-md-and-down {
    display: none !important;
  }
}

@media (max-width: 1919.98px) {
  .hidden-lg-and-down {
    display: none !important;
  }
}

@media (max-width: 2559.98px) {
  .hidden-xl-and-down {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .v-locale--is-rtl .float-sm-end {
    float: left !important;
  }
  .v-locale--is-rtl .float-sm-start {
    float: right !important;
  }
  .v-locale--is-ltr .float-sm-end {
    float: right !important;
  }
  .v-locale--is-ltr .float-sm-start {
    float: left !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-1-1 {
    flex: 1 1 auto !important;
  }
  .flex-sm-1-0 {
    flex: 1 0 auto !important;
  }
  .flex-sm-0-1 {
    flex: 0 1 auto !important;
  }
  .flex-sm-0-0 {
    flex: 0 0 auto !important;
  }
  .flex-sm-1-1-100 {
    flex: 1 1 100% !important;
  }
  .flex-sm-1-0-100 {
    flex: 1 0 100% !important;
  }
  .flex-sm-0-1-100 {
    flex: 0 1 100% !important;
  }
  .flex-sm-0-0-100 {
    flex: 0 0 100% !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-sm-start {
    justify-content: flex-start !important;
  }
  .justify-sm-end {
    justify-content: flex-end !important;
  }
  .justify-sm-center {
    justify-content: center !important;
  }
  .justify-sm-space-between {
    justify-content: space-between !important;
  }
  .justify-sm-space-around {
    justify-content: space-around !important;
  }
  .justify-sm-space-evenly {
    justify-content: space-evenly !important;
  }
  .align-sm-start {
    align-items: flex-start !important;
  }
  .align-sm-end {
    align-items: flex-end !important;
  }
  .align-sm-center {
    align-items: center !important;
  }
  .align-sm-baseline {
    align-items: baseline !important;
  }
  .align-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-space-between {
    align-content: space-between !important;
  }
  .align-content-sm-space-around {
    align-content: space-around !important;
  }
  .align-content-sm-space-evenly {
    align-content: space-evenly !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-6 {
    order: 6 !important;
  }
  .order-sm-7 {
    order: 7 !important;
  }
  .order-sm-8 {
    order: 8 !important;
  }
  .order-sm-9 {
    order: 9 !important;
  }
  .order-sm-10 {
    order: 10 !important;
  }
  .order-sm-11 {
    order: 11 !important;
  }
  .order-sm-12 {
    order: 12 !important;
  }
  .order-sm-last {
    order: 13 !important;
  }
  .ga-sm-0 {
    gap: 0px !important;
  }
  .ga-sm-1 {
    gap: 4px !important;
  }
  .ga-sm-2 {
    gap: 8px !important;
  }
  .ga-sm-3 {
    gap: 12px !important;
  }
  .ga-sm-4 {
    gap: 16px !important;
  }
  .ga-sm-5 {
    gap: 20px !important;
  }
  .ga-sm-6 {
    gap: 24px !important;
  }
  .ga-sm-7 {
    gap: 28px !important;
  }
  .ga-sm-8 {
    gap: 32px !important;
  }
  .ga-sm-9 {
    gap: 36px !important;
  }
  .ga-sm-10 {
    gap: 40px !important;
  }
  .ga-sm-11 {
    gap: 44px !important;
  }
  .ga-sm-12 {
    gap: 48px !important;
  }
  .ga-sm-13 {
    gap: 52px !important;
  }
  .ga-sm-14 {
    gap: 56px !important;
  }
  .ga-sm-15 {
    gap: 60px !important;
  }
  .ga-sm-16 {
    gap: 64px !important;
  }
  .ga-sm-auto {
    gap: auto !important;
  }
  .gr-sm-0 {
    row-gap: 0px !important;
  }
  .gr-sm-1 {
    row-gap: 4px !important;
  }
  .gr-sm-2 {
    row-gap: 8px !important;
  }
  .gr-sm-3 {
    row-gap: 12px !important;
  }
  .gr-sm-4 {
    row-gap: 16px !important;
  }
  .gr-sm-5 {
    row-gap: 20px !important;
  }
  .gr-sm-6 {
    row-gap: 24px !important;
  }
  .gr-sm-7 {
    row-gap: 28px !important;
  }
  .gr-sm-8 {
    row-gap: 32px !important;
  }
  .gr-sm-9 {
    row-gap: 36px !important;
  }
  .gr-sm-10 {
    row-gap: 40px !important;
  }
  .gr-sm-11 {
    row-gap: 44px !important;
  }
  .gr-sm-12 {
    row-gap: 48px !important;
  }
  .gr-sm-13 {
    row-gap: 52px !important;
  }
  .gr-sm-14 {
    row-gap: 56px !important;
  }
  .gr-sm-15 {
    row-gap: 60px !important;
  }
  .gr-sm-16 {
    row-gap: 64px !important;
  }
  .gr-sm-auto {
    row-gap: auto !important;
  }
  .gc-sm-0 {
    column-gap: 0px !important;
  }
  .gc-sm-1 {
    column-gap: 4px !important;
  }
  .gc-sm-2 {
    column-gap: 8px !important;
  }
  .gc-sm-3 {
    column-gap: 12px !important;
  }
  .gc-sm-4 {
    column-gap: 16px !important;
  }
  .gc-sm-5 {
    column-gap: 20px !important;
  }
  .gc-sm-6 {
    column-gap: 24px !important;
  }
  .gc-sm-7 {
    column-gap: 28px !important;
  }
  .gc-sm-8 {
    column-gap: 32px !important;
  }
  .gc-sm-9 {
    column-gap: 36px !important;
  }
  .gc-sm-10 {
    column-gap: 40px !important;
  }
  .gc-sm-11 {
    column-gap: 44px !important;
  }
  .gc-sm-12 {
    column-gap: 48px !important;
  }
  .gc-sm-13 {
    column-gap: 52px !important;
  }
  .gc-sm-14 {
    column-gap: 56px !important;
  }
  .gc-sm-15 {
    column-gap: 60px !important;
  }
  .gc-sm-16 {
    column-gap: 64px !important;
  }
  .gc-sm-auto {
    column-gap: auto !important;
  }
  .ma-sm-0 {
    margin: 0px !important;
  }
  .ma-sm-1 {
    margin: 4px !important;
  }
  .ma-sm-2 {
    margin: 8px !important;
  }
  .ma-sm-3 {
    margin: 12px !important;
  }
  .ma-sm-4 {
    margin: 16px !important;
  }
  .ma-sm-5 {
    margin: 20px !important;
  }
  .ma-sm-6 {
    margin: 24px !important;
  }
  .ma-sm-7 {
    margin: 28px !important;
  }
  .ma-sm-8 {
    margin: 32px !important;
  }
  .ma-sm-9 {
    margin: 36px !important;
  }
  .ma-sm-10 {
    margin: 40px !important;
  }
  .ma-sm-11 {
    margin: 44px !important;
  }
  .ma-sm-12 {
    margin: 48px !important;
  }
  .ma-sm-13 {
    margin: 52px !important;
  }
  .ma-sm-14 {
    margin: 56px !important;
  }
  .ma-sm-15 {
    margin: 60px !important;
  }
  .ma-sm-16 {
    margin: 64px !important;
  }
  .ma-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .mx-sm-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-sm-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-sm-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-sm-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-sm-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-sm-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-sm-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-sm-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-sm-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
  .mx-sm-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-sm-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }
  .mx-sm-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
  .mx-sm-13 {
    margin-right: 52px !important;
    margin-left: 52px !important;
  }
  .mx-sm-14 {
    margin-right: 56px !important;
    margin-left: 56px !important;
  }
  .mx-sm-15 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-sm-16 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .my-sm-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-sm-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-sm-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-sm-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-sm-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-sm-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-sm-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-sm-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-sm-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .my-sm-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-sm-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .my-sm-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .my-sm-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }
  .my-sm-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .my-sm-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-sm-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0px !important;
  }
  .mt-sm-1 {
    margin-top: 4px !important;
  }
  .mt-sm-2 {
    margin-top: 8px !important;
  }
  .mt-sm-3 {
    margin-top: 12px !important;
  }
  .mt-sm-4 {
    margin-top: 16px !important;
  }
  .mt-sm-5 {
    margin-top: 20px !important;
  }
  .mt-sm-6 {
    margin-top: 24px !important;
  }
  .mt-sm-7 {
    margin-top: 28px !important;
  }
  .mt-sm-8 {
    margin-top: 32px !important;
  }
  .mt-sm-9 {
    margin-top: 36px !important;
  }
  .mt-sm-10 {
    margin-top: 40px !important;
  }
  .mt-sm-11 {
    margin-top: 44px !important;
  }
  .mt-sm-12 {
    margin-top: 48px !important;
  }
  .mt-sm-13 {
    margin-top: 52px !important;
  }
  .mt-sm-14 {
    margin-top: 56px !important;
  }
  .mt-sm-15 {
    margin-top: 60px !important;
  }
  .mt-sm-16 {
    margin-top: 64px !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-0 {
    margin-right: 0px !important;
  }
  .mr-sm-1 {
    margin-right: 4px !important;
  }
  .mr-sm-2 {
    margin-right: 8px !important;
  }
  .mr-sm-3 {
    margin-right: 12px !important;
  }
  .mr-sm-4 {
    margin-right: 16px !important;
  }
  .mr-sm-5 {
    margin-right: 20px !important;
  }
  .mr-sm-6 {
    margin-right: 24px !important;
  }
  .mr-sm-7 {
    margin-right: 28px !important;
  }
  .mr-sm-8 {
    margin-right: 32px !important;
  }
  .mr-sm-9 {
    margin-right: 36px !important;
  }
  .mr-sm-10 {
    margin-right: 40px !important;
  }
  .mr-sm-11 {
    margin-right: 44px !important;
  }
  .mr-sm-12 {
    margin-right: 48px !important;
  }
  .mr-sm-13 {
    margin-right: 52px !important;
  }
  .mr-sm-14 {
    margin-right: 56px !important;
  }
  .mr-sm-15 {
    margin-right: 60px !important;
  }
  .mr-sm-16 {
    margin-right: 64px !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0px !important;
  }
  .mb-sm-1 {
    margin-bottom: 4px !important;
  }
  .mb-sm-2 {
    margin-bottom: 8px !important;
  }
  .mb-sm-3 {
    margin-bottom: 12px !important;
  }
  .mb-sm-4 {
    margin-bottom: 16px !important;
  }
  .mb-sm-5 {
    margin-bottom: 20px !important;
  }
  .mb-sm-6 {
    margin-bottom: 24px !important;
  }
  .mb-sm-7 {
    margin-bottom: 28px !important;
  }
  .mb-sm-8 {
    margin-bottom: 32px !important;
  }
  .mb-sm-9 {
    margin-bottom: 36px !important;
  }
  .mb-sm-10 {
    margin-bottom: 40px !important;
  }
  .mb-sm-11 {
    margin-bottom: 44px !important;
  }
  .mb-sm-12 {
    margin-bottom: 48px !important;
  }
  .mb-sm-13 {
    margin-bottom: 52px !important;
  }
  .mb-sm-14 {
    margin-bottom: 56px !important;
  }
  .mb-sm-15 {
    margin-bottom: 60px !important;
  }
  .mb-sm-16 {
    margin-bottom: 64px !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-0 {
    margin-left: 0px !important;
  }
  .ml-sm-1 {
    margin-left: 4px !important;
  }
  .ml-sm-2 {
    margin-left: 8px !important;
  }
  .ml-sm-3 {
    margin-left: 12px !important;
  }
  .ml-sm-4 {
    margin-left: 16px !important;
  }
  .ml-sm-5 {
    margin-left: 20px !important;
  }
  .ml-sm-6 {
    margin-left: 24px !important;
  }
  .ml-sm-7 {
    margin-left: 28px !important;
  }
  .ml-sm-8 {
    margin-left: 32px !important;
  }
  .ml-sm-9 {
    margin-left: 36px !important;
  }
  .ml-sm-10 {
    margin-left: 40px !important;
  }
  .ml-sm-11 {
    margin-left: 44px !important;
  }
  .ml-sm-12 {
    margin-left: 48px !important;
  }
  .ml-sm-13 {
    margin-left: 52px !important;
  }
  .ml-sm-14 {
    margin-left: 56px !important;
  }
  .ml-sm-15 {
    margin-left: 60px !important;
  }
  .ml-sm-16 {
    margin-left: 64px !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .ms-sm-0 {
    margin-inline-start: 0px !important;
  }
  .ms-sm-1 {
    margin-inline-start: 4px !important;
  }
  .ms-sm-2 {
    margin-inline-start: 8px !important;
  }
  .ms-sm-3 {
    margin-inline-start: 12px !important;
  }
  .ms-sm-4 {
    margin-inline-start: 16px !important;
  }
  .ms-sm-5 {
    margin-inline-start: 20px !important;
  }
  .ms-sm-6 {
    margin-inline-start: 24px !important;
  }
  .ms-sm-7 {
    margin-inline-start: 28px !important;
  }
  .ms-sm-8 {
    margin-inline-start: 32px !important;
  }
  .ms-sm-9 {
    margin-inline-start: 36px !important;
  }
  .ms-sm-10 {
    margin-inline-start: 40px !important;
  }
  .ms-sm-11 {
    margin-inline-start: 44px !important;
  }
  .ms-sm-12 {
    margin-inline-start: 48px !important;
  }
  .ms-sm-13 {
    margin-inline-start: 52px !important;
  }
  .ms-sm-14 {
    margin-inline-start: 56px !important;
  }
  .ms-sm-15 {
    margin-inline-start: 60px !important;
  }
  .ms-sm-16 {
    margin-inline-start: 64px !important;
  }
  .ms-sm-auto {
    margin-inline-start: auto !important;
  }
  .me-sm-0 {
    margin-inline-end: 0px !important;
  }
  .me-sm-1 {
    margin-inline-end: 4px !important;
  }
  .me-sm-2 {
    margin-inline-end: 8px !important;
  }
  .me-sm-3 {
    margin-inline-end: 12px !important;
  }
  .me-sm-4 {
    margin-inline-end: 16px !important;
  }
  .me-sm-5 {
    margin-inline-end: 20px !important;
  }
  .me-sm-6 {
    margin-inline-end: 24px !important;
  }
  .me-sm-7 {
    margin-inline-end: 28px !important;
  }
  .me-sm-8 {
    margin-inline-end: 32px !important;
  }
  .me-sm-9 {
    margin-inline-end: 36px !important;
  }
  .me-sm-10 {
    margin-inline-end: 40px !important;
  }
  .me-sm-11 {
    margin-inline-end: 44px !important;
  }
  .me-sm-12 {
    margin-inline-end: 48px !important;
  }
  .me-sm-13 {
    margin-inline-end: 52px !important;
  }
  .me-sm-14 {
    margin-inline-end: 56px !important;
  }
  .me-sm-15 {
    margin-inline-end: 60px !important;
  }
  .me-sm-16 {
    margin-inline-end: 64px !important;
  }
  .me-sm-auto {
    margin-inline-end: auto !important;
  }
  .ma-sm-n1 {
    margin: -4px !important;
  }
  .ma-sm-n2 {
    margin: -8px !important;
  }
  .ma-sm-n3 {
    margin: -12px !important;
  }
  .ma-sm-n4 {
    margin: -16px !important;
  }
  .ma-sm-n5 {
    margin: -20px !important;
  }
  .ma-sm-n6 {
    margin: -24px !important;
  }
  .ma-sm-n7 {
    margin: -28px !important;
  }
  .ma-sm-n8 {
    margin: -32px !important;
  }
  .ma-sm-n9 {
    margin: -36px !important;
  }
  .ma-sm-n10 {
    margin: -40px !important;
  }
  .ma-sm-n11 {
    margin: -44px !important;
  }
  .ma-sm-n12 {
    margin: -48px !important;
  }
  .ma-sm-n13 {
    margin: -52px !important;
  }
  .ma-sm-n14 {
    margin: -56px !important;
  }
  .ma-sm-n15 {
    margin: -60px !important;
  }
  .ma-sm-n16 {
    margin: -64px !important;
  }
  .mx-sm-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-sm-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-sm-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-sm-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-sm-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-sm-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-sm-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .mx-sm-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-sm-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }
  .mx-sm-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-sm-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }
  .mx-sm-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }
  .mx-sm-n13 {
    margin-right: -52px !important;
    margin-left: -52px !important;
  }
  .mx-sm-n14 {
    margin-right: -56px !important;
    margin-left: -56px !important;
  }
  .mx-sm-n15 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-sm-n16 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }
  .my-sm-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-sm-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-sm-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-sm-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-sm-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-sm-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-sm-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .my-sm-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-sm-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .my-sm-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-sm-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }
  .my-sm-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .my-sm-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }
  .my-sm-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .my-sm-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-sm-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .mt-sm-n1 {
    margin-top: -4px !important;
  }
  .mt-sm-n2 {
    margin-top: -8px !important;
  }
  .mt-sm-n3 {
    margin-top: -12px !important;
  }
  .mt-sm-n4 {
    margin-top: -16px !important;
  }
  .mt-sm-n5 {
    margin-top: -20px !important;
  }
  .mt-sm-n6 {
    margin-top: -24px !important;
  }
  .mt-sm-n7 {
    margin-top: -28px !important;
  }
  .mt-sm-n8 {
    margin-top: -32px !important;
  }
  .mt-sm-n9 {
    margin-top: -36px !important;
  }
  .mt-sm-n10 {
    margin-top: -40px !important;
  }
  .mt-sm-n11 {
    margin-top: -44px !important;
  }
  .mt-sm-n12 {
    margin-top: -48px !important;
  }
  .mt-sm-n13 {
    margin-top: -52px !important;
  }
  .mt-sm-n14 {
    margin-top: -56px !important;
  }
  .mt-sm-n15 {
    margin-top: -60px !important;
  }
  .mt-sm-n16 {
    margin-top: -64px !important;
  }
  .mr-sm-n1 {
    margin-right: -4px !important;
  }
  .mr-sm-n2 {
    margin-right: -8px !important;
  }
  .mr-sm-n3 {
    margin-right: -12px !important;
  }
  .mr-sm-n4 {
    margin-right: -16px !important;
  }
  .mr-sm-n5 {
    margin-right: -20px !important;
  }
  .mr-sm-n6 {
    margin-right: -24px !important;
  }
  .mr-sm-n7 {
    margin-right: -28px !important;
  }
  .mr-sm-n8 {
    margin-right: -32px !important;
  }
  .mr-sm-n9 {
    margin-right: -36px !important;
  }
  .mr-sm-n10 {
    margin-right: -40px !important;
  }
  .mr-sm-n11 {
    margin-right: -44px !important;
  }
  .mr-sm-n12 {
    margin-right: -48px !important;
  }
  .mr-sm-n13 {
    margin-right: -52px !important;
  }
  .mr-sm-n14 {
    margin-right: -56px !important;
  }
  .mr-sm-n15 {
    margin-right: -60px !important;
  }
  .mr-sm-n16 {
    margin-right: -64px !important;
  }
  .mb-sm-n1 {
    margin-bottom: -4px !important;
  }
  .mb-sm-n2 {
    margin-bottom: -8px !important;
  }
  .mb-sm-n3 {
    margin-bottom: -12px !important;
  }
  .mb-sm-n4 {
    margin-bottom: -16px !important;
  }
  .mb-sm-n5 {
    margin-bottom: -20px !important;
  }
  .mb-sm-n6 {
    margin-bottom: -24px !important;
  }
  .mb-sm-n7 {
    margin-bottom: -28px !important;
  }
  .mb-sm-n8 {
    margin-bottom: -32px !important;
  }
  .mb-sm-n9 {
    margin-bottom: -36px !important;
  }
  .mb-sm-n10 {
    margin-bottom: -40px !important;
  }
  .mb-sm-n11 {
    margin-bottom: -44px !important;
  }
  .mb-sm-n12 {
    margin-bottom: -48px !important;
  }
  .mb-sm-n13 {
    margin-bottom: -52px !important;
  }
  .mb-sm-n14 {
    margin-bottom: -56px !important;
  }
  .mb-sm-n15 {
    margin-bottom: -60px !important;
  }
  .mb-sm-n16 {
    margin-bottom: -64px !important;
  }
  .ml-sm-n1 {
    margin-left: -4px !important;
  }
  .ml-sm-n2 {
    margin-left: -8px !important;
  }
  .ml-sm-n3 {
    margin-left: -12px !important;
  }
  .ml-sm-n4 {
    margin-left: -16px !important;
  }
  .ml-sm-n5 {
    margin-left: -20px !important;
  }
  .ml-sm-n6 {
    margin-left: -24px !important;
  }
  .ml-sm-n7 {
    margin-left: -28px !important;
  }
  .ml-sm-n8 {
    margin-left: -32px !important;
  }
  .ml-sm-n9 {
    margin-left: -36px !important;
  }
  .ml-sm-n10 {
    margin-left: -40px !important;
  }
  .ml-sm-n11 {
    margin-left: -44px !important;
  }
  .ml-sm-n12 {
    margin-left: -48px !important;
  }
  .ml-sm-n13 {
    margin-left: -52px !important;
  }
  .ml-sm-n14 {
    margin-left: -56px !important;
  }
  .ml-sm-n15 {
    margin-left: -60px !important;
  }
  .ml-sm-n16 {
    margin-left: -64px !important;
  }
  .ms-sm-n1 {
    margin-inline-start: -4px !important;
  }
  .ms-sm-n2 {
    margin-inline-start: -8px !important;
  }
  .ms-sm-n3 {
    margin-inline-start: -12px !important;
  }
  .ms-sm-n4 {
    margin-inline-start: -16px !important;
  }
  .ms-sm-n5 {
    margin-inline-start: -20px !important;
  }
  .ms-sm-n6 {
    margin-inline-start: -24px !important;
  }
  .ms-sm-n7 {
    margin-inline-start: -28px !important;
  }
  .ms-sm-n8 {
    margin-inline-start: -32px !important;
  }
  .ms-sm-n9 {
    margin-inline-start: -36px !important;
  }
  .ms-sm-n10 {
    margin-inline-start: -40px !important;
  }
  .ms-sm-n11 {
    margin-inline-start: -44px !important;
  }
  .ms-sm-n12 {
    margin-inline-start: -48px !important;
  }
  .ms-sm-n13 {
    margin-inline-start: -52px !important;
  }
  .ms-sm-n14 {
    margin-inline-start: -56px !important;
  }
  .ms-sm-n15 {
    margin-inline-start: -60px !important;
  }
  .ms-sm-n16 {
    margin-inline-start: -64px !important;
  }
  .me-sm-n1 {
    margin-inline-end: -4px !important;
  }
  .me-sm-n2 {
    margin-inline-end: -8px !important;
  }
  .me-sm-n3 {
    margin-inline-end: -12px !important;
  }
  .me-sm-n4 {
    margin-inline-end: -16px !important;
  }
  .me-sm-n5 {
    margin-inline-end: -20px !important;
  }
  .me-sm-n6 {
    margin-inline-end: -24px !important;
  }
  .me-sm-n7 {
    margin-inline-end: -28px !important;
  }
  .me-sm-n8 {
    margin-inline-end: -32px !important;
  }
  .me-sm-n9 {
    margin-inline-end: -36px !important;
  }
  .me-sm-n10 {
    margin-inline-end: -40px !important;
  }
  .me-sm-n11 {
    margin-inline-end: -44px !important;
  }
  .me-sm-n12 {
    margin-inline-end: -48px !important;
  }
  .me-sm-n13 {
    margin-inline-end: -52px !important;
  }
  .me-sm-n14 {
    margin-inline-end: -56px !important;
  }
  .me-sm-n15 {
    margin-inline-end: -60px !important;
  }
  .me-sm-n16 {
    margin-inline-end: -64px !important;
  }
  .pa-sm-0 {
    padding: 0px !important;
  }
  .pa-sm-1 {
    padding: 4px !important;
  }
  .pa-sm-2 {
    padding: 8px !important;
  }
  .pa-sm-3 {
    padding: 12px !important;
  }
  .pa-sm-4 {
    padding: 16px !important;
  }
  .pa-sm-5 {
    padding: 20px !important;
  }
  .pa-sm-6 {
    padding: 24px !important;
  }
  .pa-sm-7 {
    padding: 28px !important;
  }
  .pa-sm-8 {
    padding: 32px !important;
  }
  .pa-sm-9 {
    padding: 36px !important;
  }
  .pa-sm-10 {
    padding: 40px !important;
  }
  .pa-sm-11 {
    padding: 44px !important;
  }
  .pa-sm-12 {
    padding: 48px !important;
  }
  .pa-sm-13 {
    padding: 52px !important;
  }
  .pa-sm-14 {
    padding: 56px !important;
  }
  .pa-sm-15 {
    padding: 60px !important;
  }
  .pa-sm-16 {
    padding: 64px !important;
  }
  .px-sm-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .px-sm-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-sm-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-sm-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-sm-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-sm-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-sm-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-sm-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .px-sm-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-sm-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
  .px-sm-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-sm-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }
  .px-sm-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
  .px-sm-13 {
    padding-right: 52px !important;
    padding-left: 52px !important;
  }
  .px-sm-14 {
    padding-right: 56px !important;
    padding-left: 56px !important;
  }
  .px-sm-15 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-sm-16 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
  .py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .py-sm-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-sm-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-sm-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-sm-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-sm-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-sm-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-sm-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .py-sm-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-sm-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .py-sm-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-sm-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .py-sm-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .py-sm-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }
  .py-sm-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .py-sm-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-sm-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .pt-sm-0 {
    padding-top: 0px !important;
  }
  .pt-sm-1 {
    padding-top: 4px !important;
  }
  .pt-sm-2 {
    padding-top: 8px !important;
  }
  .pt-sm-3 {
    padding-top: 12px !important;
  }
  .pt-sm-4 {
    padding-top: 16px !important;
  }
  .pt-sm-5 {
    padding-top: 20px !important;
  }
  .pt-sm-6 {
    padding-top: 24px !important;
  }
  .pt-sm-7 {
    padding-top: 28px !important;
  }
  .pt-sm-8 {
    padding-top: 32px !important;
  }
  .pt-sm-9 {
    padding-top: 36px !important;
  }
  .pt-sm-10 {
    padding-top: 40px !important;
  }
  .pt-sm-11 {
    padding-top: 44px !important;
  }
  .pt-sm-12 {
    padding-top: 48px !important;
  }
  .pt-sm-13 {
    padding-top: 52px !important;
  }
  .pt-sm-14 {
    padding-top: 56px !important;
  }
  .pt-sm-15 {
    padding-top: 60px !important;
  }
  .pt-sm-16 {
    padding-top: 64px !important;
  }
  .pr-sm-0 {
    padding-right: 0px !important;
  }
  .pr-sm-1 {
    padding-right: 4px !important;
  }
  .pr-sm-2 {
    padding-right: 8px !important;
  }
  .pr-sm-3 {
    padding-right: 12px !important;
  }
  .pr-sm-4 {
    padding-right: 16px !important;
  }
  .pr-sm-5 {
    padding-right: 20px !important;
  }
  .pr-sm-6 {
    padding-right: 24px !important;
  }
  .pr-sm-7 {
    padding-right: 28px !important;
  }
  .pr-sm-8 {
    padding-right: 32px !important;
  }
  .pr-sm-9 {
    padding-right: 36px !important;
  }
  .pr-sm-10 {
    padding-right: 40px !important;
  }
  .pr-sm-11 {
    padding-right: 44px !important;
  }
  .pr-sm-12 {
    padding-right: 48px !important;
  }
  .pr-sm-13 {
    padding-right: 52px !important;
  }
  .pr-sm-14 {
    padding-right: 56px !important;
  }
  .pr-sm-15 {
    padding-right: 60px !important;
  }
  .pr-sm-16 {
    padding-right: 64px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0px !important;
  }
  .pb-sm-1 {
    padding-bottom: 4px !important;
  }
  .pb-sm-2 {
    padding-bottom: 8px !important;
  }
  .pb-sm-3 {
    padding-bottom: 12px !important;
  }
  .pb-sm-4 {
    padding-bottom: 16px !important;
  }
  .pb-sm-5 {
    padding-bottom: 20px !important;
  }
  .pb-sm-6 {
    padding-bottom: 24px !important;
  }
  .pb-sm-7 {
    padding-bottom: 28px !important;
  }
  .pb-sm-8 {
    padding-bottom: 32px !important;
  }
  .pb-sm-9 {
    padding-bottom: 36px !important;
  }
  .pb-sm-10 {
    padding-bottom: 40px !important;
  }
  .pb-sm-11 {
    padding-bottom: 44px !important;
  }
  .pb-sm-12 {
    padding-bottom: 48px !important;
  }
  .pb-sm-13 {
    padding-bottom: 52px !important;
  }
  .pb-sm-14 {
    padding-bottom: 56px !important;
  }
  .pb-sm-15 {
    padding-bottom: 60px !important;
  }
  .pb-sm-16 {
    padding-bottom: 64px !important;
  }
  .pl-sm-0 {
    padding-left: 0px !important;
  }
  .pl-sm-1 {
    padding-left: 4px !important;
  }
  .pl-sm-2 {
    padding-left: 8px !important;
  }
  .pl-sm-3 {
    padding-left: 12px !important;
  }
  .pl-sm-4 {
    padding-left: 16px !important;
  }
  .pl-sm-5 {
    padding-left: 20px !important;
  }
  .pl-sm-6 {
    padding-left: 24px !important;
  }
  .pl-sm-7 {
    padding-left: 28px !important;
  }
  .pl-sm-8 {
    padding-left: 32px !important;
  }
  .pl-sm-9 {
    padding-left: 36px !important;
  }
  .pl-sm-10 {
    padding-left: 40px !important;
  }
  .pl-sm-11 {
    padding-left: 44px !important;
  }
  .pl-sm-12 {
    padding-left: 48px !important;
  }
  .pl-sm-13 {
    padding-left: 52px !important;
  }
  .pl-sm-14 {
    padding-left: 56px !important;
  }
  .pl-sm-15 {
    padding-left: 60px !important;
  }
  .pl-sm-16 {
    padding-left: 64px !important;
  }
  .ps-sm-0 {
    padding-inline-start: 0px !important;
  }
  .ps-sm-1 {
    padding-inline-start: 4px !important;
  }
  .ps-sm-2 {
    padding-inline-start: 8px !important;
  }
  .ps-sm-3 {
    padding-inline-start: 12px !important;
  }
  .ps-sm-4 {
    padding-inline-start: 16px !important;
  }
  .ps-sm-5 {
    padding-inline-start: 20px !important;
  }
  .ps-sm-6 {
    padding-inline-start: 24px !important;
  }
  .ps-sm-7 {
    padding-inline-start: 28px !important;
  }
  .ps-sm-8 {
    padding-inline-start: 32px !important;
  }
  .ps-sm-9 {
    padding-inline-start: 36px !important;
  }
  .ps-sm-10 {
    padding-inline-start: 40px !important;
  }
  .ps-sm-11 {
    padding-inline-start: 44px !important;
  }
  .ps-sm-12 {
    padding-inline-start: 48px !important;
  }
  .ps-sm-13 {
    padding-inline-start: 52px !important;
  }
  .ps-sm-14 {
    padding-inline-start: 56px !important;
  }
  .ps-sm-15 {
    padding-inline-start: 60px !important;
  }
  .ps-sm-16 {
    padding-inline-start: 64px !important;
  }
  .pe-sm-0 {
    padding-inline-end: 0px !important;
  }
  .pe-sm-1 {
    padding-inline-end: 4px !important;
  }
  .pe-sm-2 {
    padding-inline-end: 8px !important;
  }
  .pe-sm-3 {
    padding-inline-end: 12px !important;
  }
  .pe-sm-4 {
    padding-inline-end: 16px !important;
  }
  .pe-sm-5 {
    padding-inline-end: 20px !important;
  }
  .pe-sm-6 {
    padding-inline-end: 24px !important;
  }
  .pe-sm-7 {
    padding-inline-end: 28px !important;
  }
  .pe-sm-8 {
    padding-inline-end: 32px !important;
  }
  .pe-sm-9 {
    padding-inline-end: 36px !important;
  }
  .pe-sm-10 {
    padding-inline-end: 40px !important;
  }
  .pe-sm-11 {
    padding-inline-end: 44px !important;
  }
  .pe-sm-12 {
    padding-inline-end: 48px !important;
  }
  .pe-sm-13 {
    padding-inline-end: 52px !important;
  }
  .pe-sm-14 {
    padding-inline-end: 56px !important;
  }
  .pe-sm-15 {
    padding-inline-end: 60px !important;
  }
  .pe-sm-16 {
    padding-inline-end: 64px !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-justify {
    text-align: justify !important;
  }
  .text-sm-start {
    text-align: start !important;
  }
  .text-sm-end {
    text-align: end !important;
  }
  .text-sm-h1 {
    font-size: 6rem !important;
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-h2 {
    font-size: 3.75rem !important;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-h3 {
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-h4 {
    font-size: 2.125rem !important;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-h6 {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-subtitle-1 {
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 1.75rem;
    letter-spacing: 0.009375em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-subtitle-2 {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-body-1 {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.03125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-body-2 {
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-button {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0.0892857143em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
  .text-sm-caption {
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-sm-overline {
    font-size: 0.75rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1666666667em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }

  .col-sm-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .float-md-none {
    float: none !important;
  }
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .v-locale--is-rtl .float-md-end {
    float: left !important;
  }
  .v-locale--is-rtl .float-md-start {
    float: right !important;
  }
  .v-locale--is-ltr .float-md-end {
    float: right !important;
  }
  .v-locale--is-ltr .float-md-start {
    float: left !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-1-1 {
    flex: 1 1 auto !important;
  }
  .flex-md-1-0 {
    flex: 1 0 auto !important;
  }
  .flex-md-0-1 {
    flex: 0 1 auto !important;
  }
  .flex-md-0-0 {
    flex: 0 0 auto !important;
  }
  .flex-md-1-1-100 {
    flex: 1 1 100% !important;
  }
  .flex-md-1-0-100 {
    flex: 1 0 100% !important;
  }
  .flex-md-0-1-100 {
    flex: 0 1 100% !important;
  }
  .flex-md-0-0-100 {
    flex: 0 0 100% !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-md-start {
    justify-content: flex-start !important;
  }
  .justify-md-end {
    justify-content: flex-end !important;
  }
  .justify-md-center {
    justify-content: center !important;
  }
  .justify-md-space-between {
    justify-content: space-between !important;
  }
  .justify-md-space-around {
    justify-content: space-around !important;
  }
  .justify-md-space-evenly {
    justify-content: space-evenly !important;
  }
  .align-md-start {
    align-items: flex-start !important;
  }
  .align-md-end {
    align-items: flex-end !important;
  }
  .align-md-center {
    align-items: center !important;
  }
  .align-md-baseline {
    align-items: baseline !important;
  }
  .align-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-space-between {
    align-content: space-between !important;
  }
  .align-content-md-space-around {
    align-content: space-around !important;
  }
  .align-content-md-space-evenly {
    align-content: space-evenly !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-6 {
    order: 6 !important;
  }
  .order-md-7 {
    order: 7 !important;
  }
  .order-md-8 {
    order: 8 !important;
  }
  .order-md-9 {
    order: 9 !important;
  }
  .order-md-10 {
    order: 10 !important;
  }
  .order-md-11 {
    order: 11 !important;
  }
  .order-md-12 {
    order: 12 !important;
  }
  .order-md-last {
    order: 13 !important;
  }
  .ga-md-0 {
    gap: 0px !important;
  }
  .ga-md-1 {
    gap: 4px !important;
  }
  .ga-md-2 {
    gap: 8px !important;
  }
  .ga-md-3 {
    gap: 12px !important;
  }
  .ga-md-4 {
    gap: 16px !important;
  }
  .ga-md-5 {
    gap: 20px !important;
  }
  .ga-md-6 {
    gap: 24px !important;
  }
  .ga-md-7 {
    gap: 28px !important;
  }
  .ga-md-8 {
    gap: 32px !important;
  }
  .ga-md-9 {
    gap: 36px !important;
  }
  .ga-md-10 {
    gap: 40px !important;
  }
  .ga-md-11 {
    gap: 44px !important;
  }
  .ga-md-12 {
    gap: 48px !important;
  }
  .ga-md-13 {
    gap: 52px !important;
  }
  .ga-md-14 {
    gap: 56px !important;
  }
  .ga-md-15 {
    gap: 60px !important;
  }
  .ga-md-16 {
    gap: 64px !important;
  }
  .ga-md-auto {
    gap: auto !important;
  }
  .gr-md-0 {
    row-gap: 0px !important;
  }
  .gr-md-1 {
    row-gap: 4px !important;
  }
  .gr-md-2 {
    row-gap: 8px !important;
  }
  .gr-md-3 {
    row-gap: 12px !important;
  }
  .gr-md-4 {
    row-gap: 16px !important;
  }
  .gr-md-5 {
    row-gap: 20px !important;
  }
  .gr-md-6 {
    row-gap: 24px !important;
  }
  .gr-md-7 {
    row-gap: 28px !important;
  }
  .gr-md-8 {
    row-gap: 32px !important;
  }
  .gr-md-9 {
    row-gap: 36px !important;
  }
  .gr-md-10 {
    row-gap: 40px !important;
  }
  .gr-md-11 {
    row-gap: 44px !important;
  }
  .gr-md-12 {
    row-gap: 48px !important;
  }
  .gr-md-13 {
    row-gap: 52px !important;
  }
  .gr-md-14 {
    row-gap: 56px !important;
  }
  .gr-md-15 {
    row-gap: 60px !important;
  }
  .gr-md-16 {
    row-gap: 64px !important;
  }
  .gr-md-auto {
    row-gap: auto !important;
  }
  .gc-md-0 {
    column-gap: 0px !important;
  }
  .gc-md-1 {
    column-gap: 4px !important;
  }
  .gc-md-2 {
    column-gap: 8px !important;
  }
  .gc-md-3 {
    column-gap: 12px !important;
  }
  .gc-md-4 {
    column-gap: 16px !important;
  }
  .gc-md-5 {
    column-gap: 20px !important;
  }
  .gc-md-6 {
    column-gap: 24px !important;
  }
  .gc-md-7 {
    column-gap: 28px !important;
  }
  .gc-md-8 {
    column-gap: 32px !important;
  }
  .gc-md-9 {
    column-gap: 36px !important;
  }
  .gc-md-10 {
    column-gap: 40px !important;
  }
  .gc-md-11 {
    column-gap: 44px !important;
  }
  .gc-md-12 {
    column-gap: 48px !important;
  }
  .gc-md-13 {
    column-gap: 52px !important;
  }
  .gc-md-14 {
    column-gap: 56px !important;
  }
  .gc-md-15 {
    column-gap: 60px !important;
  }
  .gc-md-16 {
    column-gap: 64px !important;
  }
  .gc-md-auto {
    column-gap: auto !important;
  }
  .ma-md-0 {
    margin: 0px !important;
  }
  .ma-md-1 {
    margin: 4px !important;
  }
  .ma-md-2 {
    margin: 8px !important;
  }
  .ma-md-3 {
    margin: 12px !important;
  }
  .ma-md-4 {
    margin: 16px !important;
  }
  .ma-md-5 {
    margin: 20px !important;
  }
  .ma-md-6 {
    margin: 24px !important;
  }
  .ma-md-7 {
    margin: 28px !important;
  }
  .ma-md-8 {
    margin: 32px !important;
  }
  .ma-md-9 {
    margin: 36px !important;
  }
  .ma-md-10 {
    margin: 40px !important;
  }
  .ma-md-11 {
    margin: 44px !important;
  }
  .ma-md-12 {
    margin: 48px !important;
  }
  .ma-md-13 {
    margin: 52px !important;
  }
  .ma-md-14 {
    margin: 56px !important;
  }
  .ma-md-15 {
    margin: 60px !important;
  }
  .ma-md-16 {
    margin: 64px !important;
  }
  .ma-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .mx-md-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-md-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-md-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-md-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-md-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-md-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-md-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-md-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-md-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
  .mx-md-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-md-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }
  .mx-md-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
  .mx-md-13 {
    margin-right: 52px !important;
    margin-left: 52px !important;
  }
  .mx-md-14 {
    margin-right: 56px !important;
    margin-left: 56px !important;
  }
  .mx-md-15 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-md-16 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .my-md-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-md-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-md-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-md-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-md-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-md-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-md-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-md-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-md-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .my-md-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-md-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .my-md-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .my-md-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }
  .my-md-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .my-md-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-md-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0px !important;
  }
  .mt-md-1 {
    margin-top: 4px !important;
  }
  .mt-md-2 {
    margin-top: 8px !important;
  }
  .mt-md-3 {
    margin-top: 12px !important;
  }
  .mt-md-4 {
    margin-top: 16px !important;
  }
  .mt-md-5 {
    margin-top: 20px !important;
  }
  .mt-md-6 {
    margin-top: 24px !important;
  }
  .mt-md-7 {
    margin-top: 28px !important;
  }
  .mt-md-8 {
    margin-top: 32px !important;
  }
  .mt-md-9 {
    margin-top: 36px !important;
  }
  .mt-md-10 {
    margin-top: 40px !important;
  }
  .mt-md-11 {
    margin-top: 44px !important;
  }
  .mt-md-12 {
    margin-top: 48px !important;
  }
  .mt-md-13 {
    margin-top: 52px !important;
  }
  .mt-md-14 {
    margin-top: 56px !important;
  }
  .mt-md-15 {
    margin-top: 60px !important;
  }
  .mt-md-16 {
    margin-top: 64px !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-0 {
    margin-right: 0px !important;
  }
  .mr-md-1 {
    margin-right: 4px !important;
  }
  .mr-md-2 {
    margin-right: 8px !important;
  }
  .mr-md-3 {
    margin-right: 12px !important;
  }
  .mr-md-4 {
    margin-right: 16px !important;
  }
  .mr-md-5 {
    margin-right: 20px !important;
  }
  .mr-md-6 {
    margin-right: 24px !important;
  }
  .mr-md-7 {
    margin-right: 28px !important;
  }
  .mr-md-8 {
    margin-right: 32px !important;
  }
  .mr-md-9 {
    margin-right: 36px !important;
  }
  .mr-md-10 {
    margin-right: 40px !important;
  }
  .mr-md-11 {
    margin-right: 44px !important;
  }
  .mr-md-12 {
    margin-right: 48px !important;
  }
  .mr-md-13 {
    margin-right: 52px !important;
  }
  .mr-md-14 {
    margin-right: 56px !important;
  }
  .mr-md-15 {
    margin-right: 60px !important;
  }
  .mr-md-16 {
    margin-right: 64px !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .mb-md-1 {
    margin-bottom: 4px !important;
  }
  .mb-md-2 {
    margin-bottom: 8px !important;
  }
  .mb-md-3 {
    margin-bottom: 12px !important;
  }
  .mb-md-4 {
    margin-bottom: 16px !important;
  }
  .mb-md-5 {
    margin-bottom: 20px !important;
  }
  .mb-md-6 {
    margin-bottom: 24px !important;
  }
  .mb-md-7 {
    margin-bottom: 28px !important;
  }
  .mb-md-8 {
    margin-bottom: 32px !important;
  }
  .mb-md-9 {
    margin-bottom: 36px !important;
  }
  .mb-md-10 {
    margin-bottom: 40px !important;
  }
  .mb-md-11 {
    margin-bottom: 44px !important;
  }
  .mb-md-12 {
    margin-bottom: 48px !important;
  }
  .mb-md-13 {
    margin-bottom: 52px !important;
  }
  .mb-md-14 {
    margin-bottom: 56px !important;
  }
  .mb-md-15 {
    margin-bottom: 60px !important;
  }
  .mb-md-16 {
    margin-bottom: 64px !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-0 {
    margin-left: 0px !important;
  }
  .ml-md-1 {
    margin-left: 4px !important;
  }
  .ml-md-2 {
    margin-left: 8px !important;
  }
  .ml-md-3 {
    margin-left: 12px !important;
  }
  .ml-md-4 {
    margin-left: 16px !important;
  }
  .ml-md-5 {
    margin-left: 20px !important;
  }
  .ml-md-6 {
    margin-left: 24px !important;
  }
  .ml-md-7 {
    margin-left: 28px !important;
  }
  .ml-md-8 {
    margin-left: 32px !important;
  }
  .ml-md-9 {
    margin-left: 36px !important;
  }
  .ml-md-10 {
    margin-left: 40px !important;
  }
  .ml-md-11 {
    margin-left: 44px !important;
  }
  .ml-md-12 {
    margin-left: 48px !important;
  }
  .ml-md-13 {
    margin-left: 52px !important;
  }
  .ml-md-14 {
    margin-left: 56px !important;
  }
  .ml-md-15 {
    margin-left: 60px !important;
  }
  .ml-md-16 {
    margin-left: 64px !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .ms-md-0 {
    margin-inline-start: 0px !important;
  }
  .ms-md-1 {
    margin-inline-start: 4px !important;
  }
  .ms-md-2 {
    margin-inline-start: 8px !important;
  }
  .ms-md-3 {
    margin-inline-start: 12px !important;
  }
  .ms-md-4 {
    margin-inline-start: 16px !important;
  }
  .ms-md-5 {
    margin-inline-start: 20px !important;
  }
  .ms-md-6 {
    margin-inline-start: 24px !important;
  }
  .ms-md-7 {
    margin-inline-start: 28px !important;
  }
  .ms-md-8 {
    margin-inline-start: 32px !important;
  }
  .ms-md-9 {
    margin-inline-start: 36px !important;
  }
  .ms-md-10 {
    margin-inline-start: 40px !important;
  }
  .ms-md-11 {
    margin-inline-start: 44px !important;
  }
  .ms-md-12 {
    margin-inline-start: 48px !important;
  }
  .ms-md-13 {
    margin-inline-start: 52px !important;
  }
  .ms-md-14 {
    margin-inline-start: 56px !important;
  }
  .ms-md-15 {
    margin-inline-start: 60px !important;
  }
  .ms-md-16 {
    margin-inline-start: 64px !important;
  }
  .ms-md-auto {
    margin-inline-start: auto !important;
  }
  .me-md-0 {
    margin-inline-end: 0px !important;
  }
  .me-md-1 {
    margin-inline-end: 4px !important;
  }
  .me-md-2 {
    margin-inline-end: 8px !important;
  }
  .me-md-3 {
    margin-inline-end: 12px !important;
  }
  .me-md-4 {
    margin-inline-end: 16px !important;
  }
  .me-md-5 {
    margin-inline-end: 20px !important;
  }
  .me-md-6 {
    margin-inline-end: 24px !important;
  }
  .me-md-7 {
    margin-inline-end: 28px !important;
  }
  .me-md-8 {
    margin-inline-end: 32px !important;
  }
  .me-md-9 {
    margin-inline-end: 36px !important;
  }
  .me-md-10 {
    margin-inline-end: 40px !important;
  }
  .me-md-11 {
    margin-inline-end: 44px !important;
  }
  .me-md-12 {
    margin-inline-end: 48px !important;
  }
  .me-md-13 {
    margin-inline-end: 52px !important;
  }
  .me-md-14 {
    margin-inline-end: 56px !important;
  }
  .me-md-15 {
    margin-inline-end: 60px !important;
  }
  .me-md-16 {
    margin-inline-end: 64px !important;
  }
  .me-md-auto {
    margin-inline-end: auto !important;
  }
  .ma-md-n1 {
    margin: -4px !important;
  }
  .ma-md-n2 {
    margin: -8px !important;
  }
  .ma-md-n3 {
    margin: -12px !important;
  }
  .ma-md-n4 {
    margin: -16px !important;
  }
  .ma-md-n5 {
    margin: -20px !important;
  }
  .ma-md-n6 {
    margin: -24px !important;
  }
  .ma-md-n7 {
    margin: -28px !important;
  }
  .ma-md-n8 {
    margin: -32px !important;
  }
  .ma-md-n9 {
    margin: -36px !important;
  }
  .ma-md-n10 {
    margin: -40px !important;
  }
  .ma-md-n11 {
    margin: -44px !important;
  }
  .ma-md-n12 {
    margin: -48px !important;
  }
  .ma-md-n13 {
    margin: -52px !important;
  }
  .ma-md-n14 {
    margin: -56px !important;
  }
  .ma-md-n15 {
    margin: -60px !important;
  }
  .ma-md-n16 {
    margin: -64px !important;
  }
  .mx-md-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-md-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-md-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-md-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-md-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-md-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-md-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .mx-md-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-md-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }
  .mx-md-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-md-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }
  .mx-md-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }
  .mx-md-n13 {
    margin-right: -52px !important;
    margin-left: -52px !important;
  }
  .mx-md-n14 {
    margin-right: -56px !important;
    margin-left: -56px !important;
  }
  .mx-md-n15 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-md-n16 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }
  .my-md-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-md-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-md-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-md-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-md-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-md-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-md-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .my-md-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-md-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .my-md-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-md-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }
  .my-md-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .my-md-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }
  .my-md-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .my-md-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-md-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .mt-md-n1 {
    margin-top: -4px !important;
  }
  .mt-md-n2 {
    margin-top: -8px !important;
  }
  .mt-md-n3 {
    margin-top: -12px !important;
  }
  .mt-md-n4 {
    margin-top: -16px !important;
  }
  .mt-md-n5 {
    margin-top: -20px !important;
  }
  .mt-md-n6 {
    margin-top: -24px !important;
  }
  .mt-md-n7 {
    margin-top: -28px !important;
  }
  .mt-md-n8 {
    margin-top: -32px !important;
  }
  .mt-md-n9 {
    margin-top: -36px !important;
  }
  .mt-md-n10 {
    margin-top: -40px !important;
  }
  .mt-md-n11 {
    margin-top: -44px !important;
  }
  .mt-md-n12 {
    margin-top: -48px !important;
  }
  .mt-md-n13 {
    margin-top: -52px !important;
  }
  .mt-md-n14 {
    margin-top: -56px !important;
  }
  .mt-md-n15 {
    margin-top: -60px !important;
  }
  .mt-md-n16 {
    margin-top: -64px !important;
  }
  .mr-md-n1 {
    margin-right: -4px !important;
  }
  .mr-md-n2 {
    margin-right: -8px !important;
  }
  .mr-md-n3 {
    margin-right: -12px !important;
  }
  .mr-md-n4 {
    margin-right: -16px !important;
  }
  .mr-md-n5 {
    margin-right: -20px !important;
  }
  .mr-md-n6 {
    margin-right: -24px !important;
  }
  .mr-md-n7 {
    margin-right: -28px !important;
  }
  .mr-md-n8 {
    margin-right: -32px !important;
  }
  .mr-md-n9 {
    margin-right: -36px !important;
  }
  .mr-md-n10 {
    margin-right: -40px !important;
  }
  .mr-md-n11 {
    margin-right: -44px !important;
  }
  .mr-md-n12 {
    margin-right: -48px !important;
  }
  .mr-md-n13 {
    margin-right: -52px !important;
  }
  .mr-md-n14 {
    margin-right: -56px !important;
  }
  .mr-md-n15 {
    margin-right: -60px !important;
  }
  .mr-md-n16 {
    margin-right: -64px !important;
  }
  .mb-md-n1 {
    margin-bottom: -4px !important;
  }
  .mb-md-n2 {
    margin-bottom: -8px !important;
  }
  .mb-md-n3 {
    margin-bottom: -12px !important;
  }
  .mb-md-n4 {
    margin-bottom: -16px !important;
  }
  .mb-md-n5 {
    margin-bottom: -20px !important;
  }
  .mb-md-n6 {
    margin-bottom: -24px !important;
  }
  .mb-md-n7 {
    margin-bottom: -28px !important;
  }
  .mb-md-n8 {
    margin-bottom: -32px !important;
  }
  .mb-md-n9 {
    margin-bottom: -36px !important;
  }
  .mb-md-n10 {
    margin-bottom: -40px !important;
  }
  .mb-md-n11 {
    margin-bottom: -44px !important;
  }
  .mb-md-n12 {
    margin-bottom: -48px !important;
  }
  .mb-md-n13 {
    margin-bottom: -52px !important;
  }
  .mb-md-n14 {
    margin-bottom: -56px !important;
  }
  .mb-md-n15 {
    margin-bottom: -60px !important;
  }
  .mb-md-n16 {
    margin-bottom: -64px !important;
  }
  .ml-md-n1 {
    margin-left: -4px !important;
  }
  .ml-md-n2 {
    margin-left: -8px !important;
  }
  .ml-md-n3 {
    margin-left: -12px !important;
  }
  .ml-md-n4 {
    margin-left: -16px !important;
  }
  .ml-md-n5 {
    margin-left: -20px !important;
  }
  .ml-md-n6 {
    margin-left: -24px !important;
  }
  .ml-md-n7 {
    margin-left: -28px !important;
  }
  .ml-md-n8 {
    margin-left: -32px !important;
  }
  .ml-md-n9 {
    margin-left: -36px !important;
  }
  .ml-md-n10 {
    margin-left: -40px !important;
  }
  .ml-md-n11 {
    margin-left: -44px !important;
  }
  .ml-md-n12 {
    margin-left: -48px !important;
  }
  .ml-md-n13 {
    margin-left: -52px !important;
  }
  .ml-md-n14 {
    margin-left: -56px !important;
  }
  .ml-md-n15 {
    margin-left: -60px !important;
  }
  .ml-md-n16 {
    margin-left: -64px !important;
  }
  .ms-md-n1 {
    margin-inline-start: -4px !important;
  }
  .ms-md-n2 {
    margin-inline-start: -8px !important;
  }
  .ms-md-n3 {
    margin-inline-start: -12px !important;
  }
  .ms-md-n4 {
    margin-inline-start: -16px !important;
  }
  .ms-md-n5 {
    margin-inline-start: -20px !important;
  }
  .ms-md-n6 {
    margin-inline-start: -24px !important;
  }
  .ms-md-n7 {
    margin-inline-start: -28px !important;
  }
  .ms-md-n8 {
    margin-inline-start: -32px !important;
  }
  .ms-md-n9 {
    margin-inline-start: -36px !important;
  }
  .ms-md-n10 {
    margin-inline-start: -40px !important;
  }
  .ms-md-n11 {
    margin-inline-start: -44px !important;
  }
  .ms-md-n12 {
    margin-inline-start: -48px !important;
  }
  .ms-md-n13 {
    margin-inline-start: -52px !important;
  }
  .ms-md-n14 {
    margin-inline-start: -56px !important;
  }
  .ms-md-n15 {
    margin-inline-start: -60px !important;
  }
  .ms-md-n16 {
    margin-inline-start: -64px !important;
  }
  .me-md-n1 {
    margin-inline-end: -4px !important;
  }
  .me-md-n2 {
    margin-inline-end: -8px !important;
  }
  .me-md-n3 {
    margin-inline-end: -12px !important;
  }
  .me-md-n4 {
    margin-inline-end: -16px !important;
  }
  .me-md-n5 {
    margin-inline-end: -20px !important;
  }
  .me-md-n6 {
    margin-inline-end: -24px !important;
  }
  .me-md-n7 {
    margin-inline-end: -28px !important;
  }
  .me-md-n8 {
    margin-inline-end: -32px !important;
  }
  .me-md-n9 {
    margin-inline-end: -36px !important;
  }
  .me-md-n10 {
    margin-inline-end: -40px !important;
  }
  .me-md-n11 {
    margin-inline-end: -44px !important;
  }
  .me-md-n12 {
    margin-inline-end: -48px !important;
  }
  .me-md-n13 {
    margin-inline-end: -52px !important;
  }
  .me-md-n14 {
    margin-inline-end: -56px !important;
  }
  .me-md-n15 {
    margin-inline-end: -60px !important;
  }
  .me-md-n16 {
    margin-inline-end: -64px !important;
  }
  .pa-md-0 {
    padding: 0px !important;
  }
  .pa-md-1 {
    padding: 4px !important;
  }
  .pa-md-2 {
    padding: 8px !important;
  }
  .pa-md-3 {
    padding: 12px !important;
  }
  .pa-md-4 {
    padding: 16px !important;
  }
  .pa-md-5 {
    padding: 20px !important;
  }
  .pa-md-6 {
    padding: 24px !important;
  }
  .pa-md-7 {
    padding: 28px !important;
  }
  .pa-md-8 {
    padding: 32px !important;
  }
  .pa-md-9 {
    padding: 36px !important;
  }
  .pa-md-10 {
    padding: 40px !important;
  }
  .pa-md-11 {
    padding: 44px !important;
  }
  .pa-md-12 {
    padding: 48px !important;
  }
  .pa-md-13 {
    padding: 52px !important;
  }
  .pa-md-14 {
    padding: 56px !important;
  }
  .pa-md-15 {
    padding: 60px !important;
  }
  .pa-md-16 {
    padding: 64px !important;
  }
  .px-md-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .px-md-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-md-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-md-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-md-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-md-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-md-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-md-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .px-md-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-md-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
  .px-md-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-md-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }
  .px-md-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
  .px-md-13 {
    padding-right: 52px !important;
    padding-left: 52px !important;
  }
  .px-md-14 {
    padding-right: 56px !important;
    padding-left: 56px !important;
  }
  .px-md-15 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-md-16 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
  .py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .py-md-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-md-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-md-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-md-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-md-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-md-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-md-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .py-md-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-md-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .py-md-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-md-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .py-md-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .py-md-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }
  .py-md-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .py-md-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-md-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .pt-md-0 {
    padding-top: 0px !important;
  }
  .pt-md-1 {
    padding-top: 4px !important;
  }
  .pt-md-2 {
    padding-top: 8px !important;
  }
  .pt-md-3 {
    padding-top: 12px !important;
  }
  .pt-md-4 {
    padding-top: 16px !important;
  }
  .pt-md-5 {
    padding-top: 20px !important;
  }
  .pt-md-6 {
    padding-top: 24px !important;
  }
  .pt-md-7 {
    padding-top: 28px !important;
  }
  .pt-md-8 {
    padding-top: 32px !important;
  }
  .pt-md-9 {
    padding-top: 36px !important;
  }
  .pt-md-10 {
    padding-top: 40px !important;
  }
  .pt-md-11 {
    padding-top: 44px !important;
  }
  .pt-md-12 {
    padding-top: 48px !important;
  }
  .pt-md-13 {
    padding-top: 52px !important;
  }
  .pt-md-14 {
    padding-top: 56px !important;
  }
  .pt-md-15 {
    padding-top: 60px !important;
  }
  .pt-md-16 {
    padding-top: 64px !important;
  }
  .pr-md-0 {
    padding-right: 0px !important;
  }
  .pr-md-1 {
    padding-right: 4px !important;
  }
  .pr-md-2 {
    padding-right: 8px !important;
  }
  .pr-md-3 {
    padding-right: 12px !important;
  }
  .pr-md-4 {
    padding-right: 16px !important;
  }
  .pr-md-5 {
    padding-right: 20px !important;
  }
  .pr-md-6 {
    padding-right: 24px !important;
  }
  .pr-md-7 {
    padding-right: 28px !important;
  }
  .pr-md-8 {
    padding-right: 32px !important;
  }
  .pr-md-9 {
    padding-right: 36px !important;
  }
  .pr-md-10 {
    padding-right: 40px !important;
  }
  .pr-md-11 {
    padding-right: 44px !important;
  }
  .pr-md-12 {
    padding-right: 48px !important;
  }
  .pr-md-13 {
    padding-right: 52px !important;
  }
  .pr-md-14 {
    padding-right: 56px !important;
  }
  .pr-md-15 {
    padding-right: 60px !important;
  }
  .pr-md-16 {
    padding-right: 64px !important;
  }
  .pb-md-0 {
    padding-bottom: 0px !important;
  }
  .pb-md-1 {
    padding-bottom: 4px !important;
  }
  .pb-md-2 {
    padding-bottom: 8px !important;
  }
  .pb-md-3 {
    padding-bottom: 12px !important;
  }
  .pb-md-4 {
    padding-bottom: 16px !important;
  }
  .pb-md-5 {
    padding-bottom: 20px !important;
  }
  .pb-md-6 {
    padding-bottom: 24px !important;
  }
  .pb-md-7 {
    padding-bottom: 28px !important;
  }
  .pb-md-8 {
    padding-bottom: 32px !important;
  }
  .pb-md-9 {
    padding-bottom: 36px !important;
  }
  .pb-md-10 {
    padding-bottom: 40px !important;
  }
  .pb-md-11 {
    padding-bottom: 44px !important;
  }
  .pb-md-12 {
    padding-bottom: 48px !important;
  }
  .pb-md-13 {
    padding-bottom: 52px !important;
  }
  .pb-md-14 {
    padding-bottom: 56px !important;
  }
  .pb-md-15 {
    padding-bottom: 60px !important;
  }
  .pb-md-16 {
    padding-bottom: 64px !important;
  }
  .pl-md-0 {
    padding-left: 0px !important;
  }
  .pl-md-1 {
    padding-left: 4px !important;
  }
  .pl-md-2 {
    padding-left: 8px !important;
  }
  .pl-md-3 {
    padding-left: 12px !important;
  }
  .pl-md-4 {
    padding-left: 16px !important;
  }
  .pl-md-5 {
    padding-left: 20px !important;
  }
  .pl-md-6 {
    padding-left: 24px !important;
  }
  .pl-md-7 {
    padding-left: 28px !important;
  }
  .pl-md-8 {
    padding-left: 32px !important;
  }
  .pl-md-9 {
    padding-left: 36px !important;
  }
  .pl-md-10 {
    padding-left: 40px !important;
  }
  .pl-md-11 {
    padding-left: 44px !important;
  }
  .pl-md-12 {
    padding-left: 48px !important;
  }
  .pl-md-13 {
    padding-left: 52px !important;
  }
  .pl-md-14 {
    padding-left: 56px !important;
  }
  .pl-md-15 {
    padding-left: 60px !important;
  }
  .pl-md-16 {
    padding-left: 64px !important;
  }
  .ps-md-0 {
    padding-inline-start: 0px !important;
  }
  .ps-md-1 {
    padding-inline-start: 4px !important;
  }
  .ps-md-2 {
    padding-inline-start: 8px !important;
  }
  .ps-md-3 {
    padding-inline-start: 12px !important;
  }
  .ps-md-4 {
    padding-inline-start: 16px !important;
  }
  .ps-md-5 {
    padding-inline-start: 20px !important;
  }
  .ps-md-6 {
    padding-inline-start: 24px !important;
  }
  .ps-md-7 {
    padding-inline-start: 28px !important;
  }
  .ps-md-8 {
    padding-inline-start: 32px !important;
  }
  .ps-md-9 {
    padding-inline-start: 36px !important;
  }
  .ps-md-10 {
    padding-inline-start: 40px !important;
  }
  .ps-md-11 {
    padding-inline-start: 44px !important;
  }
  .ps-md-12 {
    padding-inline-start: 48px !important;
  }
  .ps-md-13 {
    padding-inline-start: 52px !important;
  }
  .ps-md-14 {
    padding-inline-start: 56px !important;
  }
  .ps-md-15 {
    padding-inline-start: 60px !important;
  }
  .ps-md-16 {
    padding-inline-start: 64px !important;
  }
  .pe-md-0 {
    padding-inline-end: 0px !important;
  }
  .pe-md-1 {
    padding-inline-end: 4px !important;
  }
  .pe-md-2 {
    padding-inline-end: 8px !important;
  }
  .pe-md-3 {
    padding-inline-end: 12px !important;
  }
  .pe-md-4 {
    padding-inline-end: 16px !important;
  }
  .pe-md-5 {
    padding-inline-end: 20px !important;
  }
  .pe-md-6 {
    padding-inline-end: 24px !important;
  }
  .pe-md-7 {
    padding-inline-end: 28px !important;
  }
  .pe-md-8 {
    padding-inline-end: 32px !important;
  }
  .pe-md-9 {
    padding-inline-end: 36px !important;
  }
  .pe-md-10 {
    padding-inline-end: 40px !important;
  }
  .pe-md-11 {
    padding-inline-end: 44px !important;
  }
  .pe-md-12 {
    padding-inline-end: 48px !important;
  }
  .pe-md-13 {
    padding-inline-end: 52px !important;
  }
  .pe-md-14 {
    padding-inline-end: 56px !important;
  }
  .pe-md-15 {
    padding-inline-end: 60px !important;
  }
  .pe-md-16 {
    padding-inline-end: 64px !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
  .text-md-start {
    text-align: start !important;
  }
  .text-md-end {
    text-align: end !important;
  }
  .text-md-h1 {
    font-size: 6rem !important;
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-h2 {
    font-size: 3.75rem !important;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-h3 {
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-h4 {
    font-size: 2.125rem !important;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-h6 {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-subtitle-1 {
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 1.75rem;
    letter-spacing: 0.009375em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-subtitle-2 {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-body-1 {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.03125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-body-2 {
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-button {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0.0892857143em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
  .text-md-caption {
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-md-overline {
    font-size: 0.75rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1666666667em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
    .col-md {
      flex-grow: 1;
      flex-basis: 0;
      padding: 0.5rem;
    }

    .col-md-fixed {
      flex: 0 0 auto;
      padding: 0.5rem;
    }

    .col-md-1 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 8.3333%;
    }

    .col-md-2 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 16.6667%;
    }

    .col-md-3 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 25%;
    }

    .col-md-4 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 33.3333%;
    }

    .col-md-5 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 41.6667%;
    }

    .col-md-6 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 50%;
    }

    .col-md-7 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 58.3333%;
    }

    .col-md-8 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 66.6667%;
    }

    .col-md-9 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 75%;
    }

    .col-md-10 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 83.3333%;
    }

    .col-md-11 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 91.6667%;
    }

    .col-md-12 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 100%;
    }
}
@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .v-locale--is-rtl .float-lg-end {
    float: left !important;
  }
  .v-locale--is-rtl .float-lg-start {
    float: right !important;
  }
  .v-locale--is-ltr .float-lg-end {
    float: right !important;
  }
  .v-locale--is-ltr .float-lg-start {
    float: left !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-1-1 {
    flex: 1 1 auto !important;
  }
  .flex-lg-1-0 {
    flex: 1 0 auto !important;
  }
  .flex-lg-0-1 {
    flex: 0 1 auto !important;
  }
  .flex-lg-0-0 {
    flex: 0 0 auto !important;
  }
  .flex-lg-1-1-100 {
    flex: 1 1 100% !important;
  }
  .flex-lg-1-0-100 {
    flex: 1 0 100% !important;
  }
  .flex-lg-0-1-100 {
    flex: 0 1 100% !important;
  }
  .flex-lg-0-0-100 {
    flex: 0 0 100% !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-lg-start {
    justify-content: flex-start !important;
  }
  .justify-lg-end {
    justify-content: flex-end !important;
  }
  .justify-lg-center {
    justify-content: center !important;
  }
  .justify-lg-space-between {
    justify-content: space-between !important;
  }
  .justify-lg-space-around {
    justify-content: space-around !important;
  }
  .justify-lg-space-evenly {
    justify-content: space-evenly !important;
  }
  .align-lg-start {
    align-items: flex-start !important;
  }
  .align-lg-end {
    align-items: flex-end !important;
  }
  .align-lg-center {
    align-items: center !important;
  }
  .align-lg-baseline {
    align-items: baseline !important;
  }
  .align-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-space-between {
    align-content: space-between !important;
  }
  .align-content-lg-space-around {
    align-content: space-around !important;
  }
  .align-content-lg-space-evenly {
    align-content: space-evenly !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-6 {
    order: 6 !important;
  }
  .order-lg-7 {
    order: 7 !important;
  }
  .order-lg-8 {
    order: 8 !important;
  }
  .order-lg-9 {
    order: 9 !important;
  }
  .order-lg-10 {
    order: 10 !important;
  }
  .order-lg-11 {
    order: 11 !important;
  }
  .order-lg-12 {
    order: 12 !important;
  }
  .order-lg-last {
    order: 13 !important;
  }
  .ga-lg-0 {
    gap: 0px !important;
  }
  .ga-lg-1 {
    gap: 4px !important;
  }
  .ga-lg-2 {
    gap: 8px !important;
  }
  .ga-lg-3 {
    gap: 12px !important;
  }
  .ga-lg-4 {
    gap: 16px !important;
  }
  .ga-lg-5 {
    gap: 20px !important;
  }
  .ga-lg-6 {
    gap: 24px !important;
  }
  .ga-lg-7 {
    gap: 28px !important;
  }
  .ga-lg-8 {
    gap: 32px !important;
  }
  .ga-lg-9 {
    gap: 36px !important;
  }
  .ga-lg-10 {
    gap: 40px !important;
  }
  .ga-lg-11 {
    gap: 44px !important;
  }
  .ga-lg-12 {
    gap: 48px !important;
  }
  .ga-lg-13 {
    gap: 52px !important;
  }
  .ga-lg-14 {
    gap: 56px !important;
  }
  .ga-lg-15 {
    gap: 60px !important;
  }
  .ga-lg-16 {
    gap: 64px !important;
  }
  .ga-lg-auto {
    gap: auto !important;
  }
  .gr-lg-0 {
    row-gap: 0px !important;
  }
  .gr-lg-1 {
    row-gap: 4px !important;
  }
  .gr-lg-2 {
    row-gap: 8px !important;
  }
  .gr-lg-3 {
    row-gap: 12px !important;
  }
  .gr-lg-4 {
    row-gap: 16px !important;
  }
  .gr-lg-5 {
    row-gap: 20px !important;
  }
  .gr-lg-6 {
    row-gap: 24px !important;
  }
  .gr-lg-7 {
    row-gap: 28px !important;
  }
  .gr-lg-8 {
    row-gap: 32px !important;
  }
  .gr-lg-9 {
    row-gap: 36px !important;
  }
  .gr-lg-10 {
    row-gap: 40px !important;
  }
  .gr-lg-11 {
    row-gap: 44px !important;
  }
  .gr-lg-12 {
    row-gap: 48px !important;
  }
  .gr-lg-13 {
    row-gap: 52px !important;
  }
  .gr-lg-14 {
    row-gap: 56px !important;
  }
  .gr-lg-15 {
    row-gap: 60px !important;
  }
  .gr-lg-16 {
    row-gap: 64px !important;
  }
  .gr-lg-auto {
    row-gap: auto !important;
  }
  .gc-lg-0 {
    column-gap: 0px !important;
  }
  .gc-lg-1 {
    column-gap: 4px !important;
  }
  .gc-lg-2 {
    column-gap: 8px !important;
  }
  .gc-lg-3 {
    column-gap: 12px !important;
  }
  .gc-lg-4 {
    column-gap: 16px !important;
  }
  .gc-lg-5 {
    column-gap: 20px !important;
  }
  .gc-lg-6 {
    column-gap: 24px !important;
  }
  .gc-lg-7 {
    column-gap: 28px !important;
  }
  .gc-lg-8 {
    column-gap: 32px !important;
  }
  .gc-lg-9 {
    column-gap: 36px !important;
  }
  .gc-lg-10 {
    column-gap: 40px !important;
  }
  .gc-lg-11 {
    column-gap: 44px !important;
  }
  .gc-lg-12 {
    column-gap: 48px !important;
  }
  .gc-lg-13 {
    column-gap: 52px !important;
  }
  .gc-lg-14 {
    column-gap: 56px !important;
  }
  .gc-lg-15 {
    column-gap: 60px !important;
  }
  .gc-lg-16 {
    column-gap: 64px !important;
  }
  .gc-lg-auto {
    column-gap: auto !important;
  }
  .ma-lg-0 {
    margin: 0px !important;
  }
  .ma-lg-1 {
    margin: 4px !important;
  }
  .ma-lg-2 {
    margin: 8px !important;
  }
  .ma-lg-3 {
    margin: 12px !important;
  }
  .ma-lg-4 {
    margin: 16px !important;
  }
  .ma-lg-5 {
    margin: 20px !important;
  }
  .ma-lg-6 {
    margin: 24px !important;
  }
  .ma-lg-7 {
    margin: 28px !important;
  }
  .ma-lg-8 {
    margin: 32px !important;
  }
  .ma-lg-9 {
    margin: 36px !important;
  }
  .ma-lg-10 {
    margin: 40px !important;
  }
  .ma-lg-11 {
    margin: 44px !important;
  }
  .ma-lg-12 {
    margin: 48px !important;
  }
  .ma-lg-13 {
    margin: 52px !important;
  }
  .ma-lg-14 {
    margin: 56px !important;
  }
  .ma-lg-15 {
    margin: 60px !important;
  }
  .ma-lg-16 {
    margin: 64px !important;
  }
  .ma-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .mx-lg-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-lg-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-lg-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-lg-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-lg-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-lg-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-lg-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-lg-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-lg-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
  .mx-lg-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-lg-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }
  .mx-lg-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
  .mx-lg-13 {
    margin-right: 52px !important;
    margin-left: 52px !important;
  }
  .mx-lg-14 {
    margin-right: 56px !important;
    margin-left: 56px !important;
  }
  .mx-lg-15 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-lg-16 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .my-lg-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-lg-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-lg-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-lg-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-lg-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-lg-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-lg-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-lg-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-lg-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .my-lg-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-lg-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .my-lg-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .my-lg-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }
  .my-lg-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .my-lg-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-lg-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0px !important;
  }
  .mt-lg-1 {
    margin-top: 4px !important;
  }
  .mt-lg-2 {
    margin-top: 8px !important;
  }
  .mt-lg-3 {
    margin-top: 12px !important;
  }
  .mt-lg-4 {
    margin-top: 16px !important;
  }
  .mt-lg-5 {
    margin-top: 20px !important;
  }
  .mt-lg-6 {
    margin-top: 24px !important;
  }
  .mt-lg-7 {
    margin-top: 28px !important;
  }
  .mt-lg-8 {
    margin-top: 32px !important;
  }
  .mt-lg-9 {
    margin-top: 36px !important;
  }
  .mt-lg-10 {
    margin-top: 40px !important;
  }
  .mt-lg-11 {
    margin-top: 44px !important;
  }
  .mt-lg-12 {
    margin-top: 48px !important;
  }
  .mt-lg-13 {
    margin-top: 52px !important;
  }
  .mt-lg-14 {
    margin-top: 56px !important;
  }
  .mt-lg-15 {
    margin-top: 60px !important;
  }
  .mt-lg-16 {
    margin-top: 64px !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-0 {
    margin-right: 0px !important;
  }
  .mr-lg-1 {
    margin-right: 4px !important;
  }
  .mr-lg-2 {
    margin-right: 8px !important;
  }
  .mr-lg-3 {
    margin-right: 12px !important;
  }
  .mr-lg-4 {
    margin-right: 16px !important;
  }
  .mr-lg-5 {
    margin-right: 20px !important;
  }
  .mr-lg-6 {
    margin-right: 24px !important;
  }
  .mr-lg-7 {
    margin-right: 28px !important;
  }
  .mr-lg-8 {
    margin-right: 32px !important;
  }
  .mr-lg-9 {
    margin-right: 36px !important;
  }
  .mr-lg-10 {
    margin-right: 40px !important;
  }
  .mr-lg-11 {
    margin-right: 44px !important;
  }
  .mr-lg-12 {
    margin-right: 48px !important;
  }
  .mr-lg-13 {
    margin-right: 52px !important;
  }
  .mr-lg-14 {
    margin-right: 56px !important;
  }
  .mr-lg-15 {
    margin-right: 60px !important;
  }
  .mr-lg-16 {
    margin-right: 64px !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0px !important;
  }
  .mb-lg-1 {
    margin-bottom: 4px !important;
  }
  .mb-lg-2 {
    margin-bottom: 8px !important;
  }
  .mb-lg-3 {
    margin-bottom: 12px !important;
  }
  .mb-lg-4 {
    margin-bottom: 16px !important;
  }
  .mb-lg-5 {
    margin-bottom: 20px !important;
  }
  .mb-lg-6 {
    margin-bottom: 24px !important;
  }
  .mb-lg-7 {
    margin-bottom: 28px !important;
  }
  .mb-lg-8 {
    margin-bottom: 32px !important;
  }
  .mb-lg-9 {
    margin-bottom: 36px !important;
  }
  .mb-lg-10 {
    margin-bottom: 40px !important;
  }
  .mb-lg-11 {
    margin-bottom: 44px !important;
  }
  .mb-lg-12 {
    margin-bottom: 48px !important;
  }
  .mb-lg-13 {
    margin-bottom: 52px !important;
  }
  .mb-lg-14 {
    margin-bottom: 56px !important;
  }
  .mb-lg-15 {
    margin-bottom: 60px !important;
  }
  .mb-lg-16 {
    margin-bottom: 64px !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-0 {
    margin-left: 0px !important;
  }
  .ml-lg-1 {
    margin-left: 4px !important;
  }
  .ml-lg-2 {
    margin-left: 8px !important;
  }
  .ml-lg-3 {
    margin-left: 12px !important;
  }
  .ml-lg-4 {
    margin-left: 16px !important;
  }
  .ml-lg-5 {
    margin-left: 20px !important;
  }
  .ml-lg-6 {
    margin-left: 24px !important;
  }
  .ml-lg-7 {
    margin-left: 28px !important;
  }
  .ml-lg-8 {
    margin-left: 32px !important;
  }
  .ml-lg-9 {
    margin-left: 36px !important;
  }
  .ml-lg-10 {
    margin-left: 40px !important;
  }
  .ml-lg-11 {
    margin-left: 44px !important;
  }
  .ml-lg-12 {
    margin-left: 48px !important;
  }
  .ml-lg-13 {
    margin-left: 52px !important;
  }
  .ml-lg-14 {
    margin-left: 56px !important;
  }
  .ml-lg-15 {
    margin-left: 60px !important;
  }
  .ml-lg-16 {
    margin-left: 64px !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .ms-lg-0 {
    margin-inline-start: 0px !important;
  }
  .ms-lg-1 {
    margin-inline-start: 4px !important;
  }
  .ms-lg-2 {
    margin-inline-start: 8px !important;
  }
  .ms-lg-3 {
    margin-inline-start: 12px !important;
  }
  .ms-lg-4 {
    margin-inline-start: 16px !important;
  }
  .ms-lg-5 {
    margin-inline-start: 20px !important;
  }
  .ms-lg-6 {
    margin-inline-start: 24px !important;
  }
  .ms-lg-7 {
    margin-inline-start: 28px !important;
  }
  .ms-lg-8 {
    margin-inline-start: 32px !important;
  }
  .ms-lg-9 {
    margin-inline-start: 36px !important;
  }
  .ms-lg-10 {
    margin-inline-start: 40px !important;
  }
  .ms-lg-11 {
    margin-inline-start: 44px !important;
  }
  .ms-lg-12 {
    margin-inline-start: 48px !important;
  }
  .ms-lg-13 {
    margin-inline-start: 52px !important;
  }
  .ms-lg-14 {
    margin-inline-start: 56px !important;
  }
  .ms-lg-15 {
    margin-inline-start: 60px !important;
  }
  .ms-lg-16 {
    margin-inline-start: 64px !important;
  }
  .ms-lg-auto {
    margin-inline-start: auto !important;
  }
  .me-lg-0 {
    margin-inline-end: 0px !important;
  }
  .me-lg-1 {
    margin-inline-end: 4px !important;
  }
  .me-lg-2 {
    margin-inline-end: 8px !important;
  }
  .me-lg-3 {
    margin-inline-end: 12px !important;
  }
  .me-lg-4 {
    margin-inline-end: 16px !important;
  }
  .me-lg-5 {
    margin-inline-end: 20px !important;
  }
  .me-lg-6 {
    margin-inline-end: 24px !important;
  }
  .me-lg-7 {
    margin-inline-end: 28px !important;
  }
  .me-lg-8 {
    margin-inline-end: 32px !important;
  }
  .me-lg-9 {
    margin-inline-end: 36px !important;
  }
  .me-lg-10 {
    margin-inline-end: 40px !important;
  }
  .me-lg-11 {
    margin-inline-end: 44px !important;
  }
  .me-lg-12 {
    margin-inline-end: 48px !important;
  }
  .me-lg-13 {
    margin-inline-end: 52px !important;
  }
  .me-lg-14 {
    margin-inline-end: 56px !important;
  }
  .me-lg-15 {
    margin-inline-end: 60px !important;
  }
  .me-lg-16 {
    margin-inline-end: 64px !important;
  }
  .me-lg-auto {
    margin-inline-end: auto !important;
  }
  .ma-lg-n1 {
    margin: -4px !important;
  }
  .ma-lg-n2 {
    margin: -8px !important;
  }
  .ma-lg-n3 {
    margin: -12px !important;
  }
  .ma-lg-n4 {
    margin: -16px !important;
  }
  .ma-lg-n5 {
    margin: -20px !important;
  }
  .ma-lg-n6 {
    margin: -24px !important;
  }
  .ma-lg-n7 {
    margin: -28px !important;
  }
  .ma-lg-n8 {
    margin: -32px !important;
  }
  .ma-lg-n9 {
    margin: -36px !important;
  }
  .ma-lg-n10 {
    margin: -40px !important;
  }
  .ma-lg-n11 {
    margin: -44px !important;
  }
  .ma-lg-n12 {
    margin: -48px !important;
  }
  .ma-lg-n13 {
    margin: -52px !important;
  }
  .ma-lg-n14 {
    margin: -56px !important;
  }
  .ma-lg-n15 {
    margin: -60px !important;
  }
  .ma-lg-n16 {
    margin: -64px !important;
  }
  .mx-lg-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-lg-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-lg-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-lg-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-lg-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-lg-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-lg-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .mx-lg-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-lg-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }
  .mx-lg-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-lg-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }
  .mx-lg-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }
  .mx-lg-n13 {
    margin-right: -52px !important;
    margin-left: -52px !important;
  }
  .mx-lg-n14 {
    margin-right: -56px !important;
    margin-left: -56px !important;
  }
  .mx-lg-n15 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-lg-n16 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }
  .my-lg-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-lg-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-lg-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-lg-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-lg-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-lg-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-lg-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .my-lg-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-lg-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .my-lg-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-lg-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }
  .my-lg-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .my-lg-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }
  .my-lg-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .my-lg-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-lg-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .mt-lg-n1 {
    margin-top: -4px !important;
  }
  .mt-lg-n2 {
    margin-top: -8px !important;
  }
  .mt-lg-n3 {
    margin-top: -12px !important;
  }
  .mt-lg-n4 {
    margin-top: -16px !important;
  }
  .mt-lg-n5 {
    margin-top: -20px !important;
  }
  .mt-lg-n6 {
    margin-top: -24px !important;
  }
  .mt-lg-n7 {
    margin-top: -28px !important;
  }
  .mt-lg-n8 {
    margin-top: -32px !important;
  }
  .mt-lg-n9 {
    margin-top: -36px !important;
  }
  .mt-lg-n10 {
    margin-top: -40px !important;
  }
  .mt-lg-n11 {
    margin-top: -44px !important;
  }
  .mt-lg-n12 {
    margin-top: -48px !important;
  }
  .mt-lg-n13 {
    margin-top: -52px !important;
  }
  .mt-lg-n14 {
    margin-top: -56px !important;
  }
  .mt-lg-n15 {
    margin-top: -60px !important;
  }
  .mt-lg-n16 {
    margin-top: -64px !important;
  }
  .mr-lg-n1 {
    margin-right: -4px !important;
  }
  .mr-lg-n2 {
    margin-right: -8px !important;
  }
  .mr-lg-n3 {
    margin-right: -12px !important;
  }
  .mr-lg-n4 {
    margin-right: -16px !important;
  }
  .mr-lg-n5 {
    margin-right: -20px !important;
  }
  .mr-lg-n6 {
    margin-right: -24px !important;
  }
  .mr-lg-n7 {
    margin-right: -28px !important;
  }
  .mr-lg-n8 {
    margin-right: -32px !important;
  }
  .mr-lg-n9 {
    margin-right: -36px !important;
  }
  .mr-lg-n10 {
    margin-right: -40px !important;
  }
  .mr-lg-n11 {
    margin-right: -44px !important;
  }
  .mr-lg-n12 {
    margin-right: -48px !important;
  }
  .mr-lg-n13 {
    margin-right: -52px !important;
  }
  .mr-lg-n14 {
    margin-right: -56px !important;
  }
  .mr-lg-n15 {
    margin-right: -60px !important;
  }
  .mr-lg-n16 {
    margin-right: -64px !important;
  }
  .mb-lg-n1 {
    margin-bottom: -4px !important;
  }
  .mb-lg-n2 {
    margin-bottom: -8px !important;
  }
  .mb-lg-n3 {
    margin-bottom: -12px !important;
  }
  .mb-lg-n4 {
    margin-bottom: -16px !important;
  }
  .mb-lg-n5 {
    margin-bottom: -20px !important;
  }
  .mb-lg-n6 {
    margin-bottom: -24px !important;
  }
  .mb-lg-n7 {
    margin-bottom: -28px !important;
  }
  .mb-lg-n8 {
    margin-bottom: -32px !important;
  }
  .mb-lg-n9 {
    margin-bottom: -36px !important;
  }
  .mb-lg-n10 {
    margin-bottom: -40px !important;
  }
  .mb-lg-n11 {
    margin-bottom: -44px !important;
  }
  .mb-lg-n12 {
    margin-bottom: -48px !important;
  }
  .mb-lg-n13 {
    margin-bottom: -52px !important;
  }
  .mb-lg-n14 {
    margin-bottom: -56px !important;
  }
  .mb-lg-n15 {
    margin-bottom: -60px !important;
  }
  .mb-lg-n16 {
    margin-bottom: -64px !important;
  }
  .ml-lg-n1 {
    margin-left: -4px !important;
  }
  .ml-lg-n2 {
    margin-left: -8px !important;
  }
  .ml-lg-n3 {
    margin-left: -12px !important;
  }
  .ml-lg-n4 {
    margin-left: -16px !important;
  }
  .ml-lg-n5 {
    margin-left: -20px !important;
  }
  .ml-lg-n6 {
    margin-left: -24px !important;
  }
  .ml-lg-n7 {
    margin-left: -28px !important;
  }
  .ml-lg-n8 {
    margin-left: -32px !important;
  }
  .ml-lg-n9 {
    margin-left: -36px !important;
  }
  .ml-lg-n10 {
    margin-left: -40px !important;
  }
  .ml-lg-n11 {
    margin-left: -44px !important;
  }
  .ml-lg-n12 {
    margin-left: -48px !important;
  }
  .ml-lg-n13 {
    margin-left: -52px !important;
  }
  .ml-lg-n14 {
    margin-left: -56px !important;
  }
  .ml-lg-n15 {
    margin-left: -60px !important;
  }
  .ml-lg-n16 {
    margin-left: -64px !important;
  }
  .ms-lg-n1 {
    margin-inline-start: -4px !important;
  }
  .ms-lg-n2 {
    margin-inline-start: -8px !important;
  }
  .ms-lg-n3 {
    margin-inline-start: -12px !important;
  }
  .ms-lg-n4 {
    margin-inline-start: -16px !important;
  }
  .ms-lg-n5 {
    margin-inline-start: -20px !important;
  }
  .ms-lg-n6 {
    margin-inline-start: -24px !important;
  }
  .ms-lg-n7 {
    margin-inline-start: -28px !important;
  }
  .ms-lg-n8 {
    margin-inline-start: -32px !important;
  }
  .ms-lg-n9 {
    margin-inline-start: -36px !important;
  }
  .ms-lg-n10 {
    margin-inline-start: -40px !important;
  }
  .ms-lg-n11 {
    margin-inline-start: -44px !important;
  }
  .ms-lg-n12 {
    margin-inline-start: -48px !important;
  }
  .ms-lg-n13 {
    margin-inline-start: -52px !important;
  }
  .ms-lg-n14 {
    margin-inline-start: -56px !important;
  }
  .ms-lg-n15 {
    margin-inline-start: -60px !important;
  }
  .ms-lg-n16 {
    margin-inline-start: -64px !important;
  }
  .me-lg-n1 {
    margin-inline-end: -4px !important;
  }
  .me-lg-n2 {
    margin-inline-end: -8px !important;
  }
  .me-lg-n3 {
    margin-inline-end: -12px !important;
  }
  .me-lg-n4 {
    margin-inline-end: -16px !important;
  }
  .me-lg-n5 {
    margin-inline-end: -20px !important;
  }
  .me-lg-n6 {
    margin-inline-end: -24px !important;
  }
  .me-lg-n7 {
    margin-inline-end: -28px !important;
  }
  .me-lg-n8 {
    margin-inline-end: -32px !important;
  }
  .me-lg-n9 {
    margin-inline-end: -36px !important;
  }
  .me-lg-n10 {
    margin-inline-end: -40px !important;
  }
  .me-lg-n11 {
    margin-inline-end: -44px !important;
  }
  .me-lg-n12 {
    margin-inline-end: -48px !important;
  }
  .me-lg-n13 {
    margin-inline-end: -52px !important;
  }
  .me-lg-n14 {
    margin-inline-end: -56px !important;
  }
  .me-lg-n15 {
    margin-inline-end: -60px !important;
  }
  .me-lg-n16 {
    margin-inline-end: -64px !important;
  }
  .pa-lg-0 {
    padding: 0px !important;
  }
  .pa-lg-1 {
    padding: 4px !important;
  }
  .pa-lg-2 {
    padding: 8px !important;
  }
  .pa-lg-3 {
    padding: 12px !important;
  }
  .pa-lg-4 {
    padding: 16px !important;
  }
  .pa-lg-5 {
    padding: 20px !important;
  }
  .pa-lg-6 {
    padding: 24px !important;
  }
  .pa-lg-7 {
    padding: 28px !important;
  }
  .pa-lg-8 {
    padding: 32px !important;
  }
  .pa-lg-9 {
    padding: 36px !important;
  }
  .pa-lg-10 {
    padding: 40px !important;
  }
  .pa-lg-11 {
    padding: 44px !important;
  }
  .pa-lg-12 {
    padding: 48px !important;
  }
  .pa-lg-13 {
    padding: 52px !important;
  }
  .pa-lg-14 {
    padding: 56px !important;
  }
  .pa-lg-15 {
    padding: 60px !important;
  }
  .pa-lg-16 {
    padding: 64px !important;
  }
  .px-lg-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .px-lg-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-lg-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-lg-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-lg-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-lg-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-lg-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-lg-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .px-lg-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-lg-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
  .px-lg-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-lg-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }
  .px-lg-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
  .px-lg-13 {
    padding-right: 52px !important;
    padding-left: 52px !important;
  }
  .px-lg-14 {
    padding-right: 56px !important;
    padding-left: 56px !important;
  }
  .px-lg-15 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-lg-16 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
  .py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .py-lg-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-lg-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-lg-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-lg-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-lg-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-lg-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-lg-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .py-lg-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-lg-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .py-lg-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-lg-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .py-lg-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .py-lg-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }
  .py-lg-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .py-lg-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-lg-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .pt-lg-0 {
    padding-top: 0px !important;
  }
  .pt-lg-1 {
    padding-top: 4px !important;
  }
  .pt-lg-2 {
    padding-top: 8px !important;
  }
  .pt-lg-3 {
    padding-top: 12px !important;
  }
  .pt-lg-4 {
    padding-top: 16px !important;
  }
  .pt-lg-5 {
    padding-top: 20px !important;
  }
  .pt-lg-6 {
    padding-top: 24px !important;
  }
  .pt-lg-7 {
    padding-top: 28px !important;
  }
  .pt-lg-8 {
    padding-top: 32px !important;
  }
  .pt-lg-9 {
    padding-top: 36px !important;
  }
  .pt-lg-10 {
    padding-top: 40px !important;
  }
  .pt-lg-11 {
    padding-top: 44px !important;
  }
  .pt-lg-12 {
    padding-top: 48px !important;
  }
  .pt-lg-13 {
    padding-top: 52px !important;
  }
  .pt-lg-14 {
    padding-top: 56px !important;
  }
  .pt-lg-15 {
    padding-top: 60px !important;
  }
  .pt-lg-16 {
    padding-top: 64px !important;
  }
  .pr-lg-0 {
    padding-right: 0px !important;
  }
  .pr-lg-1 {
    padding-right: 4px !important;
  }
  .pr-lg-2 {
    padding-right: 8px !important;
  }
  .pr-lg-3 {
    padding-right: 12px !important;
  }
  .pr-lg-4 {
    padding-right: 16px !important;
  }
  .pr-lg-5 {
    padding-right: 20px !important;
  }
  .pr-lg-6 {
    padding-right: 24px !important;
  }
  .pr-lg-7 {
    padding-right: 28px !important;
  }
  .pr-lg-8 {
    padding-right: 32px !important;
  }
  .pr-lg-9 {
    padding-right: 36px !important;
  }
  .pr-lg-10 {
    padding-right: 40px !important;
  }
  .pr-lg-11 {
    padding-right: 44px !important;
  }
  .pr-lg-12 {
    padding-right: 48px !important;
  }
  .pr-lg-13 {
    padding-right: 52px !important;
  }
  .pr-lg-14 {
    padding-right: 56px !important;
  }
  .pr-lg-15 {
    padding-right: 60px !important;
  }
  .pr-lg-16 {
    padding-right: 64px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0px !important;
  }
  .pb-lg-1 {
    padding-bottom: 4px !important;
  }
  .pb-lg-2 {
    padding-bottom: 8px !important;
  }
  .pb-lg-3 {
    padding-bottom: 12px !important;
  }
  .pb-lg-4 {
    padding-bottom: 16px !important;
  }
  .pb-lg-5 {
    padding-bottom: 20px !important;
  }
  .pb-lg-6 {
    padding-bottom: 24px !important;
  }
  .pb-lg-7 {
    padding-bottom: 28px !important;
  }
  .pb-lg-8 {
    padding-bottom: 32px !important;
  }
  .pb-lg-9 {
    padding-bottom: 36px !important;
  }
  .pb-lg-10 {
    padding-bottom: 40px !important;
  }
  .pb-lg-11 {
    padding-bottom: 44px !important;
  }
  .pb-lg-12 {
    padding-bottom: 48px !important;
  }
  .pb-lg-13 {
    padding-bottom: 52px !important;
  }
  .pb-lg-14 {
    padding-bottom: 56px !important;
  }
  .pb-lg-15 {
    padding-bottom: 60px !important;
  }
  .pb-lg-16 {
    padding-bottom: 64px !important;
  }
  .pl-lg-0 {
    padding-left: 0px !important;
  }
  .pl-lg-1 {
    padding-left: 4px !important;
  }
  .pl-lg-2 {
    padding-left: 8px !important;
  }
  .pl-lg-3 {
    padding-left: 12px !important;
  }
  .pl-lg-4 {
    padding-left: 16px !important;
  }
  .pl-lg-5 {
    padding-left: 20px !important;
  }
  .pl-lg-6 {
    padding-left: 24px !important;
  }
  .pl-lg-7 {
    padding-left: 28px !important;
  }
  .pl-lg-8 {
    padding-left: 32px !important;
  }
  .pl-lg-9 {
    padding-left: 36px !important;
  }
  .pl-lg-10 {
    padding-left: 40px !important;
  }
  .pl-lg-11 {
    padding-left: 44px !important;
  }
  .pl-lg-12 {
    padding-left: 48px !important;
  }
  .pl-lg-13 {
    padding-left: 52px !important;
  }
  .pl-lg-14 {
    padding-left: 56px !important;
  }
  .pl-lg-15 {
    padding-left: 60px !important;
  }
  .pl-lg-16 {
    padding-left: 64px !important;
  }
  .ps-lg-0 {
    padding-inline-start: 0px !important;
  }
  .ps-lg-1 {
    padding-inline-start: 4px !important;
  }
  .ps-lg-2 {
    padding-inline-start: 8px !important;
  }
  .ps-lg-3 {
    padding-inline-start: 12px !important;
  }
  .ps-lg-4 {
    padding-inline-start: 16px !important;
  }
  .ps-lg-5 {
    padding-inline-start: 20px !important;
  }
  .ps-lg-6 {
    padding-inline-start: 24px !important;
  }
  .ps-lg-7 {
    padding-inline-start: 28px !important;
  }
  .ps-lg-8 {
    padding-inline-start: 32px !important;
  }
  .ps-lg-9 {
    padding-inline-start: 36px !important;
  }
  .ps-lg-10 {
    padding-inline-start: 40px !important;
  }
  .ps-lg-11 {
    padding-inline-start: 44px !important;
  }
  .ps-lg-12 {
    padding-inline-start: 48px !important;
  }
  .ps-lg-13 {
    padding-inline-start: 52px !important;
  }
  .ps-lg-14 {
    padding-inline-start: 56px !important;
  }
  .ps-lg-15 {
    padding-inline-start: 60px !important;
  }
  .ps-lg-16 {
    padding-inline-start: 64px !important;
  }
  .pe-lg-0 {
    padding-inline-end: 0px !important;
  }
  .pe-lg-1 {
    padding-inline-end: 4px !important;
  }
  .pe-lg-2 {
    padding-inline-end: 8px !important;
  }
  .pe-lg-3 {
    padding-inline-end: 12px !important;
  }
  .pe-lg-4 {
    padding-inline-end: 16px !important;
  }
  .pe-lg-5 {
    padding-inline-end: 20px !important;
  }
  .pe-lg-6 {
    padding-inline-end: 24px !important;
  }
  .pe-lg-7 {
    padding-inline-end: 28px !important;
  }
  .pe-lg-8 {
    padding-inline-end: 32px !important;
  }
  .pe-lg-9 {
    padding-inline-end: 36px !important;
  }
  .pe-lg-10 {
    padding-inline-end: 40px !important;
  }
  .pe-lg-11 {
    padding-inline-end: 44px !important;
  }
  .pe-lg-12 {
    padding-inline-end: 48px !important;
  }
  .pe-lg-13 {
    padding-inline-end: 52px !important;
  }
  .pe-lg-14 {
    padding-inline-end: 56px !important;
  }
  .pe-lg-15 {
    padding-inline-end: 60px !important;
  }
  .pe-lg-16 {
    padding-inline-end: 64px !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-justify {
    text-align: justify !important;
  }
  .text-lg-start {
    text-align: start !important;
  }
  .text-lg-end {
    text-align: end !important;
  }
  .text-lg-h1 {
    font-size: 6rem !important;
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-h2 {
    font-size: 3.75rem !important;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-h3 {
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-h4 {
    font-size: 2.125rem !important;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-h6 {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-subtitle-1 {
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 1.75rem;
    letter-spacing: 0.009375em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-subtitle-2 {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-body-1 {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.03125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-body-2 {
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-button {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0.0892857143em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
  .text-lg-caption {
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-lg-overline {
    font-size: 0.75rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1666666667em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }

  .col-lg-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media (min-width: 1920px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .v-locale--is-rtl .float-xl-end {
    float: left !important;
  }
  .v-locale--is-rtl .float-xl-start {
    float: right !important;
  }
  .v-locale--is-ltr .float-xl-end {
    float: right !important;
  }
  .v-locale--is-ltr .float-xl-start {
    float: left !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-1-1 {
    flex: 1 1 auto !important;
  }
  .flex-xl-1-0 {
    flex: 1 0 auto !important;
  }
  .flex-xl-0-1 {
    flex: 0 1 auto !important;
  }
  .flex-xl-0-0 {
    flex: 0 0 auto !important;
  }
  .flex-xl-1-1-100 {
    flex: 1 1 100% !important;
  }
  .flex-xl-1-0-100 {
    flex: 1 0 100% !important;
  }
  .flex-xl-0-1-100 {
    flex: 0 1 100% !important;
  }
  .flex-xl-0-0-100 {
    flex: 0 0 100% !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-xl-start {
    justify-content: flex-start !important;
  }
  .justify-xl-end {
    justify-content: flex-end !important;
  }
  .justify-xl-center {
    justify-content: center !important;
  }
  .justify-xl-space-between {
    justify-content: space-between !important;
  }
  .justify-xl-space-around {
    justify-content: space-around !important;
  }
  .justify-xl-space-evenly {
    justify-content: space-evenly !important;
  }
  .align-xl-start {
    align-items: flex-start !important;
  }
  .align-xl-end {
    align-items: flex-end !important;
  }
  .align-xl-center {
    align-items: center !important;
  }
  .align-xl-baseline {
    align-items: baseline !important;
  }
  .align-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-space-between {
    align-content: space-between !important;
  }
  .align-content-xl-space-around {
    align-content: space-around !important;
  }
  .align-content-xl-space-evenly {
    align-content: space-evenly !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-6 {
    order: 6 !important;
  }
  .order-xl-7 {
    order: 7 !important;
  }
  .order-xl-8 {
    order: 8 !important;
  }
  .order-xl-9 {
    order: 9 !important;
  }
  .order-xl-10 {
    order: 10 !important;
  }
  .order-xl-11 {
    order: 11 !important;
  }
  .order-xl-12 {
    order: 12 !important;
  }
  .order-xl-last {
    order: 13 !important;
  }
  .ga-xl-0 {
    gap: 0px !important;
  }
  .ga-xl-1 {
    gap: 4px !important;
  }
  .ga-xl-2 {
    gap: 8px !important;
  }
  .ga-xl-3 {
    gap: 12px !important;
  }
  .ga-xl-4 {
    gap: 16px !important;
  }
  .ga-xl-5 {
    gap: 20px !important;
  }
  .ga-xl-6 {
    gap: 24px !important;
  }
  .ga-xl-7 {
    gap: 28px !important;
  }
  .ga-xl-8 {
    gap: 32px !important;
  }
  .ga-xl-9 {
    gap: 36px !important;
  }
  .ga-xl-10 {
    gap: 40px !important;
  }
  .ga-xl-11 {
    gap: 44px !important;
  }
  .ga-xl-12 {
    gap: 48px !important;
  }
  .ga-xl-13 {
    gap: 52px !important;
  }
  .ga-xl-14 {
    gap: 56px !important;
  }
  .ga-xl-15 {
    gap: 60px !important;
  }
  .ga-xl-16 {
    gap: 64px !important;
  }
  .ga-xl-auto {
    gap: auto !important;
  }
  .gr-xl-0 {
    row-gap: 0px !important;
  }
  .gr-xl-1 {
    row-gap: 4px !important;
  }
  .gr-xl-2 {
    row-gap: 8px !important;
  }
  .gr-xl-3 {
    row-gap: 12px !important;
  }
  .gr-xl-4 {
    row-gap: 16px !important;
  }
  .gr-xl-5 {
    row-gap: 20px !important;
  }
  .gr-xl-6 {
    row-gap: 24px !important;
  }
  .gr-xl-7 {
    row-gap: 28px !important;
  }
  .gr-xl-8 {
    row-gap: 32px !important;
  }
  .gr-xl-9 {
    row-gap: 36px !important;
  }
  .gr-xl-10 {
    row-gap: 40px !important;
  }
  .gr-xl-11 {
    row-gap: 44px !important;
  }
  .gr-xl-12 {
    row-gap: 48px !important;
  }
  .gr-xl-13 {
    row-gap: 52px !important;
  }
  .gr-xl-14 {
    row-gap: 56px !important;
  }
  .gr-xl-15 {
    row-gap: 60px !important;
  }
  .gr-xl-16 {
    row-gap: 64px !important;
  }
  .gr-xl-auto {
    row-gap: auto !important;
  }
  .gc-xl-0 {
    column-gap: 0px !important;
  }
  .gc-xl-1 {
    column-gap: 4px !important;
  }
  .gc-xl-2 {
    column-gap: 8px !important;
  }
  .gc-xl-3 {
    column-gap: 12px !important;
  }
  .gc-xl-4 {
    column-gap: 16px !important;
  }
  .gc-xl-5 {
    column-gap: 20px !important;
  }
  .gc-xl-6 {
    column-gap: 24px !important;
  }
  .gc-xl-7 {
    column-gap: 28px !important;
  }
  .gc-xl-8 {
    column-gap: 32px !important;
  }
  .gc-xl-9 {
    column-gap: 36px !important;
  }
  .gc-xl-10 {
    column-gap: 40px !important;
  }
  .gc-xl-11 {
    column-gap: 44px !important;
  }
  .gc-xl-12 {
    column-gap: 48px !important;
  }
  .gc-xl-13 {
    column-gap: 52px !important;
  }
  .gc-xl-14 {
    column-gap: 56px !important;
  }
  .gc-xl-15 {
    column-gap: 60px !important;
  }
  .gc-xl-16 {
    column-gap: 64px !important;
  }
  .gc-xl-auto {
    column-gap: auto !important;
  }
  .ma-xl-0 {
    margin: 0px !important;
  }
  .ma-xl-1 {
    margin: 4px !important;
  }
  .ma-xl-2 {
    margin: 8px !important;
  }
  .ma-xl-3 {
    margin: 12px !important;
  }
  .ma-xl-4 {
    margin: 16px !important;
  }
  .ma-xl-5 {
    margin: 20px !important;
  }
  .ma-xl-6 {
    margin: 24px !important;
  }
  .ma-xl-7 {
    margin: 28px !important;
  }
  .ma-xl-8 {
    margin: 32px !important;
  }
  .ma-xl-9 {
    margin: 36px !important;
  }
  .ma-xl-10 {
    margin: 40px !important;
  }
  .ma-xl-11 {
    margin: 44px !important;
  }
  .ma-xl-12 {
    margin: 48px !important;
  }
  .ma-xl-13 {
    margin: 52px !important;
  }
  .ma-xl-14 {
    margin: 56px !important;
  }
  .ma-xl-15 {
    margin: 60px !important;
  }
  .ma-xl-16 {
    margin: 64px !important;
  }
  .ma-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .mx-xl-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .mx-xl-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .mx-xl-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .mx-xl-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .mx-xl-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .mx-xl-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }
  .mx-xl-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-xl-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }
  .mx-xl-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }
  .mx-xl-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .mx-xl-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }
  .mx-xl-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }
  .mx-xl-13 {
    margin-right: 52px !important;
    margin-left: 52px !important;
  }
  .mx-xl-14 {
    margin-right: 56px !important;
    margin-left: 56px !important;
  }
  .mx-xl-15 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .mx-xl-16 {
    margin-right: 64px !important;
    margin-left: 64px !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .my-xl-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .my-xl-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .my-xl-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .my-xl-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .my-xl-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .my-xl-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .my-xl-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-xl-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .my-xl-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .my-xl-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .my-xl-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .my-xl-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .my-xl-13 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
  }
  .my-xl-14 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .my-xl-15 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .my-xl-16 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0px !important;
  }
  .mt-xl-1 {
    margin-top: 4px !important;
  }
  .mt-xl-2 {
    margin-top: 8px !important;
  }
  .mt-xl-3 {
    margin-top: 12px !important;
  }
  .mt-xl-4 {
    margin-top: 16px !important;
  }
  .mt-xl-5 {
    margin-top: 20px !important;
  }
  .mt-xl-6 {
    margin-top: 24px !important;
  }
  .mt-xl-7 {
    margin-top: 28px !important;
  }
  .mt-xl-8 {
    margin-top: 32px !important;
  }
  .mt-xl-9 {
    margin-top: 36px !important;
  }
  .mt-xl-10 {
    margin-top: 40px !important;
  }
  .mt-xl-11 {
    margin-top: 44px !important;
  }
  .mt-xl-12 {
    margin-top: 48px !important;
  }
  .mt-xl-13 {
    margin-top: 52px !important;
  }
  .mt-xl-14 {
    margin-top: 56px !important;
  }
  .mt-xl-15 {
    margin-top: 60px !important;
  }
  .mt-xl-16 {
    margin-top: 64px !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-0 {
    margin-right: 0px !important;
  }
  .mr-xl-1 {
    margin-right: 4px !important;
  }
  .mr-xl-2 {
    margin-right: 8px !important;
  }
  .mr-xl-3 {
    margin-right: 12px !important;
  }
  .mr-xl-4 {
    margin-right: 16px !important;
  }
  .mr-xl-5 {
    margin-right: 20px !important;
  }
  .mr-xl-6 {
    margin-right: 24px !important;
  }
  .mr-xl-7 {
    margin-right: 28px !important;
  }
  .mr-xl-8 {
    margin-right: 32px !important;
  }
  .mr-xl-9 {
    margin-right: 36px !important;
  }
  .mr-xl-10 {
    margin-right: 40px !important;
  }
  .mr-xl-11 {
    margin-right: 44px !important;
  }
  .mr-xl-12 {
    margin-right: 48px !important;
  }
  .mr-xl-13 {
    margin-right: 52px !important;
  }
  .mr-xl-14 {
    margin-right: 56px !important;
  }
  .mr-xl-15 {
    margin-right: 60px !important;
  }
  .mr-xl-16 {
    margin-right: 64px !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0px !important;
  }
  .mb-xl-1 {
    margin-bottom: 4px !important;
  }
  .mb-xl-2 {
    margin-bottom: 8px !important;
  }
  .mb-xl-3 {
    margin-bottom: 12px !important;
  }
  .mb-xl-4 {
    margin-bottom: 16px !important;
  }
  .mb-xl-5 {
    margin-bottom: 20px !important;
  }
  .mb-xl-6 {
    margin-bottom: 24px !important;
  }
  .mb-xl-7 {
    margin-bottom: 28px !important;
  }
  .mb-xl-8 {
    margin-bottom: 32px !important;
  }
  .mb-xl-9 {
    margin-bottom: 36px !important;
  }
  .mb-xl-10 {
    margin-bottom: 40px !important;
  }
  .mb-xl-11 {
    margin-bottom: 44px !important;
  }
  .mb-xl-12 {
    margin-bottom: 48px !important;
  }
  .mb-xl-13 {
    margin-bottom: 52px !important;
  }
  .mb-xl-14 {
    margin-bottom: 56px !important;
  }
  .mb-xl-15 {
    margin-bottom: 60px !important;
  }
  .mb-xl-16 {
    margin-bottom: 64px !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-0 {
    margin-left: 0px !important;
  }
  .ml-xl-1 {
    margin-left: 4px !important;
  }
  .ml-xl-2 {
    margin-left: 8px !important;
  }
  .ml-xl-3 {
    margin-left: 12px !important;
  }
  .ml-xl-4 {
    margin-left: 16px !important;
  }
  .ml-xl-5 {
    margin-left: 20px !important;
  }
  .ml-xl-6 {
    margin-left: 24px !important;
  }
  .ml-xl-7 {
    margin-left: 28px !important;
  }
  .ml-xl-8 {
    margin-left: 32px !important;
  }
  .ml-xl-9 {
    margin-left: 36px !important;
  }
  .ml-xl-10 {
    margin-left: 40px !important;
  }
  .ml-xl-11 {
    margin-left: 44px !important;
  }
  .ml-xl-12 {
    margin-left: 48px !important;
  }
  .ml-xl-13 {
    margin-left: 52px !important;
  }
  .ml-xl-14 {
    margin-left: 56px !important;
  }
  .ml-xl-15 {
    margin-left: 60px !important;
  }
  .ml-xl-16 {
    margin-left: 64px !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .ms-xl-0 {
    margin-inline-start: 0px !important;
  }
  .ms-xl-1 {
    margin-inline-start: 4px !important;
  }
  .ms-xl-2 {
    margin-inline-start: 8px !important;
  }
  .ms-xl-3 {
    margin-inline-start: 12px !important;
  }
  .ms-xl-4 {
    margin-inline-start: 16px !important;
  }
  .ms-xl-5 {
    margin-inline-start: 20px !important;
  }
  .ms-xl-6 {
    margin-inline-start: 24px !important;
  }
  .ms-xl-7 {
    margin-inline-start: 28px !important;
  }
  .ms-xl-8 {
    margin-inline-start: 32px !important;
  }
  .ms-xl-9 {
    margin-inline-start: 36px !important;
  }
  .ms-xl-10 {
    margin-inline-start: 40px !important;
  }
  .ms-xl-11 {
    margin-inline-start: 44px !important;
  }
  .ms-xl-12 {
    margin-inline-start: 48px !important;
  }
  .ms-xl-13 {
    margin-inline-start: 52px !important;
  }
  .ms-xl-14 {
    margin-inline-start: 56px !important;
  }
  .ms-xl-15 {
    margin-inline-start: 60px !important;
  }
  .ms-xl-16 {
    margin-inline-start: 64px !important;
  }
  .ms-xl-auto {
    margin-inline-start: auto !important;
  }
  .me-xl-0 {
    margin-inline-end: 0px !important;
  }
  .me-xl-1 {
    margin-inline-end: 4px !important;
  }
  .me-xl-2 {
    margin-inline-end: 8px !important;
  }
  .me-xl-3 {
    margin-inline-end: 12px !important;
  }
  .me-xl-4 {
    margin-inline-end: 16px !important;
  }
  .me-xl-5 {
    margin-inline-end: 20px !important;
  }
  .me-xl-6 {
    margin-inline-end: 24px !important;
  }
  .me-xl-7 {
    margin-inline-end: 28px !important;
  }
  .me-xl-8 {
    margin-inline-end: 32px !important;
  }
  .me-xl-9 {
    margin-inline-end: 36px !important;
  }
  .me-xl-10 {
    margin-inline-end: 40px !important;
  }
  .me-xl-11 {
    margin-inline-end: 44px !important;
  }
  .me-xl-12 {
    margin-inline-end: 48px !important;
  }
  .me-xl-13 {
    margin-inline-end: 52px !important;
  }
  .me-xl-14 {
    margin-inline-end: 56px !important;
  }
  .me-xl-15 {
    margin-inline-end: 60px !important;
  }
  .me-xl-16 {
    margin-inline-end: 64px !important;
  }
  .me-xl-auto {
    margin-inline-end: auto !important;
  }
  .ma-xl-n1 {
    margin: -4px !important;
  }
  .ma-xl-n2 {
    margin: -8px !important;
  }
  .ma-xl-n3 {
    margin: -12px !important;
  }
  .ma-xl-n4 {
    margin: -16px !important;
  }
  .ma-xl-n5 {
    margin: -20px !important;
  }
  .ma-xl-n6 {
    margin: -24px !important;
  }
  .ma-xl-n7 {
    margin: -28px !important;
  }
  .ma-xl-n8 {
    margin: -32px !important;
  }
  .ma-xl-n9 {
    margin: -36px !important;
  }
  .ma-xl-n10 {
    margin: -40px !important;
  }
  .ma-xl-n11 {
    margin: -44px !important;
  }
  .ma-xl-n12 {
    margin: -48px !important;
  }
  .ma-xl-n13 {
    margin: -52px !important;
  }
  .ma-xl-n14 {
    margin: -56px !important;
  }
  .ma-xl-n15 {
    margin: -60px !important;
  }
  .ma-xl-n16 {
    margin: -64px !important;
  }
  .mx-xl-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }
  .mx-xl-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
  .mx-xl-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .mx-xl-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
  .mx-xl-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .mx-xl-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }
  .mx-xl-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .mx-xl-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }
  .mx-xl-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }
  .mx-xl-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }
  .mx-xl-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }
  .mx-xl-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }
  .mx-xl-n13 {
    margin-right: -52px !important;
    margin-left: -52px !important;
  }
  .mx-xl-n14 {
    margin-right: -56px !important;
    margin-left: -56px !important;
  }
  .mx-xl-n15 {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
  .mx-xl-n16 {
    margin-right: -64px !important;
    margin-left: -64px !important;
  }
  .my-xl-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }
  .my-xl-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
  .my-xl-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }
  .my-xl-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }
  .my-xl-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .my-xl-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }
  .my-xl-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .my-xl-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }
  .my-xl-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }
  .my-xl-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .my-xl-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }
  .my-xl-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }
  .my-xl-n13 {
    margin-top: -52px !important;
    margin-bottom: -52px !important;
  }
  .my-xl-n14 {
    margin-top: -56px !important;
    margin-bottom: -56px !important;
  }
  .my-xl-n15 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
  }
  .my-xl-n16 {
    margin-top: -64px !important;
    margin-bottom: -64px !important;
  }
  .mt-xl-n1 {
    margin-top: -4px !important;
  }
  .mt-xl-n2 {
    margin-top: -8px !important;
  }
  .mt-xl-n3 {
    margin-top: -12px !important;
  }
  .mt-xl-n4 {
    margin-top: -16px !important;
  }
  .mt-xl-n5 {
    margin-top: -20px !important;
  }
  .mt-xl-n6 {
    margin-top: -24px !important;
  }
  .mt-xl-n7 {
    margin-top: -28px !important;
  }
  .mt-xl-n8 {
    margin-top: -32px !important;
  }
  .mt-xl-n9 {
    margin-top: -36px !important;
  }
  .mt-xl-n10 {
    margin-top: -40px !important;
  }
  .mt-xl-n11 {
    margin-top: -44px !important;
  }
  .mt-xl-n12 {
    margin-top: -48px !important;
  }
  .mt-xl-n13 {
    margin-top: -52px !important;
  }
  .mt-xl-n14 {
    margin-top: -56px !important;
  }
  .mt-xl-n15 {
    margin-top: -60px !important;
  }
  .mt-xl-n16 {
    margin-top: -64px !important;
  }
  .mr-xl-n1 {
    margin-right: -4px !important;
  }
  .mr-xl-n2 {
    margin-right: -8px !important;
  }
  .mr-xl-n3 {
    margin-right: -12px !important;
  }
  .mr-xl-n4 {
    margin-right: -16px !important;
  }
  .mr-xl-n5 {
    margin-right: -20px !important;
  }
  .mr-xl-n6 {
    margin-right: -24px !important;
  }
  .mr-xl-n7 {
    margin-right: -28px !important;
  }
  .mr-xl-n8 {
    margin-right: -32px !important;
  }
  .mr-xl-n9 {
    margin-right: -36px !important;
  }
  .mr-xl-n10 {
    margin-right: -40px !important;
  }
  .mr-xl-n11 {
    margin-right: -44px !important;
  }
  .mr-xl-n12 {
    margin-right: -48px !important;
  }
  .mr-xl-n13 {
    margin-right: -52px !important;
  }
  .mr-xl-n14 {
    margin-right: -56px !important;
  }
  .mr-xl-n15 {
    margin-right: -60px !important;
  }
  .mr-xl-n16 {
    margin-right: -64px !important;
  }
  .mb-xl-n1 {
    margin-bottom: -4px !important;
  }
  .mb-xl-n2 {
    margin-bottom: -8px !important;
  }
  .mb-xl-n3 {
    margin-bottom: -12px !important;
  }
  .mb-xl-n4 {
    margin-bottom: -16px !important;
  }
  .mb-xl-n5 {
    margin-bottom: -20px !important;
  }
  .mb-xl-n6 {
    margin-bottom: -24px !important;
  }
  .mb-xl-n7 {
    margin-bottom: -28px !important;
  }
  .mb-xl-n8 {
    margin-bottom: -32px !important;
  }
  .mb-xl-n9 {
    margin-bottom: -36px !important;
  }
  .mb-xl-n10 {
    margin-bottom: -40px !important;
  }
  .mb-xl-n11 {
    margin-bottom: -44px !important;
  }
  .mb-xl-n12 {
    margin-bottom: -48px !important;
  }
  .mb-xl-n13 {
    margin-bottom: -52px !important;
  }
  .mb-xl-n14 {
    margin-bottom: -56px !important;
  }
  .mb-xl-n15 {
    margin-bottom: -60px !important;
  }
  .mb-xl-n16 {
    margin-bottom: -64px !important;
  }
  .ml-xl-n1 {
    margin-left: -4px !important;
  }
  .ml-xl-n2 {
    margin-left: -8px !important;
  }
  .ml-xl-n3 {
    margin-left: -12px !important;
  }
  .ml-xl-n4 {
    margin-left: -16px !important;
  }
  .ml-xl-n5 {
    margin-left: -20px !important;
  }
  .ml-xl-n6 {
    margin-left: -24px !important;
  }
  .ml-xl-n7 {
    margin-left: -28px !important;
  }
  .ml-xl-n8 {
    margin-left: -32px !important;
  }
  .ml-xl-n9 {
    margin-left: -36px !important;
  }
  .ml-xl-n10 {
    margin-left: -40px !important;
  }
  .ml-xl-n11 {
    margin-left: -44px !important;
  }
  .ml-xl-n12 {
    margin-left: -48px !important;
  }
  .ml-xl-n13 {
    margin-left: -52px !important;
  }
  .ml-xl-n14 {
    margin-left: -56px !important;
  }
  .ml-xl-n15 {
    margin-left: -60px !important;
  }
  .ml-xl-n16 {
    margin-left: -64px !important;
  }
  .ms-xl-n1 {
    margin-inline-start: -4px !important;
  }
  .ms-xl-n2 {
    margin-inline-start: -8px !important;
  }
  .ms-xl-n3 {
    margin-inline-start: -12px !important;
  }
  .ms-xl-n4 {
    margin-inline-start: -16px !important;
  }
  .ms-xl-n5 {
    margin-inline-start: -20px !important;
  }
  .ms-xl-n6 {
    margin-inline-start: -24px !important;
  }
  .ms-xl-n7 {
    margin-inline-start: -28px !important;
  }
  .ms-xl-n8 {
    margin-inline-start: -32px !important;
  }
  .ms-xl-n9 {
    margin-inline-start: -36px !important;
  }
  .ms-xl-n10 {
    margin-inline-start: -40px !important;
  }
  .ms-xl-n11 {
    margin-inline-start: -44px !important;
  }
  .ms-xl-n12 {
    margin-inline-start: -48px !important;
  }
  .ms-xl-n13 {
    margin-inline-start: -52px !important;
  }
  .ms-xl-n14 {
    margin-inline-start: -56px !important;
  }
  .ms-xl-n15 {
    margin-inline-start: -60px !important;
  }
  .ms-xl-n16 {
    margin-inline-start: -64px !important;
  }
  .me-xl-n1 {
    margin-inline-end: -4px !important;
  }
  .me-xl-n2 {
    margin-inline-end: -8px !important;
  }
  .me-xl-n3 {
    margin-inline-end: -12px !important;
  }
  .me-xl-n4 {
    margin-inline-end: -16px !important;
  }
  .me-xl-n5 {
    margin-inline-end: -20px !important;
  }
  .me-xl-n6 {
    margin-inline-end: -24px !important;
  }
  .me-xl-n7 {
    margin-inline-end: -28px !important;
  }
  .me-xl-n8 {
    margin-inline-end: -32px !important;
  }
  .me-xl-n9 {
    margin-inline-end: -36px !important;
  }
  .me-xl-n10 {
    margin-inline-end: -40px !important;
  }
  .me-xl-n11 {
    margin-inline-end: -44px !important;
  }
  .me-xl-n12 {
    margin-inline-end: -48px !important;
  }
  .me-xl-n13 {
    margin-inline-end: -52px !important;
  }
  .me-xl-n14 {
    margin-inline-end: -56px !important;
  }
  .me-xl-n15 {
    margin-inline-end: -60px !important;
  }
  .me-xl-n16 {
    margin-inline-end: -64px !important;
  }
  .pa-xl-0 {
    padding: 0px !important;
  }
  .pa-xl-1 {
    padding: 4px !important;
  }
  .pa-xl-2 {
    padding: 8px !important;
  }
  .pa-xl-3 {
    padding: 12px !important;
  }
  .pa-xl-4 {
    padding: 16px !important;
  }
  .pa-xl-5 {
    padding: 20px !important;
  }
  .pa-xl-6 {
    padding: 24px !important;
  }
  .pa-xl-7 {
    padding: 28px !important;
  }
  .pa-xl-8 {
    padding: 32px !important;
  }
  .pa-xl-9 {
    padding: 36px !important;
  }
  .pa-xl-10 {
    padding: 40px !important;
  }
  .pa-xl-11 {
    padding: 44px !important;
  }
  .pa-xl-12 {
    padding: 48px !important;
  }
  .pa-xl-13 {
    padding: 52px !important;
  }
  .pa-xl-14 {
    padding: 56px !important;
  }
  .pa-xl-15 {
    padding: 60px !important;
  }
  .pa-xl-16 {
    padding: 64px !important;
  }
  .px-xl-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .px-xl-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .px-xl-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .px-xl-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .px-xl-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .px-xl-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .px-xl-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .px-xl-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .px-xl-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
  .px-xl-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }
  .px-xl-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .px-xl-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }
  .px-xl-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
  .px-xl-13 {
    padding-right: 52px !important;
    padding-left: 52px !important;
  }
  .px-xl-14 {
    padding-right: 56px !important;
    padding-left: 56px !important;
  }
  .px-xl-15 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .px-xl-16 {
    padding-right: 64px !important;
    padding-left: 64px !important;
  }
  .py-xl-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .py-xl-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .py-xl-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-xl-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .py-xl-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-xl-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .py-xl-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-xl-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .py-xl-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-xl-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .py-xl-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py-xl-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .py-xl-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .py-xl-13 {
    padding-top: 52px !important;
    padding-bottom: 52px !important;
  }
  .py-xl-14 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .py-xl-15 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .py-xl-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .pt-xl-0 {
    padding-top: 0px !important;
  }
  .pt-xl-1 {
    padding-top: 4px !important;
  }
  .pt-xl-2 {
    padding-top: 8px !important;
  }
  .pt-xl-3 {
    padding-top: 12px !important;
  }
  .pt-xl-4 {
    padding-top: 16px !important;
  }
  .pt-xl-5 {
    padding-top: 20px !important;
  }
  .pt-xl-6 {
    padding-top: 24px !important;
  }
  .pt-xl-7 {
    padding-top: 28px !important;
  }
  .pt-xl-8 {
    padding-top: 32px !important;
  }
  .pt-xl-9 {
    padding-top: 36px !important;
  }
  .pt-xl-10 {
    padding-top: 40px !important;
  }
  .pt-xl-11 {
    padding-top: 44px !important;
  }
  .pt-xl-12 {
    padding-top: 48px !important;
  }
  .pt-xl-13 {
    padding-top: 52px !important;
  }
  .pt-xl-14 {
    padding-top: 56px !important;
  }
  .pt-xl-15 {
    padding-top: 60px !important;
  }
  .pt-xl-16 {
    padding-top: 64px !important;
  }
  .pr-xl-0 {
    padding-right: 0px !important;
  }
  .pr-xl-1 {
    padding-right: 4px !important;
  }
  .pr-xl-2 {
    padding-right: 8px !important;
  }
  .pr-xl-3 {
    padding-right: 12px !important;
  }
  .pr-xl-4 {
    padding-right: 16px !important;
  }
  .pr-xl-5 {
    padding-right: 20px !important;
  }
  .pr-xl-6 {
    padding-right: 24px !important;
  }
  .pr-xl-7 {
    padding-right: 28px !important;
  }
  .pr-xl-8 {
    padding-right: 32px !important;
  }
  .pr-xl-9 {
    padding-right: 36px !important;
  }
  .pr-xl-10 {
    padding-right: 40px !important;
  }
  .pr-xl-11 {
    padding-right: 44px !important;
  }
  .pr-xl-12 {
    padding-right: 48px !important;
  }
  .pr-xl-13 {
    padding-right: 52px !important;
  }
  .pr-xl-14 {
    padding-right: 56px !important;
  }
  .pr-xl-15 {
    padding-right: 60px !important;
  }
  .pr-xl-16 {
    padding-right: 64px !important;
  }
  .pb-xl-0 {
    padding-bottom: 0px !important;
  }
  .pb-xl-1 {
    padding-bottom: 4px !important;
  }
  .pb-xl-2 {
    padding-bottom: 8px !important;
  }
  .pb-xl-3 {
    padding-bottom: 12px !important;
  }
  .pb-xl-4 {
    padding-bottom: 16px !important;
  }
  .pb-xl-5 {
    padding-bottom: 20px !important;
  }
  .pb-xl-6 {
    padding-bottom: 24px !important;
  }
  .pb-xl-7 {
    padding-bottom: 28px !important;
  }
  .pb-xl-8 {
    padding-bottom: 32px !important;
  }
  .pb-xl-9 {
    padding-bottom: 36px !important;
  }
  .pb-xl-10 {
    padding-bottom: 40px !important;
  }
  .pb-xl-11 {
    padding-bottom: 44px !important;
  }
  .pb-xl-12 {
    padding-bottom: 48px !important;
  }
  .pb-xl-13 {
    padding-bottom: 52px !important;
  }
  .pb-xl-14 {
    padding-bottom: 56px !important;
  }
  .pb-xl-15 {
    padding-bottom: 60px !important;
  }
  .pb-xl-16 {
    padding-bottom: 64px !important;
  }
  .pl-xl-0 {
    padding-left: 0px !important;
  }
  .pl-xl-1 {
    padding-left: 4px !important;
  }
  .pl-xl-2 {
    padding-left: 8px !important;
  }
  .pl-xl-3 {
    padding-left: 12px !important;
  }
  .pl-xl-4 {
    padding-left: 16px !important;
  }
  .pl-xl-5 {
    padding-left: 20px !important;
  }
  .pl-xl-6 {
    padding-left: 24px !important;
  }
  .pl-xl-7 {
    padding-left: 28px !important;
  }
  .pl-xl-8 {
    padding-left: 32px !important;
  }
  .pl-xl-9 {
    padding-left: 36px !important;
  }
  .pl-xl-10 {
    padding-left: 40px !important;
  }
  .pl-xl-11 {
    padding-left: 44px !important;
  }
  .pl-xl-12 {
    padding-left: 48px !important;
  }
  .pl-xl-13 {
    padding-left: 52px !important;
  }
  .pl-xl-14 {
    padding-left: 56px !important;
  }
  .pl-xl-15 {
    padding-left: 60px !important;
  }
  .pl-xl-16 {
    padding-left: 64px !important;
  }
  .ps-xl-0 {
    padding-inline-start: 0px !important;
  }
  .ps-xl-1 {
    padding-inline-start: 4px !important;
  }
  .ps-xl-2 {
    padding-inline-start: 8px !important;
  }
  .ps-xl-3 {
    padding-inline-start: 12px !important;
  }
  .ps-xl-4 {
    padding-inline-start: 16px !important;
  }
  .ps-xl-5 {
    padding-inline-start: 20px !important;
  }
  .ps-xl-6 {
    padding-inline-start: 24px !important;
  }
  .ps-xl-7 {
    padding-inline-start: 28px !important;
  }
  .ps-xl-8 {
    padding-inline-start: 32px !important;
  }
  .ps-xl-9 {
    padding-inline-start: 36px !important;
  }
  .ps-xl-10 {
    padding-inline-start: 40px !important;
  }
  .ps-xl-11 {
    padding-inline-start: 44px !important;
  }
  .ps-xl-12 {
    padding-inline-start: 48px !important;
  }
  .ps-xl-13 {
    padding-inline-start: 52px !important;
  }
  .ps-xl-14 {
    padding-inline-start: 56px !important;
  }
  .ps-xl-15 {
    padding-inline-start: 60px !important;
  }
  .ps-xl-16 {
    padding-inline-start: 64px !important;
  }
  .pe-xl-0 {
    padding-inline-end: 0px !important;
  }
  .pe-xl-1 {
    padding-inline-end: 4px !important;
  }
  .pe-xl-2 {
    padding-inline-end: 8px !important;
  }
  .pe-xl-3 {
    padding-inline-end: 12px !important;
  }
  .pe-xl-4 {
    padding-inline-end: 16px !important;
  }
  .pe-xl-5 {
    padding-inline-end: 20px !important;
  }
  .pe-xl-6 {
    padding-inline-end: 24px !important;
  }
  .pe-xl-7 {
    padding-inline-end: 28px !important;
  }
  .pe-xl-8 {
    padding-inline-end: 32px !important;
  }
  .pe-xl-9 {
    padding-inline-end: 36px !important;
  }
  .pe-xl-10 {
    padding-inline-end: 40px !important;
  }
  .pe-xl-11 {
    padding-inline-end: 44px !important;
  }
  .pe-xl-12 {
    padding-inline-end: 48px !important;
  }
  .pe-xl-13 {
    padding-inline-end: 52px !important;
  }
  .pe-xl-14 {
    padding-inline-end: 56px !important;
  }
  .pe-xl-15 {
    padding-inline-end: 60px !important;
  }
  .pe-xl-16 {
    padding-inline-end: 64px !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-justify {
    text-align: justify !important;
  }
  .text-xl-start {
    text-align: start !important;
  }
  .text-xl-end {
    text-align: end !important;
  }
  .text-xl-h1 {
    font-size: 6rem !important;
    font-weight: 300;
    line-height: 6rem;
    letter-spacing: -0.015625em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-h2 {
    font-size: 3.75rem !important;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-h3 {
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-h4 {
    font-size: 2.125rem !important;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-h6 {
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-subtitle-1 {
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 1.75rem;
    letter-spacing: 0.009375em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-subtitle-2 {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-body-1 {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.03125em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-body-2 {
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-button {
    font-size: 0.875rem !important;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0.0892857143em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
  .text-xl-caption {
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
    font-family: "Roboto", sans-serif;
    text-transform: none !important;
  }
  .text-xl-overline {
    font-size: 0.75rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1666666667em !important;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase !important;
  }
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }

  .col-xl-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}


