.labodoc-popup.admin-medicine-delete-confirmation-modal {
    .labodoc-popup-container-content {
        p {
            text-align: center;
        }

        .title {
            font-weight: bold;
        }

        .choices {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                color: white;
                margin: 10px;
                padding: 5px 20px;
            }

            button.cancel {
                background: var(--labodoc-middle-blue);
            }

            button.delete {
                background-color: #DB3F33;
            }
        }
    }
}
