div.labodoc-text {
    position: relative;

    input, textarea {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid;
        width: 100%;
        display: block;
    }

    input:focus, textarea:focus {
        outline: none;
    }

    label {
        position: absolute;
        left: 0;
        transition: transform 200ms, color 200ms;
    }

    label:has(~ input:focus), /* Input has focus */
    label:has(~ textarea:focus),
    label:has(~ input:not(:placeholder-shown)),
    label:has(~ textarea:not(:placeholder-shown)) /* Input has a value */
    {
        transform: translateY(-20px);
        font-weight: bold;
    }

    .iti {
        width: 100%;
    }

    label:has(+ .iti) {
        transform: translateY(-20px);
        font-weight: bold;
    }

    span.reveal-password-icon {
        position: absolute;
        top: 0;
        right: 0;
    }
}
