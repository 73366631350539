.labodoc-flashinfo-message-form {
    .message {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;

        h3 {
            grid-column: 1 / -1;
        }

        .fields {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .title {
                align-self: end;
            }

            .text {
                grid-column: 1 / -1;

                textarea {
                    resize: vertical;
                    min-height: 10em;
                }
            }
        }

        .banner {
            img {
                display: block;
                width: 300px;
                margin: 20px auto;
            }
        }
    }
}
