.labodoc-select-checkbox {
    position: relative;

    > label {
        display: block;
        padding: 3px;
        background: white;
        border: 1px solid #ccc;
        cursor: pointer;

        &:before {
            content: '▼';
            float: right;
        }
    }

    > .dropdown {
        position: absolute;
        background: white;
        top: 30px;
        left: 2px;
        right: 2px;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .15);
        transform-origin: 50% 0;
        transform: scale(1, 0);
        transition: transform .15s ease-in-out .15s;
        max-height: 15rem;
        overflow-y: scroll;

        > .search {
            position: sticky;
            left: 0;
            top: 0;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 1em;
        }

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;

            > li {
                display: block;
                padding: 8px 12px;
                opacity: 0;
                transition: opacity .15s ease-in-out;
            }

            > li.option {
                margin-left: 1em;
            }
        }
    }
}

.labodoc-select-checkbox[disabled] {
    > label {
        pointer-events: none;
        cursor: default;

        background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
        color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
        border-color: rgba(118, 118, 118, 0.3);
    }
}

.labodoc-select-checkbox.open {
    > label:before {
        content: '▲';
    }

    > .dropdown {
        transform: scale(1, 1);
        transition-delay: 0s;
        z-index: 10;

        > ul {
            > li {
                opacity: 1;
                transition-delay: .2s;
            }
        }
    }
}
