form.laboratory-form {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    border-radius: 70px 70px 0 0;
    padding: 50px;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        row-gap: 20px;
    }

    div.information {
        width: 400px;

        div.form-group {
            margin-bottom: 30px;
        }
    }

    div.logo {
        img {
            width: 300px;
        }
    }
}

