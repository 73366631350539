div#page-login {
    --padding-top-and-bottom: 4em;

    padding-bottom: 2em;

    div.page-width {
        i.icon {
            --size: 2.2em;
            height: var(--size);
            width: var(--size);
            border-radius: 200px;
            background-color: var(--labodoc-light-blue);
            box-shadow: 0 0 10px var(--shadow-color);

            position: relative;
            top: calc(var(--size) / 2);

            margin: auto;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: calc(var(--size) + 1em);

            @media screen and (max-width: 700px) {
                background-color: white;
                --size: 2em;
            }
        }

        div.container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 1em;

            border-radius: 50px 50px 0 0;
            background-color: var(--labodoc-light-blue);
            box-shadow: 0 0 10px var(--shadow-color);

            max-width: 1000px;
            padding-top: var(--padding-top-and-bottom);
            padding-bottom: 40px;
            margin: auto;

            @media screen and (max-width: 700px) {
                align-items: stretch;

                border-radius: 0;
                background-color: initial;
                box-shadow: none;
            }

            h1 {
                text-align: center;
                margin: 0;
            }

            hr {
                width: 10em;
                border-color: var(--labodoc-yellow);
            }

            p {
                text-align: center;
                margin: auto;

                @media screen and (max-width: 700px) {
                    max-width: 260px;
                }

                a {
                    color: black;
                    font-weight: 900;
                }
            }

            p.notice {
                span {
                    font-style: italic;
                    font-size: 0.8em;
                }
            }

            a {
                margin: auto;
            }
        }
    }
}
