.labodoc-spinner {
    width: 100px;
    height: 100px;
    border: 10px solid #FFF;
    border-bottom-color: var(--labodoc-middle-blue);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
