form.password-forgotten {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    button.return {
        background: transparent;
        border: 1.5px solid var(--labodoc-middle-blue);
        color: var(--labodoc-middle-blue);
    }
}
