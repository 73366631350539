#page-register {
    flex: 1;
    padding: 1em 0;

    h1 {
        text-align: center;
        margin: 0 0 20px;
    }
}

#page-register.form {
    @media screen and (max-width: 700px) {
        padding: 0;
        background: linear-gradient(
                to bottom,
                var(--labodoc-beige) 0%,
                var(--labodoc-beige) 10vh,
                white 10vh,
                white 100vh
        );

        h1 {
            margin-bottom: 1em;
        }
    }
}
