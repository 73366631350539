#layout-admin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    > nav {
        padding: 30px 0 20px 0;
        background-color: var(--labodoc-beige);

        a {
            color: var(--labodoc-deep-blue);
            padding: 15px 20px;
            text-decoration: none;
            margin-right: 10px
        }

        a.active {
/*            background-color: var(--labodoc-deep-blue); */
            color: var(--labodoc-middle-blue);
            font-weight: bold;
            border-bottom: 4px solid var(--labodoc-middle-blue);
        }
    }
}
