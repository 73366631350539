div.labodoc-dialog-open div.modal-container {
  display: block;
  position: fixed;
  top: 10vh;
  left: 10vw;
  right: 10vw;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 60%;
  z-index: 10;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

div.labodoc-dialog-closed {
  display: none !important;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 16px;
  background-color: var(--labodoc-deep-blue);
  color: white;
}

.labodoc-dialog-close-btn {
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: var(--labodoc-deep-blue)
}
.labodoc-dialog-close-btn i {
    font-size: 28px;
}

.labodoc-dialog-open .modal-background {
 position: fixed;
 top: 0;
 left: 0;
 width: 100vw;
 height: 100vh;
 background-color: black;
 opacity: 0.15;
 z-index: 5;
}
.labodoc-dialog-closed .modal-background {
  display: none;
}

.modal-content {
  padding: 20px 16px;
  max-height: 80vh;
  overflow: scroll;
  scrollbar-color: var(--labodoc-middle-blue) lightgrey;
  scrollbar-width: thin;
}

.modal-content::-webkit-scrollbar {
  width: 3px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: var(--labodoc-middle-blue);
  border-radius: 100px;
}



@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}
