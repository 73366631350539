#page-admin-users-administration {
    flex: 1;

    .page-width {
        header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                border-bottom: 3px solid var(--labodoc-yellow);
                line-height: 2em;
                margin-bottom: 2em;
            }

            .tabs {
                display: flex;
                column-gap: 10px;

                .tab {
                    background-color: transparent;
                    border: 2px solid var(--labodoc-middle-blue);
                    color: var(--labodoc-middle-blue);
                }

                .tab.active {
                    background-color: var(--labodoc-middle-blue);
                    border: 2px solid var(--labodoc-middle-blue);
                    color: white;
                }
            }
        }

        .container {
            .applications-list {
                .labodoc-spinner {
                    display: block;
                    margin: 50px auto;
                }

                .applications {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .application {
                        margin: 10px 8px;
                        padding: 10px;
                        background-color: var(--labodoc-light-blue);
                        box-shadow: 0 0 5px var(--shadow-color);
                        border-radius: 10px;
                        flex: 1;

                        .information {
                            display: flex;
                            margin: 10px;

                            .category {
                                background-color: white;
                                border: 1px solid var(--labodoc-deep-blue);
                                padding: 0 20px;

                                .title {
                                    grid-column: 1 / -1;
                                    border-bottom: 3px solid var(--labodoc-yellow);
                                    line-height: 2em;
                                }

                                &:first-child {
                                    border-radius: 10px 0 0 10px;
                                }

                                &:last-child {
                                    border-radius: 0 10px 10px 0;
                                }
                            }

                            .personal,
                            .professional,
                            .facility,
                            .complementary {
                                min-width: 300px;
                            }

                            .complementary {
                                flex: 1;
                            }
                        }

                        .actions {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;

                            button {
                                color: white;
                                margin: 10px;
                                padding: 5px 20px;

                                &.reject {
                                    border: 1px solid #703939;
                                    color: #703939;
                                    background-color: transparent;
                                }

                                &.validate {
                                    border: 1px solid #397048;
                                    color: #397048;
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }

            .users-list {
                .labodoc-spinner {
                    display: block;
                    margin: 50px auto;
                }

                table {
                    width: 100%;
                    border-collapse: collapse;

                    td, th {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                    }

                    th {
                        background-color: var(--labodoc-middle-blue);
                        color: white;
                    }

                    tr:nth-child(even){
                        background-color: var(--labodoc-beige);
                    }

                    .actions {
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;

                        .action {
                            color: white;
                            padding: 0.5em 0;

                            &.enable {
                                border: 1px solid #397048;
                                background-color: #397048;
                            }

                            &.disable {
                                border: 1px solid #703939;
                                background-color: #703939;
                            }

                            &.delete {
                                border: 1px solid red;
                                background-color: red;
                            }
                        }
                    }
                }
            }
        }
    }
}

.labodoc-popup.admin-user-enable-confirmation-modal {
    .labodoc-popup-container-content {
        p {
            text-align: center;
        }

        .title {
            font-weight: bold;
        }

        .choices {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                color: white;
                margin: 10px;
                padding: 5px 20px;
            }

            button.cancel {
                background: var(--labodoc-middle-blue);
            }

            button.enable {
                background-color: #397048;
            }
        }
    }
}

.labodoc-popup.admin-user-disable-confirmation-modal {
    .labodoc-popup-container-content {
        p {
            text-align: center;
        }

        .title {
            font-weight: bold;
        }

        .choices {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                color: white;
                margin: 10px;
                padding: 5px 20px;
            }

            button.cancel {
                background: var(--labodoc-middle-blue);
            }

            button.disable {
                background-color: #703939;
            }
        }
    }
}

.labodoc-popup.admin-user-delete-confirmation-modal {
    .labodoc-popup-container-content {
        p {
            text-align: center;
        }

        .title {
            font-weight: bold;
        }

        .choices {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                color: white;
                margin: 10px;
                padding: 5px 20px;
            }

            button.cancel {
                background: var(--labodoc-middle-blue);
            }

            button.delete {
                background-color: #DB3F33;
            }
        }
    }
}
