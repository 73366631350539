div.home {
    div.page-width {
        display: flex;
        flex-direction: column;
        align-items: center;

        > p {
            text-align: center;
            margin: 0;

            a {
                color: var(--labodoc-deep-blue);
                font-weight: bold;
            }
        }

        hr {
            display: none;
            @media screen and (max-width: 700px) {
                display: initial;
                width: 10em;
                border-color: var(--labodoc-yellow);
                margin: 20px 0;
            }
        }

        i.icon {
            --size: 2.2em;
            height: var(--size);
            width: var(--size);
            border-radius: 200px;
            background-color: var(--labodoc-light-blue);
            box-shadow: 0 0 10px var(--shadow-color);

            position: relative;
            top: calc(var(--size) / 2);

            margin: auto;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: calc(var(--size) + 1em);

            @media screen and (max-width: 700px) {
                background-color: white;
                --size: 2em;
                top: initial;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 1em;

            border-radius: 50px 50px 0 0;
            background-color: var(--labodoc-light-blue);
            box-shadow: 0 0 10px var(--shadow-color);

            max-width: 1000px;
            padding: 4em 15em 2em 15em;

            @media screen and (max-width: 1000px) {
                padding: 4em 10em 2em 10em;
            }
            @media screen and (max-width: 700px) {
                align-items: stretch;

                border-radius: 0;
                background-color: initial;
                box-shadow: none;
                padding: 0;
            }

            p {
                max-width: 600px;
                text-align: center;
                margin-bottom: 40px;
            }

            div.labodoc-checkbox {
                label {
                    color: initial !important;

                    a {
                        color: black;
                        font-weight: bold;
                    }
                }
            }

            div.buttons {
                display: flex;
                flex-direction: column;
                row-gap: 1em;
            }

            div.notice {
                margin-top: 10px;
                margin-bottom: 0;

                p {
                    font-style: italic;
                    font-size: 0.8em;
                    margin: 5px 5px 0;
                }
            }
        }
    }
}
