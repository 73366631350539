#page-faq {
  #page-faq-header {
    #page-faq-presentation {
      border-radius: 0 100px 0 0;
      padding-bottom: 50px;
      @media screen and (max-width: 700px) {
        border-radius: 0 0 40px 0;
        max-width: 95vw;
        padding-bottom: 25px;
      }

      #page-faq-presentation-content {
        h1 {
          font-size: 35px;
          max-width: 500px;
        }

        p {
          max-width: 550px;
        }
      }
    }
    img {
      position: relative;
      top: 12px;
      max-height: 430px;
      margin-left: 175px;
    }
    @media screen and (min-width: 2001px) {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }
  #page-faq-general-question {

  }

  #page-faq-two-columns {
    div.page-faq-column-1 {
        border-radius: 0px 50px 50px 0;
        background-color: var(--labodoc-light-blue);
        padding: 3em 10vw;
        height: 100%;
    }
    div.page-faq-column-2 {
        padding-top: 3em;
    }
  }

  #page-faq-contact {
    margin-left: 10vw;
    margin-right: 10vw;
    div.bubble {
        border-radius: 50px 50px 50px 0;
        background-color: var(--labodoc-beige);
        padding: 5em 10vw;
    }
  }

  div.faq-collapsable-container {
      div.faq-collapsable-wrapper {
          div.faq-collapsable-wrapper-header {
              cursor: pointer;
              div {
                img {
                    width: 25px;
                    height: 25px;
                }
              }
          }
          div.faq-collapsable-wrapper-content {
              span {
                display: block;
                -webkit-transition: all 0.2s linear;
                -moz-transition: all 0.2s linear;
                -o-transition: all 0.2s linear;
                transition: all 0.2s linear;
                transition-delay: 0.1s;
                max-height: 0;
                overflow: hidden;
              }
              span.show {
                  max-height:500px;
              }
          }
      }
  }

}
