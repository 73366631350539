#page-admin-health-directory {
    flex: 1;
    background-color: var(--labodoc-beige);
    padding-top: 20px;

    header {
        background-color: var(--labodoc-middle-blue);
        padding: 20px 0;
        margin-bottom: 20px;

        div.page-width {
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .filter-lines {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .form-group {
                    flex: 1;
                }

                div.labodoc-text, div.labodoc-select-checkbox  {
                    margin: 5px 1.1em;
                }
                div.labodoc-text {
                  margin-bottom: 0;
                  margin-top: 22px;
                  background: white;
                  border-radius: 10px;
                  padding: 2px 0;

                  input {
                    border-bottom: none;
                  }
                  label {
                    color: black;
                    padding-left: 5px;
                  }
                  label:has(~ input:focus), /* Input has focus */
                  label:has(~ textarea:focus),
                  label:has(~ input:not(:placeholder-shown)),
                  label:has(~ textarea:not(:placeholder-shown)) /* Input has a value */
                  {
                      transform: translateY(-30px);
                      color: white;
                      font-weight: bold;
                  }
                }
            }

            .filter-lines .form-label {
                color: white;
            }

            form {
                > div {
                    flex: 1;

                    > label {
                        color: white;
                    }

                    div.labodoc-select-checkbox {
                        div.list {
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }

    .labodoc-spinner {
        display: block;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    main {
        div.page-width {
            div.pagination {
                text-align: center;
                padding: 20px;
            }

            div.health-professionals {
                margin-top: 1.5em;

                div.health-professional {
                    background-color: var(--labodoc-light-blue);
                    padding: 20px;
                    margin-bottom: 20px;
                    box-shadow: 0 0 10px var(--shadow-color);
                    border-radius: 10px;

                    div.practices {
                        margin-left: 10px;

                        div.practice {
                            margin-left: 10px;
                            background-color: white;
                            border: 1px solid var(--labodoc-deep-blue);
                            border-radius: 10px;
                            padding: 20px;
                            margin-bottom: 10px;
                            position: relative;
                            cursor: pointer;

                            p.professional-information {
                                margin: 0;
                                &:before {
                                    content: '▼';
                                    float: right;
                                }
                            }

                            div.expertises {
                                display: none;
                                ul {
                                    margin-bottom: 0;
                                }
                            }

                            div.situations {
                                display: none;
                                ul {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        div.practice.open {
                            p.professional-information {
                                &:before {
                                    content: '▲';
                                }
                            }

                            div.expertises {
                                display: initial;
                            }

                            div.situations {
                                display: initial;
                            }
                        }
                    }

                    div.cards {
                        margin-left: 10px;

                        div.card {
                            margin-left: 10px;
                            background-color: white;
                            border: 1px solid var(--labodoc-deep-blue);
                            border-radius: 10px;
                            padding: 20px;
                            margin-bottom: 10px;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}
