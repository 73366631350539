#page-admin-learned-societies {
    flex: 1;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height: 2em;
            margin-bottom: 2em;
        }


        button {
            background-color: var(--labodoc-yellow);
            color: white;
        }
    }


    .learned-societies {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow-y: auto;
        max-height: 600px;

        .labodoc-spinner {
            display: block;
            margin: 50px auto;
        }

        .learned-society {
            width: 400px;
            background-color: white;
            margin: 40px 8px;
            box-shadow: 0 0 5px var(--shadow-color);
            padding: 30px;
            text-align: center;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            header {
                display: initial;

                img {
                    width: 250px;
                    cursor: pointer
                }

                .name {
                    font-size: 1.2em;
                    color: var(--labodoc-deep-blue);
                    text-decoration: none;
                    font-weight: bolder;
                    display: block;
                    margin: 10px 0 0 0;
                }
            }

            hr {
                color: var(--labodoc-yellow);
                width: 100%;
            }

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: center;

                button {
                    color: white;
                    margin: 10px;
                    padding: 5px 20px;
                }

                button.edit {
                    background-color: var(--labodoc-yellow);
                }

                button.delete {
                    background-color: #DB3F33;
                }
            }
        }
    }
}

.labodoc-popup.admin-learned-society-delete-confirmation-modal {
    .labodoc-popup-container-content {
        .title {
            text-align: center;
            font-weight: bold;
        }

        .choices {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                color: white;
                margin: 10px;
                padding: 5px 20px;
            }

            button.cancel {
                background: var(--labodoc-middle-blue);
            }

            button.delete {
                background-color: #DB3F33;
            }
        }
    }
}
