.labodoc-input-file {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    label {
        border-radius: 50px 50px 50px 0;
        background-color: var(--labodoc-middle-blue);
        color: white;
        padding: 0.7em 0 0.7em 1.5em;
        font-weight: bold;
        cursor: pointer;
    }

    label:after {
        border-radius: 50px;
        padding: 0.7em;
        box-shadow: 0 0 10px #0000004E;
        margin-left: 1.5em;
        font-family: "Font Awesome 6 Free";
        content: "\f65e" !important; /* Otherwise is being override if tagged as required */
        color: #FFFFFF !important; /* Otherwise is being override if tagged as required */
    }

    input {
        display: none;
    }

    .files-preview {
        margin-top: 5px;

        .file-preview {
            display: flex;
            margin-bottom: 5px;

            .delete {
                background-color: transparent;
                border: none;
            }

            .name {
                margin: 0;
            }
        }
    }

    span {
        margin-top: 0.5em;
        text-align: center;
        color: black;
    }
}
