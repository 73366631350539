#contact-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    #contact-form-container {
        border-radius: 50px 50px 0 0;
        width: 70vw;
        max-width: 1100px;
        margin: 4em auto 4em auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 3.75em 4em 2.5em 4em;


        h1 {
            margin: 0;
        }

        p {
            max-width: 33em;
            text-align: center;

            b {
                font-size: 0.8em;
            }
        }

        hr {
            width: 100%;
            background-color: var(--labodoc-yellow);
            border-color: var(--labodoc-yellow);
            margin: 30px;
        }

        form {
            display: grid;
            grid-template-columns: 50% 50%;
            row-gap: 20px;
            width: 100%;

            div.form-group {
                width: 80%;
            }

            #contact-name {
                grid-row: 1;
                grid-column: 1;
            }

            #contact-email {
                grid-row: 1;
                grid-column: 2;
            }

            #contact-subject {
                grid-row: 2;
                grid-column: 1;
            }

            #contact-message {
                grid-row: 3;
                grid-column: 1 / 2 span;

                textarea {
                    resize: vertical;
                    width: 100%;
                    height: 100px;
                }
            }

            @media screen and (max-width: 700px) {
                /*display: flex;*/
                /*flex-direction: column;*/

                div.form-group {
                    width: initial;
                }

                #contact-name {
                    grid-row: 1;
                    grid-column: 1 / 2 span;
                }

                #contact-email {
                    grid-row: 2;
                    grid-column: 1 / 2 span;
                }

                #contact-subject {
                    grid-row: 3;
                    grid-column: 1 / 2 span;
                }

                #contact-message {
                    grid-row: 4;
                    grid-column: 1 / 2 span;

                    textarea {
                        resize: vertical;
                        width: 100%;
                        height: 100px;
                    }
                }
            }
        }

        @media screen and (max-width: 700px) {
            padding: 1.75em 2em 1.5em 2em;
        }
    }
}
