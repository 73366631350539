#page-admin-medicine-create {
    flex: 1;
    padding-bottom: 50px;

    div.page-width {
        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height: 2em;
            margin-bottom: 2em;
        }

        button.save {
            background-color: var(--labodoc-yellow);
            color: white;
            margin: auto;
            display: block;
        }
    }
}
