#page-admin-medicine {
    flex: 1;
    background-color: var(--labodoc-beige);
    padding: 100px 0;

    .page-width {
        max-width: 1400px;

        .labodoc-spinner {
            display: block;
            margin: 100px auto;
        }

        > .medicine {
            display: grid;
            grid-template-columns: 500px 1fr;
            gap: 40px;
            background-color: white;
            padding: 40px;
            border-radius: 0 80px 0 0;
            box-shadow: 0 6px 10px #00000040;
            margin: auto;
            align-items: center;

            @media screen and (max-width: 1200px) {
                grid-template-columns: 1fr;
            }

            .information {
                .logo {
                    width: 100%;
                    margin-top: 20px;
                    max-width: 600px;
                }

                .data {
                    .name {
                        color: var(--labodoc-deep-blue);
                        font-weight: 900;
                        font-size: 1.5em;
                        margin: 0;
                    }

                    .main-composition {
                        color: var(--labodoc-middle-blue);
                        font-weight: 600;
                        font-size: 1.2em;
                        margin: 0;
                        margin-bottom: 20px;
                    }

                    .laboratory {
                        margin-bottom: 50px;
                        color: black;
                        font-size: 1.2em;
                    }

                    div.atc {
                        margin: 20px 0;

                        p.title {
                            margin: 5px 0;
                            font-weight: 900;
                            font-size: 1.1em;
                        }

                        p.category {
                            margin: 2px 0;
                            font-size: 0.9em;
                        }
                    }

                    .website, .pharmacovigilence {
                        display: block;
                        text-decoration: none;
                        color: var(--labodoc-deep-blue);
                        margin: 10px 0;

                        i {
                            margin-right: 5px;
                        }
                    }

                    .pharmacovigilence {
                        margin-bottom: 50px;
                    }
                }
            }

            .marketing-document-list {
                background-color: var(--labodoc-light-blue);
                border-radius: 50px 50px 0 0;
                padding: 30px;

                header {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid black;

                    h4 {
                        margin-right: 5px;
                    }

                    button {
                        background: transparent;
                        border: none;
                        padding: 5px;

                        i {
                            color: var(--labodoc-middle-blue);
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }

                    div.form-group {
                        margin: 0;

                        label {
                            padding: 5px;
                            cursor: pointer;
                            color: var(--labodoc-middle-blue);
                            font-size: 20px;
                            font-weight: bold;
                        }

                        label:after {
                            font-family: "Font Awesome 6 Free";
                            content: "\f65e";
                        }

                        input {
                            display: none;
                        }
                    }
                }

                .documents {
                    padding: 0;

                    .document {
                        display: flex;
                        align-items: center;

                        .name {
                        }

                        .actions {
                            button {
                                margin-left: 5px;
                                background: transparent;
                                border: none;

                                i {
                                    color: var(--labodoc-deep-blue);
                                    font-size: 20px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }

            .dosage-list {
                background-color: var(--labodoc-beige);
                border-radius: 0 0 50px 50px;
                padding: 30px;

                header {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid black;

                    h4 {
                        margin-right: 5px;
                    }

                    button {
                        background: transparent;
                        border: none;
                        padding: 5px;

                        i {
                            color: var(--labodoc-middle-blue);
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }

                    div.form-group {
                        margin: 0;

                        label {
                            padding: 5px;
                            cursor: pointer;
                            color: var(--labodoc-middle-blue);
                            font-size: 20px;
                            font-weight: bold;
                        }

                        label:after {
                            font-family: "Font Awesome 6 Free";
                            content: "\f65e";
                        }

                        input {
                            display: none;
                        }
                    }
                }

                .dosages {
                    padding: 0;

                    .dosage {
                        display: flex;
                        align-items: center;
                        cursor: move;

                        .name {
                            pointer-events: none;
                        }

                        .actions {
                            button {
                                margin-left: 5px;
                                background: transparent;
                                border: none;

                                i {
                                    color: var(--labodoc-deep-blue);
                                    font-size: 20px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }

                    .dosage.over {
                        border: 3px dotted #666;
                    }
                }
            }
        }

        > .actions {
            display: flex;
            justify-content: center;
            margin: 50px;

            button {
                color: white;
                margin: 10px;
                padding: 5px 20px;
            }

            .action.edit {
                background-color: var(--labodoc-yellow);
            }

            .action.delete {
                background-color: red;
                color: white;
            }
        }
    }
}

.labodoc-popup.admin-medicine-marketing-document-modal,
.labodoc-popup.admin-medicine-dosage-modal {
    .labodoc-popup-container {
        .labodoc-popup-container-content {
            text-align: center;

            h1 {
                margin: 0 0 50px;
            }
            form {
                .labodoc-text {
                    margin-bottom: 50px;
                }
            }
        }
    }
}
