#page-admin-messages {
    flex: 1;

    .labodoc-spinner {
        display: block;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height: 2em;
            margin-bottom: 2em;
        }


        button {
            background-color: var(--labodoc-yellow);
            color: white;
        }
    }

    .messages {
        header {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 100px;
            padding: 20px;

            p {
                font-weight: bold;
            }
        }

        .message {
            display: grid;
            grid-template-columns: 1fr 100px;
            align-items: center;
            margin-bottom: 20px;
            padding: 20px;
            box-shadow: 0 0 10px var(--shadow-color);
            border-radius: 10px;

            .details {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;

                p {
                    margin: 0;
                }

                a {
                    text-decoration: none;
                    color: var(--labodoc-deep-blue);
                    font-weight: bold;
                }

                .type {
                    grid-column: 1;
                }

                .title {
                    grid-column: 2;
                }

                .author {
                    grid-column: 3;
                }

                .published-at {
                    grid-column: 4;
                }
            }


            .actions {
                .action {
                    font-size: 20px;
                    font-weight: bold;
                    color: var(--labodoc-deep-blue);
                    margin: 0 6px;
                }

                a {
                    text-decoration: none;
                }

                button {
                    padding: 0;
                    background: transparent;
                    border: none;
                }
            }
        }
    }
}
