#page-health-professional-dashboard {
    flex: 1;
    background-color: var(--labodoc-beige);

    .medical-items-with-messages {
        display: grid;
        grid-template-columns: calc(500px + 6vw) 1fr;
        --height: calc(100vh - 200px);

        .messages {
            --messages-height: var(--height);
            height: var(--messages-height);
            background-color: var(--labodoc-middle-blue);
            display: flex;
            flex-direction: column;
            border-radius: 0 0 90px 0;
            padding: 0 50px 0 5vw;

            > header {
                flex-shrink: 0;

                .welcome-message {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    span.fa-envelope {
                        background-color: var(--labodoc-yellow);
                        color: white;
                        padding: 0.7em;
                        font-size: 2em;
                        border-radius: 80px 80px 0 80px;

                        .message-number {
                            position: absolute;
                            background-color: red;
                            padding: 2px 4px;
                            border-radius: 100%;
                        }
                    }

                    p {
                        color: white;

                        span {
                            font-weight: 900;
                            font-size: 1.2em;
                        }

                        span.hello {
                            font-size: 1.3em;
                        }
                    }
                }

                .filters {
                    display: flex;
                    justify-content: space-between;
                    color: white;

                    .labodoc-checkbox {
                        input {
                            border-color: #FFFFFF;
                        }

                        input:checked {
                            color: var(--labodoc-yellow);
                            border-color: var(--labodoc-yellow);
                        }
                    }
                }
            }

            .container {
                flex-grow: 1;
                overflow-y: auto;

                .no-messages {
                    margin-top: 60px;

                    p.text {
                        color: white;
                        text-align: center;
                        font-size: 1.4em;
                        font-weight: bold;
                    }

                    p.tip {
                        color: white;
                        text-align: center;
                    }
                }

                .list {
                    .message {
                        background-color: #FFFFFF;
                        border-radius: 30px 30px 30px 0;
                        position: relative;
                        margin: 10px 0 0;
                        max-width: 440px;

                        .content {
                            padding: 10px 20px 10px 20px;
                            color: var(--labodoc-deep-blue);
                            cursor: pointer;

                            header {
                                display: grid;
                                grid-template-columns: 108px 70px 1fr;
                                align-items: center;
                                gap: 20px;
                                position: relative;

                                p {
                                    margin: 0;
                                }

                                .type span {
                                    font-size: 14px;
                                    font-weight: 900;
                                    text-transform: uppercase;
                                    margin-right: 20px;
                                }

                                .created-date {
                                    font-size: 13px;
                                }

                                .favorite {
                                    justify-self: end;

                                    input[type="checkbox"] {
                                        opacity: 0;
                                        position: absolute;
                                        left: -9999px;
                                    }

                                    label {
                                        font-size: 1.7em; /* Adjust the size of the star */
                                        cursor: pointer;
                                        display: inline-block;
                                    }

                                    label::before {
                                        content: '☆'; /* Empty star */
                                        color: var(--labodoc-yellow); /* Gold color */
                                        display: inline-block;
                                    }

                                    input[type="checkbox"]:checked + label:before {
                                        content: '★'; /* Filled star */
                                    }

                                    input[type="checkbox"]:focus-visible + label:before {
                                        outline: 2px solid black; /* Example focus style */
                                    }
                                }
                            }

                            .title {
                                font-weight: 600;
                                margin-bottom: 5px;
                                margin-top: 0;
                                font-size: 0.8em;
                                line-height: 0.9em;
                                height: 27px;
                            }

                            .author {
                                margin: 0;
                                font-size: 12px;
                                color: var(--labodoc-middle-blue);
                            }
                        }
                    }

                    .message.new {
                        background-color: var(--labodoc-light-blue);
                    }
                }

                .labodoc-spinner {
                    display: block;
                    margin: 100px auto;
                }
            }
        }

        .medicine-list {
            --medicine-list-height: var(--height);
            height: var(--medicine-list-height);
            display: flex;
            flex-direction: column;
            padding: 0 5vw 0 50px;

            > header {
                flex-shrink: 0;
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-bottom: 20px;

                .speciality {
                    grid-column: 1 / -1;

                    h2 {
                        color: var(--labodoc-deep-blue);
                        font-weight: 900;
                        font-size: 1.4em;
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    p {
                        margin: 0;
                        color: var(--labodoc-middle-blue);
                        font-weight: 900;
                    }

                    margin: 0 0 10px 0;
                }

                .search-bar {
                    div.form-group {
                        margin: 0;

                        input {
                            border: none;
                            border-radius: 0 20px 0 0;
                            width: 100%;
                            max-width: 500px;
                        }
                    }
                }

                .toggles {
                    justify-self: end;

                    button {
                        background-color: transparent;
                        border: none;
                    }

                    button:before {
                        content: '▼';
                        float: right;
                    }

                    button.open:before {
                        content: '▲';
                    }

                    button.reset:before {
                        content: '';
                    }
                }

                .filter-options,
                .sort-options {
                    justify-self: end;
                    margin-top: 20px;
                    align-items: center;
                    gap: 20px;

                    p {
                        margin: 0;
                    }
                }

                .filter-options {
                    grid-column: 1 / -1;
                    display: flex;

                    .form-group.atc {
                        margin-bottom: 0;

                        .labodoc-select-checkbox {
                            width: 300px;

                            .dropdown {
                                max-height: 600px;

                                label {
                                    font-size: 0.8em;

                                    b {
                                        font-size: 0.8em;
                                    }
                                }
                            }
                        }
                    }
                }

                .sort-options {
                    grid-column: 1 / -1;
                    display: flex;

                    .form-check {
                        display: flex;
                        align-items: center;

                        input[type=radio] {
                            appearance: none;
                            width: 1.15em;
                            height: 1.15em;
                            border: 0.05em solid var(--labodoc-deep-blue);
                            border-radius: 20px 20px 20px 0;
                            margin-right: 10px;
                        }

                        input[type=radio]:checked {
                            background-color: currentColor;
                            color: var(--labodoc-deep-blue);
                        }
                    }
                }
            }

            > .container {
                flex-grow: 1;
                overflow-y: auto;

                .labodoc-spinner {
                    display: block;
                    margin: 100px auto;
                }

                .no-medicines {
                    color: var(--labodoc-deep-blue);
                    text-align: center;
                    font-size: 2em;
                    font-weight: bold;
                }

                .medicine-cards {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 170px);
                    grid-gap: 8px;
                    justify-content: center;

                    a {
                        text-decoration: none;
                        display: flex;

                        .medicine-card {
                            width: 200px;
                            background-color: #FFFFFF;
                            border-radius: 20px 20px 20px 0;
                            padding: 5px 5px 20px 5px;
                            text-align: center;
                            position: relative;

                            .logo {
                                width: 150px;
                                height: 47px;
                            }

                            hr {
                                color: var(--labodoc-yellow);
                                margin: 5px 0 7px 0;
                            }

                            p {
                                margin: 5px 0;
                            }

                            .name {
                                color: var(--labodoc-deep-blue);
                                font-weight: 800;
                                font-size: 0.8em;
                            }

                            .atc-first-level {
                                color: var(--labodoc-deep-blue);
                                /*font-weight: 600;*/
                                font-size: 0.7em;
                                line-height: 15px;
                                height: 30px;
                            }

                            .laboratory {
                                margin: 0;
                                font-size: 12px;
                                color: var(--labodoc-middle-blue);
                            }

                            .atc-second-level {
                                margin: 0;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 50px;
                                height: 22px;
                                border-radius: 0 100px 0 0;

                                p {
                                    color: var(--labodoc-deep-blue);
                                    font-size: 10px;
                                }
                            }
                        }

                        .medicine-card.atc-category-A {
                            .atc-second-level {
                                background-color: var(--atc-category-A-color);
                            }
                        }

                        .medicine-card.atc-category-B {
                            .atc-second-level {
                                background-color: var(--atc-category-B-color);
                            }
                        }

                        .medicine-card.atc-category-C {
                            .atc-second-level {
                                background-color: var(--atc-category-C-color);
                            }
                        }

                        .medicine-card.atc-category-D {
                            .atc-second-level {
                                background-color: var(--atc-category-D-color);
                            }
                        }

                        .medicine-card.atc-category-G {
                            .atc-second-level {
                                background-color: var(--atc-category-G-color);
                            }
                        }

                        .medicine-card.atc-category-H {
                            .atc-second-level {
                                background-color: var(--atc-category-H-color);
                            }
                        }

                        .medicine-card.atc-category-J {
                            .atc-second-level {
                                background-color: var(--atc-category-J-color);
                            }
                        }

                        .medicine-card.atc-category-L {
                            .atc-second-level {
                                background-color: var(--atc-category-L-color);
                            }
                        }

                        .medicine-card.atc-category-M {
                            .atc-second-level {
                                background-color: var(--atc-category-M-color);
                            }
                        }

                        .medicine-card.atc-category-N {
                            .atc-second-level {
                                background-color: var(--atc-category-N-color);
                            }
                        }

                        .medicine-card.atc-category-P {
                            .atc-second-level {
                                background-color: var(--atc-category-P-color);
                            }
                        }

                        .medicine-card.atc-category-R {
                            .atc-second-level {
                                background-color: var(--atc-category-R-color);
                            }
                        }

                        .medicine-card.atc-category-S {
                            .atc-second-level {
                                background-color: var(--atc-category-S-color);
                            }
                        }

                        .medicine-card.atc-category-V {
                            .atc-second-level {
                                background-color: var(--atc-category-V-color);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #page-health-professional-dashboard {
        .medical-items-with-messages {
            .medicine-list {
                > header {
                    .search-bar {
                        grid-column: 1 / -1;
                    }

                    .toggles {
                        grid-column: 1 / -1;
                        justify-self: start;
                        margin-top: 20px;
                    }

                    .filter-options,
                    .sort-options {
                        gap: 10px;
                    }

                    .sort-options {
                        .form-check {
                            flex-direction: column;
                            justify-content: center;
                            text-align: center;

                            input[type=radio] {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #page-health-professional-dashboard {
        .medical-items-with-messages {
            grid-template-columns: 1fr;

            .messages {
                --messages-height: 40vh;
                min-height: 250px;
                padding: 0 5vw 0 5vw;
                border-radius: 0 40px 0 0;

                .container {
                    .list {
                        .message {
                            margin: 10px auto;
                        }
                    }
                }
            }

            .medicine-list {
                padding: 20px 5vw;
                min-height: 300px;

                > header {
                    .search-bar {
                        grid-column: 1;
                    }

                    .toggles {
                        grid-column: 2;
                        justify-self: end;
                        margin-top: 0;
                    }

                    .filter-options,
                    .sort-options {
                        gap: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    #page-health-professional-dashboard {
        .medical-items-with-messages {
            .messages {
                --messages-height: 50vh;

                .container {
                    .list {
                        .message {
                            .content {
                                padding: 10px;
                            }
                        }
                    }
                }
            }

            .medicine-list {
                > header {
                    .search-bar {
                        grid-column: 1 / -1;

                        div.form-group {
                            input {
                                width: 100%;
                            }
                        }
                    }

                    .toggles {
                        grid-column: 1 / -1;
                        justify-self: start;
                        margin-top: 20px;
                    }

                    div.filter-options,
                    div.sort-options {
                        gap: 20px;
                    }

                    div.filter-options {
                        div.form-group.atc {
                            flex-grow: 1;

                            .labodoc-select-checkbox {
                                width: 100%;

                                .dropdown {
                                    max-height: 400px;
                                }
                            }
                        }
                    }

                    div.sort-options {
                        div.form-check {
                            flex-direction: column;
                            justify-content: center;
                            text-align: center;

                            input[type=radio] {
                                margin: 0;
                            }
                        }
                    }
                }

                .container {
                    .medicine-cards {
                        grid-template-columns: repeat(auto-fill, 140px);

                        a {
                            .medicine-card {
                                .logo {
                                    width: 100px;
                                    height: 31px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
