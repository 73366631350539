div.labodoc-pagination .pagination-btn {
    border: 1px solid var(--labodoc-middle-blue);
    border-radius: 10px;
    background-color: var(--labodoc-light-blue);
    padding: 8px;
    margin: 8px;
    cursor: pointer;
}

div.labodoc-pagination .pagination-btn-current-page {
    background-color: var(--labodoc-middle-blue);
    color: white;
}

div.labodoc-pagination .pagination-ellipsis {
    background-color: transparent;
    border: none;
    cursor: none;
}
