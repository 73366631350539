.labodoc-button {
    border-radius: 50px 50px 50px 0;
    border: none;
    padding: 0.5em 5em;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .labodoc-button {
        padding: 0.5em 2.5em;
    }
}
