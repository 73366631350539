.admin-message-modal {
    .labodoc-popup-container-content {
        position: relative;
        padding-bottom: 20px !important;

        .container {
            text-align: center;
            color: var(--labodoc-deep-blue);
            width: 80%;
            margin: auto;

            header {
                margin-bottom: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                p {
                    margin: 0;
                }

                .type span {
                    font-size: 1.4em;
                    font-weight: bold;
                    margin-right: 10px;
                }
            }

            .title {
                font-size: 1.1em;
                font-weight: bold;
                margin-bottom: 0;
                text-align: left;
            }

            .author {
                margin-top: 5px;
                margin-bottom: 0;
                text-align: left;

                span {
                    font-size: 1.05em;

                    a {
                        color: var(--labodoc-middle-blue);
                        font-weight: bold;
                        font-size: 1em;
                    }
                }
            }

            .date {
                margin-top: 5px;
                text-align: left;
            }

            .text {
                margin-top: 30px;
                margin-bottom: 20px;
                text-align: left;
            }

            .actions {
                display: flex;
                flex-direction: column;
                margin-top: 60px;

                a {
                    display: inline-block;
                    text-decoration: none;
                    font-weight: bold;
                    padding: 10px 20px;
                    border-radius: 40px 50px 50px 0;
                    box-shadow: 0 0 6px #0000004D;
                    margin-bottom: 20px;

                    span {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
