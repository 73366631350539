.laboratory-invitation-message-form {
    .laboratory {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        h3 {
            grid-column: 1 / -1;
        }

        .fields {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
            align-items: baseline;

            .laboratory-details {
                display: flex;

                .laboratory-information {
                    flex: 1;
                    display: flex;
                    align-items: baseline;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .labodoc-select {
                        flex: 1;
                    }

                    button.remove {
                        background: none;
                        border: none;
                    }
                }
            }
        }
    }

    .message {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;

        h3 {
            grid-column: 1 / -1;
        }

        .fields {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .title {
                align-self: end;
            }

            .text {
                grid-column: 1 / -1;

                textarea {
                    resize: vertical;
                    min-height: 100px;
                }
            }
        }

        .banner {
            img {
                display: block;
                width: 300px;
                margin: 20px auto;
            }
        }
    }
}
