#page-health-professional-profile-medical-interests {
    flex: 1;
    background-color: var(--labodoc-beige);
    padding: 40px 0;

    > div.page-width {
        > div {
            background-color: white;
            padding: 20px 40px;
            margin-bottom: 20px;
            box-shadow: 0 6px 10px #00000040;
            border-radius: 0 80px 0 0;

            > .title {
                color: var(--labodoc-deep-blue);
                font-weight: 900;
                font-size: 1.2em;
            }

            > .text {
                margin: 25px 0;
            }

            .labodoc-checkbox.all {
                margin-bottom: 25px;

                label {
                    font-weight: bold;
                }
            }

            > .choices {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                row-gap: 15px;
                margin-top: 20px;
                margin-bottom: 50px;

                @media screen and (max-width: 1600px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media screen and (max-width: 1200px) {
                    grid-template-columns: 1fr;
                }
            }

            > .labodoc-button {
                display: block;
                margin: auto;
            }

        }
    }
}
