#page-admin-laboratories {
    flex: 1;

    .labodoc-spinner {
        display: block;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height:2em;
            margin-bottom: 2em;
        }


        button {
            background-color: var(--labodoc-yellow);
            color: white;
        }
    }

    .dialog-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .dialog-content .modal-container {
        max-width: 701px;
    }

    div.modal-content {
      overflow: hidden;
    }

    div.modal-content button {
      margin: 10px;
    }

    div.modal-content button.activate-btn {
      background-color: #397048;
      color: white;
    }

    div.modal-content button.deactivate-btn {
      background-color: #703939;
      color: white;
    }

    div.modal-content button.cancel-btn {
      border: none;
      background-color: transparent;
      color: var(--labodoc-deep-blue);
    }

    .dialog-content h2 {
        font-size: 21px;
        margin-bottom: 0px;
     }


    .cards {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow-y: auto;
        max-height: 600px;

        .card {
            width: 400px;
            background-color: white;
            margin: 40px 8px;
            box-shadow: 0 0 5px var(--shadow-color);
            padding: 10px 30px;
            text-align: center;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            header {
                display: initial;

                img {
                    width: 250px;
                    cursor: pointer
                }

                .name {
                    font-size: 1.2em;
                    color: var(--labodoc-deep-blue);
                    text-decoration: none;
                    font-weight: bolder;
                    display: block;
                    margin: 10px 0px;
                }
            }

            hr {
                color: var(--labodoc-yellow);
                width: 100%;
                margin: 1.6em 0;
            }

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: center;

                button {
                    color: white;
                    margin: 10px;
                    padding: 5px 20px;
                    margin: 10px;
                }

                button.edit {
                    background-color: var(--labodoc-yellow);

                }

                button.activate {
                    border: 1px solid #703939;
                    color: #703939;
                    background-color: transparent;
                }

                button.deactivate {
                    border: 1px solid #397048;
                    color: #397048;
                    background-color: transparent;
                }
            }
        }
    }
}
