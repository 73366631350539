div.with-card-form {
    header {
        @media screen and (max-width: 700px) {
            background-color: var(--labodoc-beige);
        }

        .labodoc-stepper {
            margin: 1em 0;

            @media screen and (max-width: 700px) {
                background-color: var(--labodoc-light-blue);
                border-radius: 0 40px 0 0;
                box-shadow: 0 0 10px var(--shadow-color);
                padding: 1em 0;
                margin: 0;
            }
        }
    }

    div.container {
        > div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 5em;
            row-gap: 0.5em;

            border-radius: 100px 100px 0 0;
            background-color: var(--labodoc-light-blue);
            box-shadow: 0 0 10px var(--shadow-color);

            max-width: 1000px;
            padding: 2em 10em 1em 10em;
            margin: auto;

            @media screen and (max-width: 700px) {
                background-color: initial;
                box-shadow: initial;
                padding: 2em 0 1em 0;
            }

            form {
                grid-column: 1 / span 2;

                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 3em;
                row-gap: 1em;
                align-items: baseline;

                @media screen and (max-width: 700px) {
                    grid-template-columns: 1fr;
                    column-gap: initial;
                    row-gap: 1em;

                }
            }

            div.buttons {
                grid-column: 1 / span 2;
                justify-self: center;

                display: flex;
                flex-direction: column;

                button {
                    margin: 5px;
                }
            }
        }

        div.medical-card-form {
            /*padding: 2em 5em;*/

            p {
                text-align: center;
                margin-top: 0;

                grid-column: 1 / span 2;
            }

            form {
                grid-template-columns: 1fr;
            }

            @media screen and (max-width: 700px) {
                padding: 2em 0;
            }
        }

        div.health-professional-information-cps-form,
        div.health-professional-information-cpf-form,
        div.information-recap {
            padding: 2em 8em 1em 8em;
            @media screen and (max-width: 700px) {
                padding: 2em 0 1em 0;
            }
        }

        div.information-recap {
            ul {
                list-style: none;
                grid-column: 1 / span 2;
                padding: 0;

                li {
                    width: 40%;
                    display: block;
                    border-bottom: 1px solid white;
                    padding: 10px 0;

                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }

                    @media screen and (max-width: 700px) {
                        border-bottom: 1px solid var(--labodoc-beige);
                    }
                }

                li:nth-child(odd) {
                    float: left;
                    @media screen and (max-width: 1000px) {
                        float: initial;
                    }
                }

                li:nth-child(even) {
                    float: right;
                    @media screen and (max-width: 1000px) {
                        float: initial;
                    }
                }
            }
        }

        div.notifications-settings-form {
            p {
                grid-column: 1 / span 2;
                text-align: center;
            }

            p.title {
                font-weight: bold;
                margin: 0;
            }

            form {
                .labodoc-radio-group {
                    grid-column: 1 / -1;
                    margin-bottom: 0;

                    .kv-radiogroup-container {
                        > div {
                            margin-bottom: 20px;

                            > p {
                                margin-bottom: 5px;
                            }

                            > div {
                                display: flex;
                            }
                        }
                    }
                }

                > p {
                    grid-column: 1 / -1;
                    text-align: center;
                    font-weight: normal;
                    margin-top: 0;
                }
            }
        }

        div.account-information-form {
            form {
                .password-row {
                    grid-row: 2;
                    @media screen and (max-width: 700px) {
                        grid-row: initial;
                    }
                }

                .partner-code {
                    grid-row: 1;
                    grid-column: 2;
                    @media screen and (max-width: 700px) {
                        grid-row: initial;
                        grid-column: initial;
                    }
                }
            }
        }

        div.medical-card-not-found,
        div.medical-card-expired,
        div.health-professional-already-registered,
        div.health-professional-not-eligible,
        div.email-address-already-used {
            row-gap: 1em;

            i {
                grid-column: 1 / span 2;
                justify-self: center;
                color: var(--labodoc-deep-blue);
                font-size: 3em;
                margin-bottom: 5px;
            }

            p {
                grid-column: 1 / span 2;
                justify-self: center;
                text-align: center;

                a {
                    color: var(--labodoc-deep-blue)
                }
            }
        }
    }
}
