#page-admin-medical-professions {
    flex: 1;

    .page-width {
        padding: 20px 0;

        > header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                border-bottom: 3px solid var(--labodoc-yellow);
                line-height: 2em;
                margin-bottom: 2em;
            }


            button {
                background-color: var(--labodoc-yellow);
                color: white;
            }
        }

        .labodoc-spinner {
            display: block;
            margin: 50px auto;
        }

        .medical-professions,
        .medical-specialities,
        .medical-interests {
            display: grid;
            row-gap: 10px;
        }

        .medical-profession,
        .medical-speciality,
        .medical-interest {
            margin: 0 40px;
            padding: 10px;
            background-color: white;
            box-shadow: 0 0 5px var(--shadow-color);
            border-radius: 10px;

            header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .information {
                    .name {
                        font-weight: bolder;
                        font-size: 1.1em;
                        color: var(--labodoc-deep-blue);
                        margin: 10px 0;
                    }

                    .number {
                        span {
                            margin-right: 10px;
                        }
                    }
                }

                .actions {
                    display: flex;
                    align-items: center;

                    button {
                        background-color: var(--labodoc-yellow);
                        color: white;
                        margin: 10px;
                        padding: 5px 20px;
                    }
                }
            }
        }
    }
}

.admin-medical-profession-form-modal,
.admin-medical-speciality-form-modal,
.admin-medical-interest-form-modal {
    .labodoc-popup-container-content {
        padding-top: 20px;
        text-align: center;

        .form-group {
            margin-bottom: 40px;
        }

        button {
            margin-top: 20px;
            background-color: var(--labodoc-yellow);
            color: white;
        }
    }
}
