form.login {
    display: flex;
    flex-direction: column;

    div.form-group {
        margin: 20px;
    }

    button.forgot-password {
        float: right;
        margin-top: 10px;
        border: none;
        background-color: transparent;
        text-decoration: underline;
    }

    .remember-me {
        align-self: center;
    }

    button.login {
        margin-top: 1em;
    }
}
