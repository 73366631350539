footer {
    div.up {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;

        img {
            height: 2em;
            width: 10em;
            @media screen and (max-width: 1000px) {
                height: 2em;
                width: 10em;
            }
            @media screen and (max-width: 700px) {
                height: 7em;
                width: 12.5em;
            }
        }

        nav {
            display: flex;
            @media screen and (max-width: 700px) {
                flex-direction: column;
            }

            a {
                padding: 0 20px;
                color: white;
                text-decoration: none;
                font-weight: 900;
                @media screen and (max-width: 700px) {
                    padding: 10px 10px;
                }
            }
        }
    }

    hr {
        margin: 0;
    }

    div.bottom {
        padding-top: 10px;
        padding-bottom: 10px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            @media screen and (max-width: 700px) {
                flex-direction: column;
                align-items: center;
            }

            li {
                font-size: 0.9rem;

                a {
                    font-size: 0.9rem;

                    color: white;
                    text-decoration: none;
                }
            }

            li:not(:last-child)::after {
                content: "|";
                margin: 0.2vw;
            }
            @media screen and (max-width: 700px) {
                li:not(:last-child)::after {
                    content: none;
                    margin: initial;
                }
           }
        }
    }
}
