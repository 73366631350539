#page-health-professional-profile-information {
    flex: 1;
    background-color: var(--labodoc-beige);
    padding: 40px 0;

    > div.page-width {
        > div {
            background-color: white;
            padding: 20px 40px;
            margin-bottom: 20px;
            box-shadow: 0 6px 10px #00000040;

            > p.title {
                color: var(--labodoc-deep-blue);
                font-weight: 900;
                font-size: 1.2em;
            }

            > p span a {
                color: black;
            }

            > div.container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 50px;
                margin-top: 40px;
            }
        }

        > div:first-child {
            border-radius: 0 80px 0 0;
        }

        > div.identifier {
            .labodoc-button {
                padding: 0.5em 2em;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    #page-health-professional-profile-information {
        > div.page-width {
            > div {
                div.container {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}

.modify-password-modal {
    .labodoc-popup-container-content {
        text-align: center;

        > p.title {
            margin-top: 0;
            margin-bottom: 3em;
            font-weight: 900;
            font-size: 1.2em;
            color: var(--labodoc-deep-blue);
        }

        > form {
            text-align: left;

            > .form-group {
                margin-bottom: 3em;
            }
        }
    }
}
