#page-home {
    #page-home-header {
        display: grid;
        grid-template-columns: 45% 55%;
        align-items: center;
        @media screen and (max-width: 700px) {
            grid-template-columns: 1fr;
        }

        #page-home-presentation {
            border-radius: 0 100px 0 0;
            padding-bottom: 50px;
            @media screen and (max-width: 700px) {
                border-radius: 0 0 40px 0;
                max-width: 95vw;
                padding-bottom: 25px;
            }

            #page-home-presentation-content {
                display: flex;
                flex-direction: column;
                padding-top: 70px;
                @media screen and (max-width: 700px) {
                    padding: 35px 0 35px 0;
                    align-items: center;
                }

                > img {
                    width: 21em;
                    height: 5em;
                    margin-bottom: 100px;
                    @media screen and (max-width: 700px) {
                        margin-bottom: 35px;
                        width: 13em;
                        height: 2.5em;
                    }
                }

                h1 {
                    max-width: 360px;
                    margin-bottom: 50px;
                    @media screen and (max-width: 700px) {
                        text-align: center;
                        margin-bottom: 25px;
                    }
                }

                p {
                    max-width: 600px;
                    margin-bottom: 60px;
                    @media screen and (max-width: 700px) {
                        text-align: center;
                        margin-bottom: 35px;
                    }
                }

                button {
                    margin-bottom: 50px;

                    @media screen and (max-width: 1000px) {
                        margin-bottom: 50px;
                    }

                    @media screen and (max-width: 700px) {
                        /*margin-bottom: 0;*/
                    }
                }

                #home-presentation-mobile {
                    img {
                        width: 170px;
                        height: 65px;
                        margin-right: 10px;

                        @media screen and (max-width: 1000px) {
                            margin-right: 0;
                        }

                        @media screen and (max-width: 700px) {
                            width: 150px;
                            height: 57px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        #page-home-login {
            @media screen and (max-width: 700px) {
                margin-top: 50px;
            }

            div.page-width {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media screen and (max-width: 700px) {
                    align-items: initial;
                }

                h1 {
                    font-weight: 900;
                    text-align: center;
                }

                hr {
                    width: 200px;
                    padding: 1px;
                    margin-bottom: 25px;
                    color: var(--labodoc-yellow);
                    background-color: var(--labodoc-yellow);
                }

                p {
                    max-width: 550px;
                    margin-bottom: 100px;
                    text-align: center;
                    @media screen and (max-width: 700px) {
                        display: none;
                    }
                }

                button {
                    width: 400px;
                    margin-bottom: 30px;
                    box-shadow: 0 0 6px var(--shadow-color);
                    font-weight: 900;

                    @media screen and (max-width: 700px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    #page-home-pros {
        background: linear-gradient(
                to bottom,
                var(--labodoc-beige) 0%,
                var(--labodoc-beige) 60%,
                white 60%,
                white 100%
        );
        padding: 50px 0;
        @media screen and (max-width: 1000px) {
            padding: 40px 0;
        }

        h1 {
            margin-bottom: 120px;
            text-align: center;
            @media screen and (max-width: 1000px) {
                margin-bottom: 60px;
            }
        }

        .bubble {
            max-width: 400px;
            width: 20vw;
            padding: 0 25px;
            filter: drop-shadow(0 0 5pt var(--shadow-color));
            border-radius: 50px 50px 50px 0;
            text-align: center;
            @media screen and (max-width: 1000px) {
                max-width: 300px;
                width: 75vw;
                margin: 30px;
            }

            h2 {
                max-width: 200px;
                font-weight: 900;
                margin: 60px auto 35px auto;
                @media screen and (max-width: 1000px) {
                    margin: 30px auto 20px auto;
                }
            }

            hr {
                color: var(--labodoc-yellow);
                background-color: var(--labodoc-yellow);
                padding: 1px;
                width: 60%;
                margin: auto;
            }

            p {
                max-width: 300px;
                margin: 55px auto 75px auto;
                @media screen and (max-width: 1000px) {
                    margin: 15px auto 30px auto;
                }
            }
        }

        .desktop {
            display: flex;
            justify-content: center;
            column-gap: 75px;
            @media screen and (max-width: 1000px) {
                display: none;
            }
        }

        .mobile {
            display: none;
            @media screen and (max-width: 1000px) {
                display: block;

                swiper-container::part(pagination) {
                    margin-top: 40px;

                    --swiper-pagination-bullet-height: 15px;
                    --swiper-pagination-bullet-size: 15px;
                    --swiper-pagination-color: var(--labodoc-deep-blue);
                    --swiper-pagination-bullet-inactive-color: var(--labodoc-deep-blue);
                    --swiper-pagination-bullet-inactive-opacity: 1;
                    --swiper-pagination-bottom: 1px;
                }
            }
        }
    }

    #page-home-features {
        padding: 50px 0;

        div.page-width {
            display: flex;
            justify-content: space-around;
            @media screen and (max-width: 1000px) {
                align-items: center;
                flex-direction: column;
            }

            div {
                margin-right: 50px;
                @media screen and (max-width: 1000px) {
                    margin-right: initial;
                }

                h1 {
                    margin-bottom: 35px;
                    @media screen and (max-width: 1000px) {
                        text-align: center;
                    }
                }

                ul {

                    padding-left: 0;
                    margin-left: 1.5em;

                    li::marker {
                        color: var(--labodoc-middle-blue);
                        font-size: 1.5em;
                    }

                    li:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }
            }

            img {
                max-width: 600px;
                width: 30vw;
                border-radius: 100px 100px 0 100px;
                box-shadow: 0px 0px 15px var(--shadow-color);
                object-fit: cover;
                object-position: center;

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }

    #page-home-mission {
        padding: 50px 0 100px 0;
        @media screen and (max-width: 700px) {
            padding: 0 0 30px 0;
        }

        div.page-width {
            div {
                display: flex;
                @media screen and (max-width: 700px) {
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                }

                h1 {
                    width: 200px;
                    align-self: center;

                    @media screen and (max-width: 700px) {
                        width: initial;
                    }
                }

                hr {
                    margin: 0 100px;
                    color: var(--labodoc-yellow);
                    @media screen and (max-width: 700px) {
                        width: 200px;
                    }
                }

                p {
                    flex: 1;
                }
            }

            a {
                text-decoration: none;

                button {
                    display: block;
                    margin: 120px auto 0 auto;
                    @media screen and (max-width: 700px) {
                        margin: 30px auto 0 auto;
                    }
                }
            }
        }
    }

    #page-home-testimonials {
        display: none;
        text-align: center;
        padding: 80px 0 40px 0;

        div.page-width {
            margin-bottom: 50px;
        }

        .testimonial::before {
            background-image: url('../../../img/quote.svg');
            content: '';
            width: 200px;
            height: 200px;
            position: absolute;

            left: 0;
            top: -35%;
            transform: scale(1, -1);
        }

        @media screen and (max-width: 700px) {
            .testimonial::before {
                top: -30%;
            }
        }

        .testimonial {
            max-width: 700px;
            padding: 80px 25px 25px 25px;
            filter: drop-shadow(0 0 5pt var(--shadow-color));
            border-radius: 50px 50px 50px 0;
            text-align: center;
            margin: 40px;

            @media screen and (max-width: 700px) {
                max-width: 300px;
                width: 75vw;
                margin: 70px;
            }

            .text {
                max-width: 600px;
                padding: 0 50px;
                margin: auto;
            }

            .author {
                text-transform: uppercase;
                font-weight: bold;
                margin-top: 50px;
                margin-bottom: 0;
            }

            .author-information {
                margin-top: 2px;
            }
        }

        swiper-container::part(pagination) {
            margin-top: 40px;

            --swiper-pagination-bullet-height: 15px;
            --swiper-pagination-bullet-size: 15px;
            --swiper-pagination-color: var(--labodoc-deep-blue);
            --swiper-pagination-bullet-inactive-color: var(--labodoc-deep-blue);
            --swiper-pagination-bullet-inactive-opacity: 1;
            --swiper-pagination-bottom: 1px;
        }
    }
}

.labodoc-popup.registration-successful {
    .labodoc-popup-container {
        header {
            .icon {
                color: var(--labodoc-yellow);
            }
        }

        .labodoc-popup-container-content {
            text-align: center;

            p {
                margin: auto;
            }

            p.title {
                width: 250px;
                margin-bottom: 3em;

                span {
                    b {
                        font-size: 20px;
                        font-weight: 900;
                        color: var(--labodoc-deep-blue);
                    }
                }
            }

            p.text {
                a {
                    color: black;
                }
            }
        }
    }
}

.labodoc-popup.registration-email-confirmation {
    .labodoc-popup-container {
        header {
            .icon {
                color: var(--labodoc-yellow)
            }
        }

        .labodoc-popup-container-content {
            text-align: center;

            p {
                margin: auto;
            }

            p.title {
                width: 250px;
                margin-bottom: 3em;

                span {
                    b {
                        font-size: 20px;
                        font-weight: 900;
                        color: var(--labodoc-deep-blue);
                    }
                }
            }

            p.text {
                a {
                    color: black;
                }
            }
        }
    }
}

.labodoc-popup.application-successful {
    .labodoc-popup-container {
        header {
            .icon {
                color: var(--labodoc-yellow);
            }
        }

        .labodoc-popup-container-content {
            text-align: center;

            p {
                margin: auto;
            }

            p.title {
                width: 300px;
                margin-bottom: 3em;

                span {
                    b {
                        font-size: 20px;
                        font-weight: 900;
                        color: var(--labodoc-deep-blue);
                    }
                }
            }

            p.text {
                a {
                    color: black;
                }
            }
        }
    }
}

.labodoc-popup.application-email-confirmation {
    .labodoc-popup-container {
        header {
            .icon {
                color: var(--labodoc-yellow);
            }
        }

        .labodoc-popup-container-content {
            text-align: center;

            p {
                margin: auto;
            }

            p.title {
                width: 250px;
                margin-bottom: 3em;

                span {
                    b {
                        font-size: 20px;
                        font-weight: 900;
                        color: var(--labodoc-deep-blue);
                    }
                }
            }

            p.text {
                a {
                    color: black;
                }
            }
        }
    }
}

.labodoc-popup.reset-password {
    .labodoc-popup-container {
        header {
            .icon {
                color: var(--labodoc-deep-blue);
            }
        }

        .labodoc-popup-container-content {
            text-align: center;

            p.title {
                width: 300px;
                margin: auto auto 3em;

                span {
                    b {
                        font-size: 20px;
                        font-weight: 900;
                        color: var(--labodoc-deep-blue);
                    }
                }
            }

            form {
                .labodoc-text {
                    margin-bottom: 50px;
                }
            }

            button {
                background-color: var(--labodoc-yellow);
                color: white;
            }
        }
    }
}
