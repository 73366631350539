.labodoc-select {
    label {
        position: absolute;
        transition: transform 200ms, color 200ms;
    }

    label:has(~ select:focus), /* Input has focus */
    label:has(~ select option:checked)
    {
        transform: translateY(-20px);
        font-weight: bold;
    }

    select {
        border: none;
        background: transparent;
        border-bottom: 1px solid;
        width: 100%;
    }
}
