#page-health-professional-medicine-page {
    flex: 1;
    background-color: var(--labodoc-beige);
    padding: 100px 0;

    .page-width {
        max-width: 1200px;
    }

    .labodoc-spinner {
        display: block;
        margin: 100px auto;
    }

    .medicine {
        display: grid;
        grid-template-columns: 570px 1fr;
        justify-items: center;
        align-items: start;
        row-gap: 20px;
        column-gap: 20px;
        background-color: white;
        padding: 40px;
        border-radius: 0 80px 0 0;
        box-shadow: 0 6px 10px #00000040;
        margin: auto;

        img {
            grid-column: 1 / -1;
            align-self: center;
            width: 100%;
            max-width: 355px;
            max-height: 200px;
        }

        hr {
            color: var(--labodoc-yellow);
            background-color: var(--labodoc-yellow);
            width: 100%;
            grid-column: 1 / -1;
        }

        .information {
            display: grid;
            grid-template-rows: 1fr 200px;

            .data {
                .name {
                    color: var(--labodoc-deep-blue);
                    font-weight: 900;
                    font-size: 1.35em;
                    margin: 0;
                }

                .main-composition {
                    color: var(--labodoc-middle-blue);
                    font-weight: 600;
                    font-size: 1.25em;
                    margin: 0;
                }

                .laboratory {
                    margin: 0;
                    font-size: 1.15em;
                }

                p.atc {
                    margin: 0;
                    font-size: 1.15em;
                }

                div.atc {
                    margin-top: 20px;

                    p.title {
                        margin: 5px 0;
                        font-weight: 900;
                        font-size: 1.1em;
                    }

                    p.category {
                        margin: 2px 0;
                        font-size: 0.9em;
                    }

                    p.category.first {

                    }

                    p.category.second {
                    }

                    p.category.third {
                    }

                    p.category.fourth {
                    }

                    p.category.fifth {
                    }
                }
            }

            .actions {
                width: 300px;

                a {
                    display: block;
                    background-color: var(--labodoc-middle-blue);
                    border: 2px solid var(--labodoc-middle-blue);
                    color: #FFFFFF;
                    font-weight: bold;
                    padding: 0.5em 1em;
                    text-decoration: none;
                    border-radius: 50px 50px 50px 0;
                    margin: 20px auto;
                    text-align: center;
                }

                a.has-website,
                a.ansm-website {
                    background-color: #FFFFFF;
                    border: 2px solid var(--labodoc-middle-blue);
                    color: var(--labodoc-middle-blue);
                }
            }
        }

        div.resources {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 10px;

            div.documents {
                background-color: var(--labodoc-light-blue);
                border-radius: 0 50px 0 0;
                padding: 20px 40px 20px 40px;
                width: 85%;
                /*min-height: 110px;*/

                h2 {
                    margin-top: 0;
                    margin-bottom: 10px;
                    font-size: 1.1em;
                }

                h2:after {
                    float: right;
                }

                p {
                    color: var(--labodoc-middle-blue);
                    font-weight: bold;
                    margin: 0;
                    font-size: 0.9em;
                }

                ul.documents {
                    list-style: none;
                    padding: 0;

                    li.document {
                        a {
                            display: block;
                            text-decoration: none;
                            /*border-bottom: 1px solid black;*/
                            color: var(--labodoc-deep-blue);
                            /*max-width: 200px;*/

                            padding: 10px 0;
                        }
                    }

                    .document.new {
                        a {
                            font-weight: bold;
                        }
                    }

                    .document:last-child {
                        a {
                            border-bottom: none;

                        }
                    }
                }
            }

            div.documents.close {
                h2 {
                    cursor: pointer;
                }

                h2:after {
                    content: '▲';
                }

                ul.documents {
                    display: none;
                }
            }

            div.documents.open {
                h2 {
                    cursor: pointer;
                }

                h2:after {
                    content: '▼';
                }

                ul.documents {
                    display: list-item;
                }
            }

            div.dosages {
                background-color: var(--labodoc-beige);
                border-radius: 0 0 50px 0;
                padding: 20px 40px 20px 40px;
                width: 85%;
                /*min-height: 150px;*/

                h2 {
                    margin-top: 0;
                    margin-bottom: 10px;
                    font-size: 1.1em;
                }

                h2:after {
                    float: right;
                }

                p {
                    color: var(--labodoc-middle-blue);
                    font-weight: bold;
                    margin: 0;
                    font-size: 0.9em;
                }

                ul.dosages {
                    list-style: none;
                    padding: 0;

                    li.dosage {
                        span {
                            margin-right: 5px;
                            font-size: 0.8em;
                        }

                        a {
                            color: var(--labodoc-deep-blue);
                            text-decoration: none;
                            font-size: 0.8em;
                        }
                    }
                }
            }

            div.dosages.close {
                h2 {
                    cursor: pointer;
                }

                h2:after {
                    content: '▲';
                }

                ul.dosages {
                    display: none;
                }
            }

            div.dosages.open {
                h2 {
                    cursor: pointer;
                }

                h2:after {
                    content: '▼';
                }

                ul.dosages {
                    display: list-item;
                }
            }
        }
    }

    .messages {
        display: grid;
        grid-template-columns: 350px 1fr;
        margin: 50px auto;
        background-color: white;
        border-radius: 80px 0 0 0;
        box-shadow: 0 6px 10px #00000040;
        color: var(--labodoc-deep-blue);

        header {
            display: grid;
            grid-template-columns: 100px 1fr;
            align-items: center;

            .fa-envelope {
                padding: 1em;
                background-color: var(--labodoc-light-blue);
                border-radius: 80px 80px 80px 0;
                color: var(--labodoc-deep-blue);
                font-size: 2em;
                position: relative;

                align-self: flex-end;

                .message-number {
                    position: absolute;
                    padding: 2px 4px;
                    background-color: red;
                    border-radius: 100%;
                    color: white;
                    right: 25%;
                }
            }

            > div {
                padding: 0 40px;
                text-align: left;

                .title {
                    font-weight: 900;
                    font-size: 1.1em;
                    margin: 0 0 10px 0;
                }
            }
        }

        .list {
            padding: 0 10px;
            max-height: 178px;
            overflow-y: auto;

            .message {
                display: grid;
                grid-template-columns: 150px 1fr 100px;
                align-items: center;
                border-bottom: 1px solid var(--labodoc-deep-blue);
                cursor: pointer;

                .type {
                    font-size: 1.1em;
                    font-weight: 900;
                    text-transform: uppercase;
                }

                > div {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }

                .favorite {
                    input[type="checkbox"] {
                        opacity: 0;
                        position: absolute;
                        left: -9999px;
                    }

                    label {
                        font-size: 2em; /* Adjust the size of the star */
                        cursor: pointer;
                        display: inline-block;
                    }

                    label::before {
                        content: '☆'; /* Empty star */
                        color: gold; /* Gold color */
                        display: inline-block;
                    }

                    input[type="checkbox"]:checked + label:before {
                        content: '★'; /* Filled star */
                    }

                    input[type="checkbox"]:focus + label:before {
                        outline: 2px solid white; /* Example focus style */
                    }
                }

                .title {
                    font-weight: 600;
                }
            }

            .message.new {
                p.type {
                    span:last-child {
                        color: red;
                    }
                }
            }

            .message:last-child {
                border-bottom: none;
            }
        }
    }

    .messages.empty {
        grid-template-columns: 1fr;

        header {
            > div {
                text-align: initial;

                .title {
                }

                .tip {
                    color: var(--labodoc-middle-blue);
                    font-weight: bold;
                    margin: 0;
                    font-size: 0.9em;
                }
            }
        }
    }

    .pharmacovigilance {
        background-color: var(--labodoc-yellow);
        color: white;
        margin-left: auto;
        z-index: 2;

        text-decoration: none;

        border-radius: 50px 50px 50px 0;
        font-weight: bold;
        padding: 0.5em 1em;
        display: block;
        width: 310px;
        text-align: center;
    }

    .medical-directory {
        margin: 200px auto 0 auto;
    }
}

@media screen and (max-width: 1200px) {
    #page-health-professional-medicine-page {
        .medical-directory {
            margin: 50px auto 0 auto;
        }
    }
}

@media screen and (max-width: 1000px) {
    #page-health-professional-medicine-page {
        .medicine {
            grid-template-columns: 1fr;
            row-gap: 10px;
            padding: 40px 40px 0px 40px;
        }

        .messages {
            grid-template-columns: 1fr;

            header {
                display: flex;
                gap: 20px;

                .title {
                    width: initial;
                }

            }

            .list {
                max-height: 155px;
            }

        }
    }
}

@media screen and (max-width: 700px) {
    #page-health-professional-medicine-page {
        padding: 20px 0;

        .medicine {
            img {
                max-width: 178px;
                max-height: 100px;
            }
        }

        .messages {
            padding-top: 10px;

            .list {
                max-height: 195px;

                .message {
                    grid-template-columns: 150px 1fr;

                    > div {
                        grid-row: 2;
                        grid-column: 1 / -1;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    #page-health-professional-medicine-page {
        .medicine {
            padding: 5px;

            .documents {
                width: initial;
            }
        }
    }
}

.labodoc-popup.contact-modal {

    .labodoc-popup-container-content {
        text-align: center;
        padding-bottom: 20px !important;

        .container {
            .title {
                margin-top: 0;
                color: var(--labodoc-deep-blue);
                font-weight: 900;
                font-size: 1.5em;
            }

            .text {
                max-width: 500px;
                margin: auto;
            }

            form {
                margin: 50px auto;

                label {
                    color: black;
                }
            }

            button {
                background-color: var(--labodoc-middle-blue);
                color: white;
            }

            .notice {
                font-style: italic;
                font-size: 0.7em;

                margin-top: 50px;
            }

            > span.fa-circle-check {
                font-size: 5em;
                margin-top: 0.5em;
                color: var(--labodoc-yellow);
            }

            .date {
                font-size: 1.1em;
                font-weight: bold;
            }
        }
    }
}
