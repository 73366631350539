#page-admin-partnership-update {
    flex: 1;

    div.page-width {
        h1 {
            border-bottom: 3px solid var(--labodoc-yellow);
            line-height: 2em;
            margin-bottom: 2em;
        }

        form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2em;

            .information {
                display: grid;
                grid-template-rows: 50px 50px;
                row-gap: 20px;
            }

            .logo {
                display: grid;
                justify-content: center;
                row-gap: 20px;

                img {
                    margin: auto;
                    width: 300px;
                    height: 170px;
                }
            }
        }

        > button {
            background-color: var(--labodoc-yellow);
            color: white;
            margin: auto;
            display: block;
        }
    }
}
